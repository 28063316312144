import {tailwindColorToHex} from '@app/utils/functions';
import {CarouselApi, RadCarousel, RadCarouselContent, RadCarouselItem} from '@holis/react-ui/rad';
import {CSSProperties, useRef} from 'react';

type ClassPositionItem<T> = {
	itemClass?: string;
	itemPosition?: string;
	itemColor?: string;
	id: number;
	item: T;
}

type ClassPositionItemSelectorProps<T> = Readonly<{
	items: ClassPositionItem<T>[];
	onItemSelect: (item: T) => void;
	selectedId?: number;
}>;

export default function ClassPositionItemSelector<T>({items, onItemSelect, selectedId}: ClassPositionItemSelectorProps<T>) {
  const api = useRef<CarouselApi>();

  const scrollToSelected = () => {
    if (selectedId !== undefined && api.current) {
      const selectedIndex = items.findIndex(item => item.id === selectedId);
      api.current.scrollTo(selectedIndex, true);
    }
  };

  const setApi = (_api: CarouselApi) => {
    if (!api.current) {
      api.current = _api;
      scrollToSelected();
    }
  };

  return (
    <div>
      <RadCarousel
        className='mt-4' setApi={setApi}>
        <RadCarouselContent className='gap-2'>
          {items.map(item => {
            const isSelected = selectedId === item.id;

            const readingColor = item.itemColor ? tailwindColorToHex(item.itemColor) : tailwindColorToHex('gray', '200');
            const itemStyle: CSSProperties = {
              backgroundColor: readingColor,
              padding: isSelected ? '2px' : '0',
              paddingBottom: 0,
              borderBottom: 'none',
            };
            return (
              <RadCarouselItem
                key={item.id}
                className='basis-[100px] flex flex-col place-content-end h-[76px]'
                onClick={() => onItemSelect(item.item)}
              >
                <div className='rounded-xl rounded-b-none overflow-hidden border border-b-0 select-none cursor-pointer hover:bg-mute' style={itemStyle}>
                  <div className='h-4 w-full'/>
                  <div style={{backgroundColor: isSelected ? '#fff' : tailwindColorToHex('sky', '50')}} className=''>
                    <div style={{height: isSelected ? '2px' : '0'}} className='transition-all'/>
                    <div
                      className='flex flex-col justify-center items-center w-full h-[50px] p-2'
                    >
                      <div className='font-bold text-blue-500'>{item.itemClass}</div>
                      <div className='font-bold text-gray-900'>{item.itemPosition}</div>
                    </div>
                    <div style={{height: isSelected ? '6px' : '0'}} className='transition-all bg-white -mb-[2px]'/>
                  </div>
                </div>

              </RadCarouselItem>
            );
          })}</RadCarouselContent>
      </RadCarousel>
    </div>
  );
}
