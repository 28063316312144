
export const APP_QUERY_ACCESS_TOKEN = 'access_token';
export const OBJ_NEW_ID = -1;
export const NO_DRAWING_ID = -1;
export const pictureCategories = ['general', 'inspectionPoint', 'damage', 'cml'];
export const USER_SELECTED_SITES = 'user_selected_sites';
export const MARKUP_BACKGROUND_ID = 'MARKUP_BACKGROUND';
export const GRID_DEFAULT_LAYOUT_DMG = '{"state":{"treeState":{"columns":{"id":{"hidden":true,"pinned":false,"width":101},"flagStatus":{"hidden":true,"pinned":false,"width":80},"idwg_idwg":{"hidden":true,"width":201},"coordinates3d":{"hidden":true,"width":155.73333333333332},"markerCoordinates3d":{"hidden":true,"width":155.89584350585938},"floc_techClass_class_class":{"hidden":true,"width":100},"display2d":{"hidden":true,"width":80},"display3d":{"hidden":true,"width":80},"coordinates2d":{"hidden":true,"width":80},"markerCoordinates2d":{"hidden":true,"width":80},"maxEndDate":{"hidden":true,"width":80},"action":{"filter":false,"width":75},"floc_floc":{"width":383},"description":{"width":418},"notif_notif":{"pinned":false,"width":186},"codeGroup_class_class":{"pinned":false,"width":155.73333333333332},"floc_sector_site_site":{"hidden":false,"pinned":false,"width":100.078125},"floc_sector_sector":{"hidden":false,"pinned":false,"width":100},"codeGroup_codeGroup":{"width":155.73333333333332},"part_part":{"width":155.73333333333332},"grid_grid":{"width":155.73333333333332},"accessibility_code":{"width":155.73333333333332},"notif_externalId":{"width":155.73333333333332},"notif_externalStatus":{"width":156.171875}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const GRID_DEFAULT_LAYOUT_EVENT = '{"state":{"treeState":{"columns":{"id":{"hidden":true,"width":80},"schedulingTag_classSece":{"hidden":true,"width":100},"schedulingTag_grid_grid":{"hidden":true,"width":77.5},"notes":{"hidden":true,"width":120},"releasedDate":{"hidden":true,"width":80},"reviewer":{"hidden":true,"width":80},"approver":{"hidden":true,"width":80},"reportNumber":{"hidden":true,"width":80},"reportDescription":{"hidden":true,"width":80},"reportingDate":{"hidden":true,"width":80},"plan_plan":{"hidden":true,"width":77.5},"schedulingTag_mainWorkCenter_code":{"hidden":true,"width":77.5},"schedulingTag_plannerGroup_code":{"hidden":true,"width":77.5},"externalId":{"hidden":true,"width":77.5},"action":{"filter":false,"width":77.5},"status":{"hidden":false,"pinned":false,"width":117},"event":{"hidden":false,"width":129},"code":{"hidden":false,"pinned":false,"width":158},"description":{"hidden":false,"width":289},"schedulingTag_floc":{"hidden":false,"width":294},"schedulingTag_description":{"width":445},"schedulingTag_sector_site_site":{"width":77.5},"schedulingTag_sector_sector":{"width":77.5},"schedulingTag_techClass_class_class":{"width":77.5},"schedulingTag_techClass_techClass":{"hidden":false,"width":120},"type_type":{"hidden":false,"width":77.5},"priority":{"width":80},"batchNumber":{"width":120},"plannedDate":{"hidden":false,"width":134},"inspectionDate":{"width":137},"inspector":{"hidden":false,"pinned":false,"width":274}},"scrollTop":0,"scrollLeft":0,"globalFilterValue":"","expandedRows":[]},"expandAll":false},"version":0}';
export const RADIAN = Math.PI / 180;
