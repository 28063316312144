import {AddIdwgFlocsMutation, FunctionalLocation, IdwgFloc, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import {FetchResult, useMutation} from '@apollo/client';
import {INSPECTION_DRAWINGS_ADD_IDWG_FLOCS} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import _ from 'lodash';
import FlocItems from '@app/components/Common/List/FlocList';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';

type TFLocList = Readonly<{
    flocs?: Partial<FunctionalLocation>[];
    idwg: Partial<InspectionDrawing>;
}>

export default function FlocList({idwg, flocs}: TFLocList) {
  const {setIdwgFlocs, setFlocToDelete, flocToDelete, idwgFlocs} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const {startLoading, stopLoading} = useLayoutStore();
  const [addIdwgFlocs] = useMutation<AddIdwgFlocsMutation>(INSPECTION_DRAWINGS_ADD_IDWG_FLOCS);
  const {t} = useTranslation();
  const addFlocs = (items: Partial<FunctionalLocation>[], changeFlocAutocompleteDisplay: (displayed: boolean) => void) => {
    startLoading();
    addIdwgFlocs({
      variables: {
        data: items.map((item: Partial<FunctionalLocation>) => ({
          idwgId: idwg.id,
          flocId: item.id,
        })),
      },
    }).then((_res: FetchResult<AddIdwgFlocsMutation>) => {
      const newFlocs: Array<Partial<IdwgFloc>> = _.cloneDeep(idwgFlocs ?? []);
      newFlocs.push(...(items.map((item: Partial<FunctionalLocation>) => ({
        flocId: item.id!,
        idwgId: idwg!.id!,
        functionalLocation: {
          ...item,
        },
      })) as Partial<IdwgFloc>[]));
      setIdwgFlocs(newFlocs);
      changeFlocAutocompleteDisplay(false);
      AppNotifications.success(t('message.success.addIdwgFloc'));
      stopLoading();
    }).catch(_err => {
      stopLoading();
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  return (
    <FlocItems
      hasActionButtons
      flocs={flocs}
      isDeleteButtonDisabled={!!flocToDelete}
      onClickAddFlocs={addFlocs}
      onDeleteClick={setFlocToDelete}
    />
  );
}
