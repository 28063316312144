import {useTranslation} from 'react-i18next';
import BlockTitle from '../../../../Text/BlockTitle';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {TCarouselCallback} from '@holis/react-ui';
import PictureCard from '../PictureCard';
import React, {useEffect, useState} from 'react';
import {ApolloError} from '@apollo/client';
import {useDropzone} from 'react-dropzone';
import {LuImage, LuPlusSquare} from 'react-icons/lu';
import AppNotifications from '@app/services/notification';
import {useDataStore} from '@app/stores/data';
import {EApiOperator, EImportService} from '@app/utils/enums';
import {AxiosResponse} from 'axios';
import {TPictureUploadParameters} from '@app/types/app';
import DeletePictureModal from '../PictureCard/components/DeletePictureModal';
import {RadButton, RadCarousel, RadCarouselContent, RadCarouselItem, RadCarouselNext, RadCarouselPrevious} from '@holis/react-ui/rad';
import PictureEditionModal from '../PictureEdition';
import {Picture} from '@app/graphql/__types__/graphql';
import {twMerge} from 'tailwind-merge';
import {useLayoutStore} from '@app/stores/layout';

type TPictureCarousel = Readonly<{
  isLoading?: boolean;
  error?: string | null | ApolloError;
  pictures?: Partial<Picture>[];
  onCarouselRender?: TCarouselCallback;
  title?: React.ReactNode;
  onImagesUploaded?: (pictures: Partial<Picture>[]) => void;
  onImageDeleted?: (picture?: Partial<Picture>) => void;
  onImagesUploadError?: (error: Error) => void;
  uploadFiles?: (files: File[], parameters?: TPictureUploadParameters) => void;
  addFileBtnHidden?: boolean;
  headerHidden?: boolean;
  noUploadFiles?: boolean;
  uploadParameters?: TPictureUploadParameters;
  onChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
  readonly?: boolean;
  renderPictures?: (pictures?: Partial<Picture>[]) => React.ReactNode;
  onInputInit?: (inputRef: React.RefObject<HTMLInputElement>) => void;
  className?: string;
  canDelete?: boolean;
}>

export default function PictureCarousel({title, isLoading, error, pictures, onImageDeleted, onImagesUploaded, onImagesUploadError, uploadFiles, uploadParameters, addFileBtnHidden, headerHidden, noUploadFiles, onChanged, readonly, renderPictures, onInputInit, className, canDelete}: TPictureCarousel) {
  const {t} = useTranslation();
  const [pics, setPics] = useState<Partial<Picture>[]>();
  const [loading, setLoading] = useState<boolean>(!!isLoading);
  const {startLoading, stopLoading} = useLayoutStore();
  const [picToUpdate, setPicToUpdate] = useState<Picture>();
  const [picToDelete, setPicToDelete] = useState<Partial<Picture>>();
  const {acceptedFiles, fileRejections, getRootProps, getInputProps, inputRef} = useDropzone({
    accept: ['.png', '.jpeg', '.jpg', '.svg'],
    noClick: true,
    multiple: true,
    maxFiles: 50,
  });

  const {uploadDownloadService} = useDataStore();

  const handleUploadBtnclick = () => {
    inputRef.current?.click();
  };

  const handleUpdatePictureBtnClicked = async (picture: Picture) => {
    setPicToUpdate(picture);
  };

  const handlePictureUpdated = (pic: Partial<Picture>) => {
    setPics(pics?.map(item => item.id === pic.id ? pic : item));
    onChanged?.([pic], EApiOperator.UPDATE);
  };

  const uploadImages = (images: File[]) => {
    if (uploadFiles) {
      return uploadFiles(images, uploadParameters);
    }

    setLoading(true);
    startLoading();
    uploadDownloadService!.uploadMultipleFiles(images, uploadParameters, EImportService.UPLOAD_PICTURE).then((res: AxiosResponse) => {
      if (res.status === 200) {
        AppNotifications.success(t('message.success.importService.picturesUploaded'));
        onImagesUploaded?.(res.data as Partial<Picture>[]);
        onChanged?.(res.data as Partial<Picture>[], EApiOperator.CREATE);
      } else {
        throw new Error(String(res.data));
      }
    }).catch((err: Error) => {
      AppNotifications.error(t('message.error.importService.picturesUploadFailed'));
      onImagesUploadError?.(err);
    }).finally(() => {
      setLoading(false);
      stopLoading();
    });
  };

  const handleDeletePictureModalClose = () => {
    setPicToDelete(undefined);
  };

  const handlePictureDeleted = (picture?: Partial<Picture>) => {
    setPicToDelete(undefined);
    onImageDeleted?.(picture);
    onChanged?.(picture ? [picture!] : undefined, EApiOperator.DELETE);
  };

  const handlePictureEditionModalOpenChange = (open: boolean) => {
    if (!open) {
      setPicToUpdate(undefined);
    }
  };

  useEffect(() => {
    if (acceptedFiles.length || fileRejections.length) {
      if (fileRejections.length) {
        const {message} = fileRejections[0].errors[0];
        AppNotifications.error(message ?? t('message.error.default.title'));
      } else if (acceptedFiles.length) {
        uploadImages(acceptedFiles);
      }
    }
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    setLoading(!!isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (inputRef) {
      onInputInit?.(inputRef);
    }
  }, [inputRef]);

  useEffect(() => {
    setPics(pictures ?? []);
  }, [pictures]);

  return (
    <div className={twMerge('flex flex-col w-full', className)}>
      {!headerHidden && <BlockTitle className='uppercase text-primary justify-between mb-2 px-4 font-bold'><div className='flex'><LuImage size={20} className='mr-2'/> {title ?? t('label.pictures')}</div>{!addFileBtnHidden && !noUploadFiles && <RadButton variant='outline' disabled={readonly} className='text-black' onClick={handleUploadBtnclick}><LuPlusSquare className='mr-1'/> {t('label.addPicture')}</RadButton>}</BlockTitle>}
      <SpinnerLoaderComponent className='min-h-[50px] px-2' isLoading={loading || typeof pics === 'undefined'} error={error ? t('message.error.default.title') : null}>
        <input ref={inputRef} {...getInputProps()}/>
        {!isLoading && !error && Array.isArray(pictures) && pictures.length ? (renderPictures ? renderPictures(pictures) : <RadCarousel className='w-full relative px-8' {...(!noUploadFiles ? getRootProps() : undefined)}>
          <RadCarouselContent>
            {pics?.map((item: Partial<Picture>) => (
              <RadCarouselItem key={`pic-${item.id!}`} className='sm:basis-full md:basis-1/2 lg:basis-1/3 max-w-[320px] pr-1'>
                <PictureCard readonly={readonly} picture={item} onDeleteBtnClick={canDelete !== false ? () => setPicToDelete(item) : undefined} onUpdateBtnClick={() => handleUpdatePictureBtnClicked(item as Picture)}/>
              </RadCarouselItem>
            ))}
          </RadCarouselContent>
          <RadCarouselPrevious className='ml-12'/>
          <RadCarouselNext className='mr-12'/>
        </RadCarousel>) : (!error ? <div className='m-auto h-full text-xs text-center text-gray-400 flex flex-col justify-center' {...(!noUploadFiles ? getRootProps() : undefined)}>{t('message.info.noPictureAvailable')}</div> : undefined)}
      </SpinnerLoaderComponent>
      {(picToDelete) && <DeletePictureModal open picture={picToDelete} onPictureDeleted={handlePictureDeleted} onClose={handleDeletePictureModalClose}/>}
      {!!picToUpdate && <PictureEditionModal
        open
        picture={picToUpdate}
        onSaved={handlePictureUpdated}
        onOpenChange={handlePictureEditionModalOpenChange}
      />}
    </div>
  );
}
