/* eslint-disable complexity */
import {FetchResult, QueryResult, useLazyQuery, useQuery} from '@apollo/client';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import Separator from '@app/components/Common/Separator';
import {Cml, FunctionalLocation, GetAllFlocsAutocompleteQuery, GetAllRefCmlClassesQuery, GetAllRefCmlCodeGroupsQuery, GetAllRefEventTechniquesQuery, GetAllRefFlocCatalogsQuery, GetAllRefFlocPartsQuery, GetAllRefIntegrityConditionsQuery, Grid, RefEventTechnique, RefFlocCatalogs, RefFlocPart, RefIntegrityCondition, RefCmlClass, RefCmlCodeGroup, GetFlocsByIdwgIdQuery, GetGridsByIdwgIdQuery, GetGridsBySectorIdQuery, EFlagStatus} from '@app/graphql/__types__/graphql';
import {CML_REF_CLASSES_GET_MANY, CML_REF_CODE_GROUPS_GET_MANY, FLOCS_GET_BY_IDWG_ID, FLOC_REF_PARTS_GET_MANY, GRIDS_GET_BY_IDWG_ID, GRIDS_GET_BY_SECTOR_ID, INTEGRITY_REF_CONDITIONS_GET_MANY, WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY} from '@app/graphql/requests';
import {FLOC_CATALOGS_GET_MANY} from '@app/graphql/requests/refFlocCatalogs';
import useCmlStore, {MAX_LENGTH_VALIDATORS, ZOD_CML_DATAS} from '@app/stores/cml';
import {TFieldsBlock, TRenderAutocompleteProps} from '@app/types/app';
import {CML_ALARM1, CML_ALARM2, CML_ALARM3, CML_NOMINAL, OBJ_NEW_ID} from '@app/utils/constants';
import {EFLOC_CATALOGS_CATEGORY, EFieldType, EINTEGRITY_REF_CONDITION_CODE} from '@app/utils/enums';
import {handleFormInputKeydown, isStringNotEmpty, renderCodeAndDescription, setObjValueByPath, stringNumFormat, tailwindColorToHex} from '@app/utils/functions';
import React, {InputHTMLAttributes, TextareaHTMLAttributes, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import GridSelectionModal from '../../../Grid/GridBlock/GridSelectionModal';
import FlocSelectionModal from '@app/components/Common/Block/Floc/FlocBlock/FlocSelectionModal';
import {useForm} from 'react-hook-form';
import {z} from 'zod';
import {RadForm} from '@holis/react-ui/rad';
import {zodResolver} from '@hookform/resolvers/zod';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {LuClipboardList} from 'react-icons/lu';
type TGeneralInfo = Readonly<{
  cml: Partial<Cml>;
  readonly?: boolean;
  onFieldUpdated?: (field: string, value: unknown) => void;
  onAlarmChanged?: () => void;
  onFieldBlur?: (field: string, value: unknown) => void;
}>;

export default function GeneralInfo({cml, onFieldUpdated: onfieldUpdated, readonly, onAlarmChanged, onFieldBlur}: TGeneralInfo) {
  const {t} = useTranslation();
  const [codeGroupClass, setCodeGroupClass] = useState<Partial<RefCmlClass>>();
  const [grids, setGrids] = useState<Partial<Grid>[]>([]);
  const [gridSelectionModalDisplayed, changeGridSelectionModalDisplay] = useState<boolean>(false);
  const [flocSelectionModalDisplayed, changeFlocSelectionModalDisplay] = useState<boolean>(false);
  const {editCml, setEditCml, updateCmlData: updateData, updateCmlDataField: updateDataField, hasFieldError} = useCmlStore();
  const [submitRequested, setSubmitRequested] = useState<boolean>(false);
  const [getFlocsByIdwgIdApi, flocsResult] = useLazyQuery<GetFlocsByIdwgIdQuery>(FLOCS_GET_BY_IDWG_ID, {
    fetchPolicy: 'no-cache',
  });
  const refCmlClassResult = useQuery<GetAllRefCmlClassesQuery>(CML_REF_CLASSES_GET_MANY);
  const refCmlCodeGroupResult = useQuery<GetAllRefCmlCodeGroupsQuery>(CML_REF_CODE_GROUPS_GET_MANY);
  const refEventTechniqueResult = useQuery<GetAllRefEventTechniquesQuery>(WORKORDER_REF_EVENT_TECHNIQUES_GET_MANY);
  const refFlocPartResult = useQuery<GetAllRefFlocPartsQuery>(FLOC_REF_PARTS_GET_MANY);
  const refIntegrityCondResult = useQuery<GetAllRefIntegrityConditionsQuery>(INTEGRITY_REF_CONDITIONS_GET_MANY);
  const [getGridsByIdwgIdApi] = useLazyQuery<GetGridsByIdwgIdQuery>(GRIDS_GET_BY_IDWG_ID, {
    variables: {
      idwgId: cml.idwgId,
    },
    fetchPolicy: 'no-cache',
  });
  const [getGridsBySectorIdApi] = useLazyQuery<GetGridsBySectorIdQuery>(GRIDS_GET_BY_SECTOR_ID, {
    variables: {
      sectorId: cml.functionalLocation?.sectorId,
    },
    fetchPolicy: 'no-cache',
  });
  const flocCatalogResult = useQuery<GetAllRefFlocCatalogsQuery>(FLOC_CATALOGS_GET_MANY);
  const isNewForm = cml.id === OBJ_NEW_ID;
  const zodFormObject = z.object(ZOD_CML_DATAS(t));
  const form = useForm<z.infer<typeof zodFormObject>>(
    {resolver: zodResolver(zodFormObject), values: {
      position: editCml?.position?.toString() ?? '',
      description: editCml?.description?.toString() ?? '',
      longDescription: editCml?.longDescription?.toString() ?? '',
      diameter: editCml?.diameter?.toString() ?? '',
      codeGroupId: editCml?.codeGroupId ?? -1,
      flocId: editCml?.flocId ?? -1,
    }, mode: 'onBlur'});
  const htmlForm = useRef<HTMLFormElement | null>(null);
  const handleSelectFloc = (items: Partial<FunctionalLocation>[]) => {
    const item = items.length ? items[0] : null;
    const editedCml = {...editCml};
    setObjValueByPath(editedCml, 'functionalLocation', item);
    setObjValueByPath(editedCml, 'flocId', item?.id ?? null);
    setEditCml(editedCml);
    updateDataField('flocId', item?.id ?? null);
  };

  const handleSelectGrid = (items: Partial<Grid>[]) => {
    const item = items.length ? items[0] : null;
    const editedCml = {...editCml};
    setObjValueByPath(editedCml, 'grid', item);
    setObjValueByPath(editedCml, 'gridId', item?.id ?? null);
    setEditCml(editedCml);
    updateDataField('gridId', item?.id ?? null);
  };

  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    if (['nominal', 'alarm1', 'alarm2', 'alarm3'].includes(field)) {
      value = stringNumFormat(value as string);
    }

    const editedIdwg = {...editCml};
    setObjValueByPath(editedIdwg, field, value);
    setEditCml(editedIdwg);
    if (update) {
      updateDataField(field, value);
    }

    onfieldUpdated?.(field, value);
  };

  const handleFieldBlur = (field: string, val: unknown) => {
    if (['nominal', 'alarm1', 'alarm2', 'alarm3'].includes(field)) {
      onAlarmChanged?.();
    }

    onFieldBlur?.(field, val);
  };

  useEffect(() => {
    if (submitRequested) {
      htmlForm.current?.requestSubmit();

      setSubmitRequested(false);
    }
  }, [submitRequested]);

  const renderAutocomplete = (props: TRenderAutocompleteProps) : React.ReactNode => {
    const {fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps} = props ?? {};
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((updateData && Object.keys(updateData).includes(field!)) || (!(updateData && Object.keys(updateData).includes(field!)) && (item?.id ?? null) !== dbValue)) {
            if (!!item && !!renderMenuItemLabel && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedCml = {...editCml};
            if (foreignField) {
              setObjValueByPath(editedCml, foreignField, item);
            }

            setObjValueByPath(editedCml, field!, item?.id ?? null);
            setEditCml(editedCml);
            updateDataField(field!, item?.id ?? null);
            if (field === 'codeGroupId') {
              const defaultTechId = (item as Partial<RefCmlCodeGroup>).defaultTechniqueId;
              if (!isStringNotEmpty(editedCml.description)) {
                const defaultDesc = (item as Partial<RefCmlCodeGroup>).defaultDesc ?? '';
                setObjValueByPath(editedCml, 'description', defaultDesc);
                setEditCml(editedCml);
                updateDataField('description', defaultDesc);
                onfieldUpdated?.('description', defaultDesc);
              }

              if (defaultTechId) {
                setObjValueByPath(editedCml, 'techniqueId', defaultTechId);
                setObjValueByPath(editedCml, 'technique', refEventTechniqueResult.data?.refEventTechniques.find(tech => (tech as RefEventTechnique).id === defaultTechId));
                setEditCml(editedCml);
                updateDataField('techniqueId', defaultTechId);
                onfieldUpdated?.('techniqueId', defaultTechId);
              }

              setCodeGroupClass((item as Partial<RefCmlCodeGroup>).class);
              onfieldUpdated?.('codeGroupId', item?.id);
            }

            onFieldBlur?.(field!, item?.id ?? null);
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    );
  };

  useEffect(() => {
    setCodeGroupClass(cml?.codeGroup?.class);
    if (isNewForm || cml.flagStatus === EFlagStatus.Y) {
      getFlocsByIdwgIdApi({
        variables: {
          idwgId: cml.idwgId,
        },
      });
    }

    if (cml.idwgId) {
      getGridsByIdwgIdApi().then(result => setGrids((result.data?.grids ?? []) as Partial<Grid>[]));
    } else {
      getGridsBySectorIdApi().then(result => setGrids((result.data?.grids ?? []) as Partial<Grid>[]));
    }
  }, [cml.id]);

  const BLOCK_IDENTIFICATION: TFieldsBlock = {
    title: 'label.identification',
    fields: [
      isNewForm || cml.flagStatus === EFlagStatus.Y ? {
        label: 'label.functionalLocation',
        field: 'flocId',
        initialValue: renderCodeAndDescription({description: cml!.functionalLocation?.description, code: cml!.functionalLocation?.floc}),
        value: renderCodeAndDescription({description: editCml?.functionalLocation?.description, code: editCml?.functionalLocation?.floc}),
        foreignObject: editCml?.functionalLocation,
        foreignField: 'functionalLocation',
        isRequired: true,
        dbValue: cml!.flocId as number,
        hasError: hasFieldError('flocId'),
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocsResult,
        dropdownTransitionClassName: 'top-auto bottom-6 hidden',
        listProps: {
          hasVirtualScroll: true,
          virtualScrollItemSize: 70,
        },
        inputProps: {
          readOnly: true,
          onClick(_e: React.MouseEvent<HTMLElement>) {
            changeFlocSelectionModalDisplay(true);
          },
        },
        getItemsFromResult: (result: QueryResult) => (result as QueryResult<GetAllFlocsAutocompleteQuery>)?.data?.functionalLocations ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<FunctionalLocation>).description, code: (field as Partial<FunctionalLocation>).floc}),
        renderInput: renderAutocomplete,
      } : {
        label: 'label.functionalLocation',
        field: 'functionalLocation.floc',
        fieldType: EFieldType.text,
        initialValue: cml!.functionalLocation?.floc ?? '',
        value: editCml?.functionalLocation?.floc ?? '',
        isDisabled: true,
      },
      {
        label: 'label.position',
        field: 'position',
        fieldType: EFieldType.text,
        initialValue: cml?.position ?? '',
        value: editCml?.position ?? '',
        hasError: hasFieldError('position'),
        isRequired: true,
        inputComponent: 'input',
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.POSITION,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.description',
        field: 'description',
        fieldType: EFieldType.text,
        hasError: hasFieldError('description'),
        isRequired: true,
        initialValue: cml?.description ?? '',
        value: editCml?.description ?? '',
        inputProps: {
          maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.longDescription',
        field: 'longDescription',
        fieldType: EFieldType.text,
        hasError: hasFieldError('longDescription'),
        initialValue: cml?.longDescription ?? '',
        value: editCml?.longDescription ?? '',
        inputComponent: 'textarea',
        inputProps: {
          rows: 2,
          maxLength: MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION,
        } as TextareaHTMLAttributes<HTMLTextAreaElement>,
      },
    ],
  };

  const BLOCK_CLASSIFICATION: TFieldsBlock = {
    title: 'label.classification',
    fields: [
      {
        label: 'label.class',
        field: 'classId',
        initialValue: renderCodeAndDescription({description: cml.codeGroup?.class.description, code: cml.codeGroup?.class.class}),
        value: renderCodeAndDescription({description: codeGroupClass?.description, code: codeGroupClass?.class}),
        foreignObject: codeGroupClass,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refCmlClassResult,
        isRequired: true,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefCmlClassesQuery>)?.data?.refCmlClasses ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefCmlClass>).description, code: (field as Partial<RefCmlClass>).class}),
        onSelect(item) {
          const editedCml = {...editCml};
          setObjValueByPath(editedCml, 'codeGroup', null);
          setObjValueByPath(editedCml, 'codeGroupId', null);
          setEditCml(editedCml);
          updateDataField('codeGroupId', null);
          setCodeGroupClass(item as Partial<RefCmlClass>);
          onFieldBlur?.('classId', (item as Partial<RefCmlClass>).id);
        },
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.codeGroup',
        field: 'codeGroupId',
        initialValue: renderCodeAndDescription({description: cml!.codeGroup?.description, code: cml!.codeGroup?.codeGroup}),
        value: renderCodeAndDescription({description: editCml?.codeGroup?.description, code: editCml?.codeGroup?.codeGroup}),
        foreignObject: editCml?.codeGroup,
        foreignField: 'codeGroup',
        hasError: hasFieldError('codeGroupId'),
        isRequired: true,
        dbValue: cml!.codeGroupId as number,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refCmlCodeGroupResult,
        getItemsFromResult: (result: FetchResult) => ((result as FetchResult<GetAllRefCmlCodeGroupsQuery>)?.data?.refCmlCodeGroups ?? []).filter((item: unknown) => !codeGroupClass || (item as RefCmlCodeGroup).classId === codeGroupClass.id),
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefCmlCodeGroup>).description, code: (field as Partial<RefCmlCodeGroup>).codeGroup}),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.technique',
        field: 'techniqueId',
        initialValue: renderCodeAndDescription({description: cml!.technique?.description, code: cml!.technique?.technique}),
        value: renderCodeAndDescription({description: editCml?.technique?.description, code: editCml?.technique?.technique}),
        foreignObject: editCml?.technique,
        foreignField: 'technique',
        dbValue: cml?.techniqueId as number,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refEventTechniqueResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefEventTechniquesQuery>)?.data?.refEventTechniques ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefEventTechnique>).description, code: (field as Partial<RefEventTechnique>).technique}),
        renderInput: renderAutocomplete,
      },
    ],
  };

  const BLOCK_ATTRIBUTES: TFieldsBlock = {
    title: 'label.attributes',
    fields: [
      {
        label: 'label.part',
        field: 'partId',
        initialValue: renderCodeAndDescription({description: cml!.part?.description, code: cml!.part?.part}),
        value: renderCodeAndDescription({description: editCml?.part?.description, code: editCml?.part?.part}),
        foreignObject: editCml?.part,
        foreignField: 'part',
        dbValue: cml!.partId as number,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: refFlocPartResult,
        getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefFlocPartsQuery>)?.data?.refFlocParts ?? [],
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<RefFlocPart>).description, code: (field as Partial<RefFlocPart>).part}),
        renderInput: renderAutocomplete,
      },
      {
        label: 'label.diameter',
        labelSuffix: <span className='w-full lowercase'>({t('label.inch')})</span>,
        field: 'diameter',
        fieldType: EFieldType.text,
        initialValue: cml?.diameter ?? '',
        value: editCml?.diameter,
        hasError: hasFieldError('diameter'),
        inputComponent: 'input',
        inputProps: {
          className: 'w-[100px]',
          maxLength: MAX_LENGTH_VALIDATORS.DIAMETER,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.externalID',
        field: 'externalRef',
        isDisabled: true,
        fieldType: EFieldType.text,
        initialValue: cml?.externalRef ?? '',
        value: editCml?.externalRef ?? '',
        inputComponent: 'input',
      },
    ],
  };
  const integrityConditions = refIntegrityCondResult.data?.refIntegrityConditions;
  const nominalCondition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.NOMINAL);
  const alarm1Condition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.ALARM1);
  const alarm2Condition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.ALARM2);
  const alarm3Condition: Partial<RefIntegrityCondition> | null | undefined = integrityConditions?.find((item: Partial<RefIntegrityCondition>) => item.condition === EINTEGRITY_REF_CONDITION_CODE.ALARM3);
  const BLOCK_THICKNESS: TFieldsBlock = {
    title: 'label.thickness',
    fields: [
      {
        label: 'label.nominal',
        field: 'nominal',
        fieldType: EFieldType.text,
        initialValue: cml?.nominal ?? '',
        hasError: hasFieldError('nominal'),
        labelClassName: 'w-[100px]',
        value: editCml?.nominal ?? '',
        inputComponent: 'input',
        inputRestrictDecimal: true,
        icon: <Separator height={24} width={8} color={tailwindColorToHex(nominalCondition?.color ?? CML_NOMINAL)}/>,
        labelInputProps: {
          suffixInputComponent: <span className='ml-1 w-full lowercase flex flex-col justify-center'>{editCml?.codeGroup?.unit ?? ''}</span>,
        },
        inputProps: {
          className: 'text-right',
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.alarm1',
        field: 'alarm1',
        labelClassName: 'w-[100px]',
        fieldType: EFieldType.text,
        hasError: hasFieldError('alarm1'),
        initialValue: cml?.alarm1 ?? '',
        value: editCml?.alarm1 ?? '',
        inputComponent: 'input',
        inputRestrictDecimal: true,
        icon: <Separator height={24} width={8} color={tailwindColorToHex(alarm1Condition?.color ?? CML_ALARM1)}/>,
        labelInputProps: {
          suffixInputComponent: <span className='ml-1 w-full lowercase flex flex-col justify-center'>{editCml?.codeGroup?.unit ?? ''}</span>,
        },
        inputProps: {
          className: 'text-right',
          step: 0.01,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.alarm2',
        field: 'alarm2',
        fieldType: EFieldType.text,
        hasError: hasFieldError('alarm2'),
        labelClassName: 'w-[100px]',
        initialValue: cml?.alarm2 ?? '',
        value: editCml?.alarm2 ?? '',
        inputComponent: 'input',
        inputRestrictDecimal: true,
        icon: <Separator height={24} width={8} color={tailwindColorToHex(alarm2Condition?.color ?? CML_ALARM2)}/>,
        labelInputProps: {
          suffixInputComponent: <span className='ml-1 w-full lowercase flex flex-col justify-center'>{editCml?.codeGroup?.unit ?? ''}</span>,
        },
        inputProps: {
          className: 'text-right',
          step: 0.01,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
      {
        label: 'label.alarm3',
        field: 'alarm3',
        fieldType: EFieldType.text,
        labelClassName: 'w-[100px]',
        hasError: hasFieldError('alarm3'),
        initialValue: cml?.alarm3 ?? '',
        value: editCml?.alarm3 ?? '',
        inputComponent: 'input',
        inputRestrictDecimal: true,
        icon: <Separator height={24} width={8} color={tailwindColorToHex(alarm3Condition?.color ?? CML_ALARM3)}/>,
        labelInputProps: {
          suffixInputComponent: <span className='ml-1 w-full lowercase flex flex-col justify-center'>{editCml?.codeGroup?.unit ?? ''}</span>,
        },
        inputProps: {
          className: 'text-right',
          step: 0.01,
        } as InputHTMLAttributes<HTMLInputElement>,
      },
    ],
    className: 'min-w-[300px] w-1/2',
  };

  const BLOCK_LOCATION: TFieldsBlock = {
    title: 'label.location',
    fields: [
      {
        label: 'label.grid',
        field: 'gridId',
        initialValue: renderCodeAndDescription({description: cml!.grid?.description, code: cml!.grid?.grid}),
        value: renderCodeAndDescription({description: editCml?.grid?.description, code: editCml?.grid?.grid}),
        foreignObject: editCml?.grid,
        foreignField: 'grid',
        dbValue: cml!.gridId as number,
        fieldType: EFieldType.autocomplete,
        items: grids,
        renderMenuItemLabel: field => renderCodeAndDescription({description: (field as Partial<Grid>).description, code: (field as Partial<Grid>).grid}),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6 hidden',
        inputProps: {
          readOnly: true,
          onClick(_e: React.MouseEvent<HTMLElement>) {
            changeGridSelectionModalDisplay(true);
          },
        },
      },
      {
        label: 'label.accessibility',
        field: 'accessibilityId',
        initialValue: renderCodeAndDescription(cml?.accessibility),
        value: renderCodeAndDescription(editCml?.accessibility),
        foreignObject: editCml?.accessibility,
        foreignField: 'accessibility',
        dbValue: cml.accessibilityId,
        fieldType: EFieldType.autocomplete,
        itemsQueryResult: flocCatalogResult,
        getItemsFromResult: (result: FetchResult) => ((result as FetchResult<GetAllRefFlocCatalogsQuery>)?.data?.findManyRefFlocCatalogs ?? []).filter((item: Partial<RefFlocCatalogs>) => item.category === EFLOC_CATALOGS_CATEGORY.ACCESSIBILITY),
        renderMenuItemLabel: field => renderCodeAndDescription(field ? field as Partial<RefFlocCatalogs> : null),
        renderInput: renderAutocomplete,
        dropdownTransitionClassName: 'top-auto bottom-6',
      },
    ],
  };
  const fieldBlocks = [BLOCK_IDENTIFICATION, BLOCK_CLASSIFICATION, BLOCK_ATTRIBUTES, BLOCK_THICKNESS, BLOCK_LOCATION];

  return (
    <div className='gap-2 px-3 py-2 w-full overflow-auto'>
      <RadForm {...form}>
        <form
          ref={htmlForm}
        >
          <FormFieldsBlock
            isDisabled={readonly}
            prefixNode={<FormGroupHeader>
              <div className='flex items-center font-bold'>
                <LuClipboardList size={20} className='mr-2'/> {t('label.headerData')}
              </div>
            </FormGroupHeader>} fieldsBlocks={fieldBlocks} onFieldChange={handleFieldChange}
            onFieldBlur={handleFieldBlur}/>
        </form>
      </RadForm>
      {gridSelectionModalDisplayed && <GridSelectionModal open hasItems items={grids} isMultiple={false} onValidate={handleSelectGrid} onClose={() => changeGridSelectionModalDisplay(false)}/> }
      {flocSelectionModalDisplayed && <FlocSelectionModal open hasItems queryResult={flocsResult} items={flocsResult.data?.functionalLocations ?? []} isMultiple={false} onValidate={handleSelectFloc} onClose={() => changeFlocSelectionModalDisplay(false)}/> }
    </div>
  );
}
