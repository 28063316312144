import {gql} from '@apollo/client';
import {SCHEDULING_ITEM_TASKS_COMMON_FIELDS, WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS} from '../fragments';

export const SCHEDULING_ITEM_TASKS_GET_BY_ITEM_ID = gql`
${SCHEDULING_ITEM_TASKS_COMMON_FIELDS}
${WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS}
query GetItemTasksByItemId($itemId: Int!) {
  itemTasks (where: {itemId: {equals: $itemId}}, orderBy:  [{sort: {sort: asc}}]) {
    ...ItemTaskCommonFields
    task {
      ...RefEventTaskCommonFields
    }
  }
}`;

export const SCHEDULING_ITEM_TASKS_CREATE_MANY = gql`
mutation CreateItemTasks($data: [ItemTaskCreateManyInput!]!) {
  createManyItemTask(data: $data) {
    count
  }
}`;

export const SCHEDULING_ITEM_TASKS_DELETE_BY_TASK_IDS_AND_ITEM_ID = gql`
mutation DeleteItemTasksByTaskIdsAndItemId($itemId: Int!, $taskIds: [Int!]) {
  deleteManyItemTask(where: {itemId: {equals: $itemId}, taskId: {in: $taskIds}}) {
    count
  }
}`;

export const SCHEDULING_ITEM_TASKS_DELETE_ONE_BY_ID = gql`
mutation DeleteOneItemTaskById($id: Int!) {
  deleteOneItemTask(where: {id: $id}) {
    id
  }
}
`;

export const SCHEDULING_ITEM_TASKS_UPDATE_BY_ID = gql`
${SCHEDULING_ITEM_TASKS_COMMON_FIELDS}
${WORKORDER_REF_EVENT_TASKS_COMMON_FIELDS}
mutation UpdateOneItemTaskById($id: Int!, $data: ItemTaskUncheckedUpdateInput!) {
  updateOneItemTask(where: {id: $id}, data: $data) {
    ...ItemTaskCommonFields
    task {
      ...RefEventTaskCommonFields
    }
  }
}
`;

export const SCHEDULING_ITEM_TASKS_GET_COUNTER = gql`
query GetItemTasksCounter {
  aggregateItemTask {
    _count {
      id
    }
  }
}
`;
