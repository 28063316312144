import {InspectionDrawing} from '@app/graphql/__types__/graphql';
import IdwgInfo from './IdwgInfo';
import SinglePageVerticalTabs from '@app/components/Common/Tabs/SinglePageVerticalTabs';
import {TAppTabItem} from '@app/types/app';
import FlocList from './ObjectList/FlocBlock';
import {useTranslation} from 'react-i18next';
import GridList from './GridList';
import CmlList from './CmlList';
import DamageList from './DamageList';
import useIdwgStore from '@app/stores/idwg';

type TLeftSide = Readonly<{
    idwg: Partial<InspectionDrawing>;
}>;

export default function LeftSide({idwg}: TLeftSide) {
  const {t} = useTranslation();
  const {flocs, grids, cmls, damages} = useIdwgStore();
  const items: TAppTabItem[] = [
    {
      title: t('label.general'),
      content: <IdwgInfo idwg={idwg}/>,
      value: 'idwgInfo',
    },
    {
      title: t('label.floc'),
      content: <FlocList idwg={idwg}/>,
      value: 'floc',
      counterValue: flocs?.length ?? idwg?._count?.idwgFlocs ?? 0,
    },
    {
      content: <GridList idwg={idwg}/>,
      value: 'grid',
      title: t('label.grid'),
      counterValue: grids?.length ?? idwg?._count?.idwgGrids ?? 0,
    },
    {
      content: <CmlList idwg={idwg}/>,
      value: 'cml',
      title: t('label.cml'),
      counterValue: cmls?.length ?? idwg?._count?.cmls ?? 0,
    },
    {
      content: <DamageList idwg={idwg}/>,
      value: 'damage',
      title: t('label.damage'),
      counterValue: damages?.length ?? idwg?._count?.damages ?? 0,
    },
  ];
  return (
    <div className='h-full w-full overflow-auto'>
      <SinglePageVerticalTabs
        items={items}
      />
    </div>
  );
}
