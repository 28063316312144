import {Document} from '@app/graphql/__types__/graphql';
import DocInfo from './DocInfo';
import FlocBlock from './FlocBlock';
import SinglePageVerticalTabs from '@app/components/Common/Tabs/SinglePageVerticalTabs';
import {TAppTabItem} from '@app/types/app';
import {useTranslation} from 'react-i18next';
import {useDocumentStore} from '@app/stores/document';

type TLeftSide = Readonly<{
  doc: Partial<Document>;
}>;

export default function LeftSide({doc}: TLeftSide) {
  const {t} = useTranslation();
  const {flocs} = useDocumentStore();
  const items: TAppTabItem[] = [
    {
      title: t('label.general'),
      content: <DocInfo doc={doc}/>,
      value: 'docInfo',
    },
    {
      title: t('label.floc'),
      content: <FlocBlock doc={doc}/>,
      value: 'floc',
      counterValue: flocs?.length ?? doc?._count?.flocDocs ?? 0,
    },
  ];
  return (
    <div className='h-full w-full overflow-auto'>

      <SinglePageVerticalTabs
        items={items}
      />

    </div>
  );
}
