import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu';
import {Attachment} from '@app/graphql/__types__/graphql';
import AppNotifications from '@app/services/notification';
import useAttachmentStore from '@app/stores/attachment';
import {useLayoutStore} from '@app/stores/layout';
import {TDownloadProps} from '@app/types/app';
import {addTokenToUrl, downloadFileFromUrl} from '@app/utils/functions';
import {useHolisAuth} from '@holis/auth-client-react';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {LuDownload, LuTrash2, LuUpload} from 'react-icons/lu';

type TActions = Readonly<TDownloadProps & {
  item: Partial<Attachment>;
  className?: string;
  inputRef: React.RefObject<HTMLInputElement>;
  onMenuDisplayChange?: (menuShown: boolean, containerRef: React.RefObject<HTMLDivElement>) => void;
}>;

export default function Actions({downloadUrl, item, inputRef, isExternalSrc, downloadFileName, className}: TActions) {
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const {getAccessToken} = useHolisAuth();
  const {setItemToDelete} = useAttachmentStore();
  const onFileUploadClick = () => {
    inputRef.current?.click();
  };

  const deleteAttachment = () => {
    setItemToDelete(item);
  };

  const downloadFile = async () => {
    let url = downloadUrl;
    if (!isExternalSrc && url) {
      url = await addTokenToUrl(url, getAccessToken);
    }

    if (url) {
      startLoading();
      downloadFileFromUrl(url, downloadFileName ?? `file.${item.extension}`, {
        onFail() {
          AppNotifications.error(t('message.error.default.title'));
        },
        onEnd() {
          stopLoading();
        },
      });
    } else {
      AppNotifications.error(t('message.error.fileNotFound'));
    }
  };

  return (
    <div className={className}>
      <ActionThreeDotsMenu>
        <RadDropdownMenuItem onClick={onFileUploadClick}><LuUpload className='mr-2'/> {t('label.uploadFile')}</RadDropdownMenuItem>
        {downloadUrl && <RadDropdownMenuItem onClick={downloadFile}><LuDownload className='mr-2'/> {t('label.downloadFile')}</RadDropdownMenuItem>}
        <RadDropdownMenuItem className='item-destructive' onClick={deleteAttachment}><LuTrash2 className='mr-2'/> {t('label.deleteAttachment')}</RadDropdownMenuItem>
      </ActionThreeDotsMenu>
    </div>
  );
}
