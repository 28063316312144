import React, {useEffect, useState} from 'react';
import {useLazyQuery} from '@apollo/client';
import {InspectionPoint, Picture, Event, GetPicturesByQuery} from '@app/graphql/__types__/graphql';
import {PICTURES_GET_MANY_BY} from '@app/graphql/requests';
import {TCarouselCallback} from '@holis/react-ui';
import Separator from '@app/components/Common/Separator';
import styled from 'styled-components';
import BasePictureCarousel from '@app/components/Common/Block/Picture/Block/PictureCarousel';
import {EApiOperator} from '@app/utils/enums';

const StyledSeparator = styled(Separator)`
  width: calc(100% - 20px);
`;

type TPictureCarousel = Readonly<{
  onCarouselRender?: TCarouselCallback;
  inspectionPoint: Partial<InspectionPoint>;
  event?: Partial<Event>;
  readonly?: boolean;
  onChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
}>

export default function PictureCarousel({inspectionPoint, onCarouselRender, onChanged, event, readonly}: TPictureCarousel) {
  const [getPicturesApi, {data, loading, error, refetch}] = useLazyQuery<GetPicturesByQuery>(PICTURES_GET_MANY_BY);
  const [isLoading, setLoading] = useState<boolean>(loading);
  const handleImagesChanged = (images?: Partial<Picture>[], operator?: EApiOperator) => {
    refetch();
    onChanged?.(images, operator);
  };

  useEffect(() => {
    getPicturesApi({
      variables: {
        data: {
          inspId: {
            equals: inspectionPoint.id!,
          },
          ...(event?.id ? {wrkoId: {equals: event.id!}} : {}),
        },
        orderBy: [
          {date: {sort: 'desc'}},
          {id: 'desc'},
        ],
      },
      fetchPolicy: 'no-cache',
    });
  }, [inspectionPoint.id, inspectionPoint.flocId, event?.id]);

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div className='flex flex-col w-full'>
      <BasePictureCarousel pictures={data?.pictures as Partial<Picture>[]} readonly={readonly} isLoading={isLoading} uploadParameters={{inspId: inspectionPoint!.id!, ...(event?.id ? {wrkoId: event?.id} : {}), flocId: inspectionPoint.flocId}} error={error} onChanged={handleImagesChanged} onCarouselRender={onCarouselRender}/>
      <StyledSeparator height={1.5} className='mt-8 mr-4'/>
    </div>
  );
}
