import ActionThreeDotsMenu from '@app/components/Common/Form/ActionThreeDotsMenu';
import PanelFilters from '@app/components/Common/Panel/PanelFilters';
import {InspectionDrawing} from '@app/graphql/__types__/graphql';
import useIdwgStore from '@app/stores/idwg';
import {useInspectionDrawingStore} from '@app/stores/methodEngineering/inspectionDrawing';
import {OBJ_NEW_ID, ROUTE_DOCUMENTATION_IDWG_DETAIL} from '@app/utils/constants';
import {generatePathWithBaseUrl} from '@app/utils/functions';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {RadButton, RadDropdownMenuItem} from '@holis/react-ui/rad';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuChevronsLeft, LuPictureInPicture2, LuUpload} from 'react-icons/lu';
import {generatePath} from 'react-router-dom';

type THeader = Readonly<{
  selectedDrawing?: Partial<InspectionDrawing>;
  goBack?: () => void;
  nbDrawings?: number;
  hideActions?: boolean;
  hideCreate?: boolean;
}>;

export default function Header({selectedDrawing, goBack, nbDrawings: _nbDrawings, hideActions, hideCreate}: THeader) {
  const {t} = useTranslation();
  const prms = useUserPermissions();
  const {
    changeCmlMarkupsDisplay,
    changeDamageMarkupsDisplay,
    cmlMarkupsShown,
    damageMarkupsShown,
  } = useIdwgStore();

  const {
    setActiveInspectionDrawing,
  } = useInspectionDrawingStore();

  const [filters, setFilters] = useState<string[]>(['cml', 'damage']);

  const createNewIdwg = () => {
    setActiveInspectionDrawing({
      id: OBJ_NEW_ID,
    });
  };

  const openDrawing = () => {
    window.open(generatePathWithBaseUrl(generatePath(ROUTE_DOCUMENTATION_IDWG_DETAIL, {number: selectedDrawing?.idwg!})), '_blank');
  };

  const handleFilterChanged = (values: string[]) => {
    changeCmlMarkupsDisplay(values.includes('cml'));
    changeDamageMarkupsDisplay(values.includes('damage'));
  };

  useEffect(() => {
    setFilters(Object.entries({
      cml: cmlMarkupsShown,
      damage: damageMarkupsShown,
    }).filter(([_, value]) => value).map(([key]) => key));
  }, [cmlMarkupsShown, damageMarkupsShown]);

  useEffect(() => {
    changeCmlMarkupsDisplay(true);
    changeDamageMarkupsDisplay(true);
  }, []);

  return (
    <div className='flex items-center mb-2'>
      <div className='text-gray-700 text-sm font-semibold'>
        {selectedDrawing ? (
          <div className='flex items-center'>
            {goBack && (
              <RadButton
                variant='outline'
                size='icon'
                className='mr-4'
                onClick={() => goBack()}
              >
                <LuChevronsLeft/>
              </RadButton>
            )}

            <div>
              <div className='flex gap-2 items-center -mt-1'>
                {selectedDrawing?.idwg}
                <div className='mt-1'>
                  <ActionThreeDotsMenu height={8}>
                    <RadDropdownMenuItem onClick={openDrawing}>
                      <LuPictureInPicture2 className='mr-2'/>

                      {t('label.openDrawing')}
                    </RadDropdownMenuItem>
                  </ActionThreeDotsMenu>
                </div>
              </div>

              <div className='text-xs font-normal -mt-1'>
                {selectedDrawing?.description}
              </div>
            </div>
          </div>
        ) : (
          <div className='flex gap-2 items-center'>
            {t('label.drawingList')}

            { prms.drawings.create && !hideCreate && (
              <ActionThreeDotsMenu>
                <RadDropdownMenuItem
                  onClick={createNewIdwg}>
                  <LuUpload className='mr-2'/> {t('label.addFromDesktop')}</RadDropdownMenuItem>
              </ActionThreeDotsMenu>
            )}
          </div>
        )}
      </div>

      {!hideActions && <div className='flex-1 flex justify-end'>
        <PanelFilters<string>
          isMultiple
          items={[
            {label: t('label.displayCmls'), value: 'cml'},
            {label: t('label.displayDamages'), value: 'damage'},
          ]}
          value={filters}
          onChange={values => handleFilterChanged(values.map(v => v.value))}
        />
      </div>}
    </div>
  );
}
