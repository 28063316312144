import {NO_DRAWING_ID} from '@app/utils/constants';
import {useEffect, useState} from 'react';
import DamageCard from '../DamageBlock/DamageCard';
import {useTranslation} from 'react-i18next';
import {LuMapPin} from 'react-icons/lu';
import {useLazyQuery, useMutation} from '@apollo/client';
import {DAMAGES_GET_BY_FLOC_IDS, DAMAGES_UPDATE_IDWG_MANY} from '@app/graphql/requests';
import {TDbId, TObjId} from '@app/types/app';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import React from 'react';
import useIdwgStore, {IDWG_PREFIX} from '../../../../../stores/idwg';
import DrawingObjectSelectionConfirmModal from '../../../../Modal/Confirm/DrawingObjectSelection';
import {InspectionDrawing, GetDamagesByFlocIdsQuery, Damage, UpdateIdwgDamagesMutation, Cml} from '../../../../../graphql/__types__/graphql';

type TIdwgDamageSelectionModal = Readonly<{
  idwg: Partial<InspectionDrawing>;
}>;

export default function IdwgDamageSelectionModal({idwg}: TIdwgDamageSelectionModal) {
  const {t} = useTranslation();
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>();
  const {startLoading, stopLoading} = useLayoutStore();
  const {damages, flocs, fetchDamages, idwgFlocDamages, setIdwgFlocDamages, changeDamagesSelectionModalDisplay, damagesSelectionDisplayed} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);

  const [updateIdwgDamagesApi] = useMutation<UpdateIdwgDamagesMutation>(DAMAGES_UPDATE_IDWG_MANY);

  const [getIdwgFlocDamagesApi, {loading}] = useLazyQuery<GetDamagesByFlocIdsQuery>(DAMAGES_GET_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  });

  const addItems = (ids: TDbId[]) => updateIdwgDamagesApi({
    variables: {
      ids,
      idwg: idwg.id!,
    },
  });

  const deleteItems = (ids: TDbId[]) => updateIdwgDamagesApi({
    variables: {
      ids,
      idwg: null,
    },
  });

  const handleValidateSelection = async (selectedItems: Partial<TObjId>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = [];
    if (oldSelectedIds.length) {
      const damageIdsToDelete = (damages?.filter((item: Partial<Damage>) => oldSelectedIds.includes(item.id!)).map((item: Partial<Damage>) => item.id) ?? []) as TDbId[];
      if (damageIdsToDelete.length) {
        promises.push(deleteItems(damageIdsToDelete));
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds));
    }

    if (promises.length) {
      startLoading();
      try {
        for (const asyncCall of promises) {
          await asyncCall;
        }

        fetchDamages?.();
        changeDamagesSelectionModalDisplay(false);
        AppNotifications.success(t('message.success.eventDamagesUpdated'));
      } catch (err) {
        AppNotifications.error(t('message.error.default.title'));
      }

      stopLoading();
    } else {
      changeDamagesSelectionModalDisplay(false);
    }
  };

  const getIdwgFlocDamages = () => {
    if (Array.isArray(flocs) && flocs.length) {
      getIdwgFlocDamagesApi({
        variables: {
          flocIds: flocs.map(item => item.id!),
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setIdwgFlocDamages((queryResult.data?.damages ?? []) as Partial<Damage>[]);
      });
    } else {
      setIdwgFlocDamages([]);
    }
  };

  useEffect(() => {
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      idwg,
    ]);
  }, [idwg]);

  useEffect(() => {
    getIdwgFlocDamages();
  }, [flocs]);

  return (
    <DrawingObjectSelectionConfirmModal
      hasItems
      isMultiple
      cmlHidden
      isLoading={loading}
      title={t('label.manageDamages')}
      description={t('label.manageDamagesDescription')}
      headerTitle={<div className='flex items-center text-primary'><LuMapPin/> {t('label.damage')}</div>}
      drawings={drawings}
      open={damagesSelectionDisplayed}
      items={idwgFlocDamages ?? []}
      drawingStoreIdSuffix='damage-selection-modal'
      selectedIds={damages?.map(item => item.id!)}
      renderItem={(item: Partial<Damage | Cml>, drawingStoreId: string) => <DamageCard hasMenuContext displayLastQualReadingWithColor display2dDisabled drawingStoreId={drawingStoreId} objectItem={idwg} dmgObject={item as Partial<Damage>}/>}
      onValidate={handleValidateSelection}
      onClose={() => changeDamagesSelectionModalDisplay(false)}/>
  );
}
