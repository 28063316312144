import {gql} from '@apollo/client';
import {APP_EVENT_STAGES_COMMON_FIELDS} from '../fragments';

export const APP_EVENT_STAGES_GET_MANY = gql`
${APP_EVENT_STAGES_COMMON_FIELDS}
query GetAllEventStages($orderBy: [StageWorkOrderOrderByWithRelationInput!], $where: StageWorkOrderWhereInput) {
    stageWorkOrders(orderBy: $orderBy, where: $where) {
      ...EventStageCommonFields
    }
}`;
