import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import {Event, EventCml, EventDamage, EventInspectionPoint, RefCmlValCode, RefDamageValCode, RefMeasPointValCode} from '@app/graphql/__types__/graphql';
import {renderCodeAndDescription, renderQualReadingItem} from '@app/utils/functions';
import React from 'react';
import styled from 'styled-components';
type TEventMeasurementQualReading<TDataRow extends Partial<EventCml | EventDamage | EventInspectionPoint>, TRefQualReading extends Partial<RefCmlValCode | RefDamageValCode | RefMeasPointValCode>> = Readonly<{
  event?: Partial<Event>;
  isEditableRow: (rowData: Partial<TDataRow>) => boolean;
  dataRow: unknown;
  items?: Partial<TRefQualReading>[]
  updateHandler: (item: Partial<TDataRow>, field: string, val: number | null) => void;
  onClick?: (rowData: Partial<TDataRow>) => void;
}>;

const StyledReadingContainer = styled.div`
  width: calc(100% - 10px);
`;

const StyledReadingContent = styled.div`
  width: calc(100% - 10px);
`;

const StyledAutocomplete = styled(AppAutocomplete)`
  width: calc(100% - 10px);
`;

export default function EventMeasurementQualReading<TDataRow extends Partial<EventCml | EventDamage | EventInspectionPoint>, TRefQualReading extends Partial<RefCmlValCode | RefDamageValCode | RefMeasPointValCode>>({isEditableRow, dataRow, items, onClick, updateHandler}: TEventMeasurementQualReading<TDataRow, TRefQualReading>) {
  const containerRef = React.createRef<HTMLDivElement>();
  const rowData = dataRow as Partial<TDataRow>;
  const handleClick = () => {
    if (isEditableRow(rowData)) {
      onClick?.(rowData);
    }
  };

  return (
    <StyledReadingContainer ref={containerRef} className={`mt-1 inline-flex justify-start flex-row items-center gap-1 ${onClick ? 'hover:cursor-pointer' : ''}`} onClick={handleClick}>
      {isEditableRow(rowData) && <StyledAutocomplete
        inputClassName='!bg-white px-0 pl-1'
        field='qualReadingId'
        autocompleteClassName='reading-autocomplete hidden break-words  ml-[10px]'
        label={false}
        dropdownTransitionClassName='h-[30px] -top-[25px] hidden'
        inputProps={
          {
            defaultValue: renderCodeAndDescription({description: rowData.qualReading?.description, code: rowData.qualReading?.valCode}),
          }
        }
        items={items ?? []}
        renderMenuItemLabel={field => renderCodeAndDescription({description: (field as Partial<TRefQualReading>).description, code: (field as Partial<TRefQualReading>).valCode})}
        listProps={{
          onTitleClick() {
            updateHandler(rowData, 'qualReadingId', null);
          },
          className: 'h-[60px]',
        }}
        onSelect={(valCodeItem: Record<string, unknown> | null) => {
          updateHandler(rowData, 'qualReadingId', (valCodeItem as Partial<TRefQualReading>).id ?? null);
        }}
      />}
      <StyledReadingContent className='reading-content ml-[10px] line-clamp-1'>{renderQualReadingItem(rowData.qualReading)}</StyledReadingContent>
    </StyledReadingContainer>
  );
}
