import {gql} from '@apollo/client/core';
import {FLOCS_AUTOCOMPLETE_COMMON_FIELDS, INSPECTION_DRAWING_VIEWS_COMMON_FIELDS, INSPECTION_DRAWINGS_COMMON_FIELDS, INSPECTION_DRAWINGS_COUNTER_FIELDS, WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS} from '../fragments';
import {GRIDS_AUTOCOMPLETE_COMMON_FIELDS} from '../fragments/grids';

export const INSPECTION_DRAWING_VIEWS_GET_ALL = gql`
${INSPECTION_DRAWING_VIEWS_COMMON_FIELDS}
query GetAllInspectionDrawingViews($sites: [String!], $orderBy: [InspectionDrawingViewOrderByWithRelationInput!]) {
  inspectionDrawingViews (where:  {
     sector_site_site:  {
        in: $sites
     }
  } orderBy: $orderBy) {
    ...InspectionDrawingViewCommonFields
  }
}`;

export const INSPECTION_DRAWINGS_GET_BY_FLOC_ID = gql`
${INSPECTION_DRAWINGS_COMMON_FIELDS}
query GetInspectionDrawingsByFlocId($flocId: Int!, $orderBy: [InspectionDrawingOrderByWithRelationInput!]) {
  inspectionDrawings (where: {idwgFlocs: {some: {flocId: {equals: $flocId}}}}, orderBy: $orderBy) {
    ...InspectionDrawingCommonFields
  }
}`;

export const INSPECTION_DRAWINGS_GET_BY_FLOC_IDS = gql`
${INSPECTION_DRAWINGS_COMMON_FIELDS}
query GetInspectionDrawingsByFlocIds($flocIds: [Int!], $orderBy: [InspectionDrawingOrderByWithRelationInput!]) {
  inspectionDrawings (where: {idwgFlocs: {some: {flocId: {in: $flocIds}}}}, orderBy: $orderBy) {
    ...InspectionDrawingCommonFields
  }
}`;

export const INSPECTION_DRAWINGS_GET_COUNTER = gql`
query GetIdwgsCounter {
  aggregateInspectionDrawing {
    _count {
      id
    }
  }
}
`;

export const INSPECTION_DRAWINGS_CREATE = gql`
${INSPECTION_DRAWINGS_COMMON_FIELDS}
mutation CreateInspectionDrawing($data: InspectionDrawingUncheckedCreateInput!) {
  createOneInspectionDrawing(data: $data) {
    ...InspectionDrawingCommonFields
  }
}`;

export const INSPECTION_DRAWINGS_UPDATE_BY_ID = gql`
${INSPECTION_DRAWINGS_COMMON_FIELDS}
mutation UpdateInspectionDrawingById($id: Int!, $data: InspectionDrawingUncheckedUpdateInput!) {
  updateOneInspectionDrawing(data: $data, where: {id: $id}) {
    ...InspectionDrawingCommonFields
  }
}`;

export const INSPECTION_DRAWINGS_DELETE_BY_ID = gql`
mutation DeleteInspectionDrawingById($id: Int!) {
  deleteOneInspectionDrawing(where: {id: $id}) {
    id
  }
}`;

export const INSPECTION_DRAWINGS_GET_DETAIL = gql`
${FLOCS_AUTOCOMPLETE_COMMON_FIELDS}
${GRIDS_AUTOCOMPLETE_COMMON_FIELDS}
${WORKORDER_REF_EVENT_TECHNIQUES_COMMON_FIELDS}
${INSPECTION_DRAWINGS_COUNTER_FIELDS}
query GetInspectionDrawingDetail($id: Int!) {
  inspectionDrawing(where: {id: $id}) {
    idwgGrids {
      grid {
        ...GridAutocompleteCommonFields
      }
    }
    idwgFlocs {
      functionalLocation {
        ...FlocAutocompleteCommonFields
      }
    }
    idwgTechniques {
      technique {
        ...RefEventTechniqueCommonFields
      }
    }
    ...InspectionDrawingCounterFields
  }
}`;

export const INSPECTION_DRAWINGS_GET_DETAIL_BY_NUMBER = gql`
${INSPECTION_DRAWINGS_COMMON_FIELDS}
${INSPECTION_DRAWINGS_COUNTER_FIELDS}
query GetInspectionDrawingDetailByNumber($number: String!) {
  inspectionDrawing(where: {idwg: $number}) {
    ...InspectionDrawingCommonFields
    ...InspectionDrawingCounterFields
  }
}`;

export const INSPECTION_DRAWINGS_ADD_IDWG_FLOC = gql`
  mutation AddIdwgFloc($idwgId: Int!, $flocId: Int!) {
    createOneIdwgFloc(data: {idwgId: $idwgId, flocId: $flocId}) {
      flocId
      idwgId
    }
  }
`;

export const INSPECTION_DRAWINGS_ADD_IDWG_FLOCS = gql`
  mutation AddIdwgFlocs($data: [IdwgFlocCreateManyInput!]!) {
    createManyIdwgFloc(data: $data, skipDuplicates: true) {
      count
    }
  }
`;

export const INSPECTION_DRAWINGS_DELETE_IDWG_FLOC = gql`
  mutation DeleteOneIdwgFloc($idwgId: Int!, $flocId: Int!) {
    deleteOneIdwgFloc(where: {flocId_idwgId: {idwgId: $idwgId, flocId: $flocId}}) {
      flocId
      idwgId
    }
  }
`;

export const INSPECTION_DRAWINGS_ADD_IDWG_GRIDS = gql`
  mutation AddIdwgGrids($data: [IdwgGridCreateManyInput!]!) {
    createManyIdwgGrid(data: $data, skipDuplicates: true) {
      count
    }
  }
`;

export const INSPECTION_DRAWINGS_DELETE_IDWG_GRID = gql`
  mutation DeleteOneIdwgGrid($idwgId: Int!, $gridId: Int!) {
    deleteOneIdwgGrid(where: {gridId_idwgId: {idwgId: $idwgId, gridId: $gridId}}) {
      gridId
      idwgId
    }
  }
`;
