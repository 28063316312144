import {TPDFFile} from '@app/components/Common/Viewer/PDFViewer';
import {Cml, CmlUncheckedCreateInput, CmlUncheckedUpdateInput, Damage, DamageUncheckedCreateInput, DamageUncheckedUpdateInput, Drawing, EventCml, EventDamage, FunctionalLocation, Grid, IdwgFloc, IdwgGrid, InspectionDrawing, InspectionDrawingUncheckedCreateInput, InspectionDrawingUncheckedUpdateInput, InspectionDrawingView} from '@app/graphql/__types__/graphql';
import {TDimension, TMarkupObjects} from '@app/types/app';
import {OBJ_NEW_ID} from '@app/utils/constants';
import * as fabric from 'fabric';
import {ReactZoomPanPinchState} from 'react-zoom-pan-pinch';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

const TAB_CMLS = 0;
const TAB_DAMAGES = 1;
const CML_PREFIX = 'cml-';
const DAMAGE_PREFIX = 'dmg-';

// All state in inspection drawing page (list, detail modal, cml modal, damage modal)
type State = {
  canvas?: fabric.Canvas | null;
  currentBgImg?: HTMLImageElement | null | HTMLCanvasElement;
  currentBgDimension?: TDimension;
  bgImg?: HTMLImageElement | null | HTMLCanvasElement;
  bgDimension?: TDimension;
  activeInspectionDrawing?: Partial<InspectionDrawing>|null;
  inspectionDrawings?: Partial<InspectionDrawing>[];
  inspectionDrawingViews?: Partial<InspectionDrawingView>[];
  deleteInspectionDrawingModalOpen: boolean;
  deleteCmlModalOpen: boolean;
  deleteDamageModalOpen: boolean;
  flocToDelete?: Partial<FunctionalLocation>;
  flocs?: Partial<FunctionalLocation>[];
  flocsSelectionDisplayed: boolean;
  gridsSelectionDisplayed: boolean;
  gridToDelete?: Partial<Grid>;
  updateData?: InspectionDrawingUncheckedUpdateInput | InspectionDrawingUncheckedCreateInput;
  editCml?: Partial<Cml> | null;
  updateCmlData?: CmlUncheckedUpdateInput | CmlUncheckedCreateInput;
  editIdwg?: Partial<InspectionDrawing> | null;
  creatingIdwg: boolean;
  allMarkups?: Record<string, TMarkupObjects>;
  viewerState?: TZoomViewerState;
  initialCanvasWidth?: number;
  initialCanvasHeight?: number;
  scale: number;
  selectedTab: number;
  editDamage?: Partial<Damage> | null;
  updateDamageData?: DamageUncheckedUpdateInput | DamageUncheckedCreateInput;
  modalIdwgDetailShown: boolean;
  fieldErrors: Record<string, boolean>;
  cmlFieldErrors: Record<string, boolean>;
  damageFieldErrors: Record<string, boolean>;
  uploadFile?: File;
  activeCml?: Partial<Cml>;
  activeDamage?: Partial<Damage>;
  latestEventCml?: Record<number, Partial<EventCml> | null>;
  cmlShapeColors?: Record<number, string | null>;
  latestEventDamages?: Record<number, Partial<EventDamage> | null>;
  damageShapeColors?: Record<number, string | null>;
  allMarkupsInit: boolean;
  activeMarkupId?: string;
  fileUploadCounter: number;
  currentDrawingSrc?: TPDFFile;
  drawingSrc?: TPDFFile;
  idwgGrids?: Partial<IdwgGrid>[];
  damageInactiveShown: boolean;
  cmlInactiveShown: boolean;
  fetchDamages?: () => void;
  fetchCmls?: () => void;
  fetchInspectionDrawings?: () => void;
  fileDrawing?: Partial<Drawing> | null;
  nativeUploadFile?: File;
}

const initialState: State = {
  deleteInspectionDrawingModalOpen: false,
  deleteCmlModalOpen: false,
  deleteDamageModalOpen: false,
  flocToDelete: undefined,
  flocsSelectionDisplayed: false,
  gridsSelectionDisplayed: false,
  gridToDelete: undefined,
  canvas: null,
  updateData: undefined,
  editDamage: null,
  updateDamageData: {},
  editIdwg: null,
  creatingIdwg: false,
  editCml: null,
  updateCmlData: {},
  allMarkups: {},
  bgImg: null,
  activeInspectionDrawing: null,
  viewerState: undefined,
  initialCanvasWidth: undefined,
  initialCanvasHeight: undefined,
  scale: 1,
  selectedTab: TAB_CMLS,
  modalIdwgDetailShown: false,
  fieldErrors: {},
  cmlFieldErrors: {},
  damageFieldErrors: {},
  uploadFile: undefined,
  activeCml: undefined,
  bgDimension: undefined,
  currentBgImg: undefined,
  activeDamage: undefined,
  latestEventDamages: undefined,
  damageShapeColors: undefined,
  cmlShapeColors: undefined,
  latestEventCml: undefined,
  allMarkupsInit: false,
  activeMarkupId: undefined,
  fileUploadCounter: 0,
  drawingSrc: undefined,
  currentDrawingSrc: undefined,
  currentBgDimension: undefined,
  idwgGrids: undefined,
  damageInactiveShown: false,
  cmlInactiveShown: false,
  fileDrawing: undefined,
  nativeUploadFile: undefined,
};

export const MAX_LENGTH_VALIDATORS = {
  DOCNAME: 100,
  DESCRIPTION: 255,
  IDWG: 45,
  CML_POSITION: 10,
  CML_DIAMETER: 5,
  CML_DESCRIPTION: 50,
  CML_LONG_DESCRIPTION: 500,
  CML_EXTERNAL_REF: 45,
  DMG_DESCRIPTION: 50,
  DMG_LONG_DESCRIPTION: 255,
};

type TZoomViewerState = {
  zoomPoint?: fabric.Point;
} & ReactZoomPanPinchState;

type Actions = {
  setCanvasInfos: (canvas: fabric.Canvas | null, initialCanvasWidth: number, initialCanvasHeight: number) => void;
  setInspectionDrawings: (inspectionDrawings?: Partial<InspectionDrawing>[]) => void;
  setInspectionDrawingViews: (inspectionDrawingViews?: Partial<InspectionDrawingView>[]) => void;
  setActiveInspectionDrawing: (activeInspectionDrawing?: Partial<InspectionDrawing> | null, isNew?: boolean) => void;
  updateInspectionDrawing: (inspectionDrawing: Partial<InspectionDrawing>, isNew?: boolean) => void;
  updateCml: (cml: Partial<Cml>, isNew?: boolean) => void;
  updateDamage: (damage: Partial<Damage>, isNew?: boolean) => void;
  deleteInspectionDrawing: (inspectionDrawings?: Partial<InspectionDrawing>) => void;
  deleteCml: (cml?: Partial<Cml>) => void;
  deleteDamage: (damage?: Partial<Damage>) => void;
  deleteIdwgFloc: (flocToDelete?: Partial<FunctionalLocation>, idwg?: Partial<InspectionDrawing>) => void;
  deleteIdwgGrid: (gridToDelete?: Partial<Grid>, idwg?: Partial<InspectionDrawing>) => void;
  changeDeleteInspectionDrawingModalDisplay: (isOpen: boolean) => void;
  changeDeleteCmlModalDisplay: (isOpen: boolean) => void;
  changeDeleteDamageModalDisplay: (isOpen: boolean) => void;
  setFlocToDelete: (flocToDelete?: Partial<FunctionalLocation>) => void;
  setFlocs: (flocs?: Partial<FunctionalLocation>[]) => void;
  setIdwgGrids: (idwgGrids?: Partial<IdwgGrid>[]) => void;
  setFlocsSelectionDisplayed: (displayed: boolean) => void;
  setGridsSelectionDisplay: (displayed: boolean) => void;
  setGridToDelete: (gridToDelete?: Partial<Grid>) => void;
  resetData: () => void;
  setBgImg: (bgImg: HTMLImageElement|HTMLCanvasElement) => void;
  setUpdateData: (updateData?: InspectionDrawingUncheckedUpdateInput | InspectionDrawingUncheckedCreateInput) => void;
  setUpdateCmlData: (updateCmlData: CmlUncheckedUpdateInput | CmlUncheckedCreateInput) => void;
  setUpdateDamageData: (updateDamageData: DamageUncheckedUpdateInput | DamageUncheckedCreateInput) => void;
  setEditIdwg: (idwg: Partial<InspectionDrawing> | null) => void;
  isSaved: () => boolean;
  hasError: ()=>boolean;
  hasFieldError: (field: string, forceCheck?: boolean) => boolean;
  setEditCml: (cml?: Partial<Cml> | null) => void;
  setEditDamage: (damage?: Partial<Damage> | null) => void;
  addCmlMarkup: (cmls: Partial<Cml>, markup: TMarkupObjects) => boolean;
  changeMarkupVisible: (id: string, visible: boolean) => void;
  addDamageMarkup: (damage: Partial<Damage>, markup: TMarkupObjects) => boolean;
  clearAllMarkups: () => boolean;
  hideAllMarkups: () => boolean;
  setViewerState: (viewerState?: Partial<TZoomViewerState>) => void;
  setScale: (scale: number) => void;
  setSelectedTab: (selectedTab: number) => void;
  cancelEditData: () => void;
  changeModalIdwgDetailShown: (modalIdwgDetailShown: boolean) => void;
  updateDataField: (field: string, value: unknown) => void;
  updateCmlDataField: (field: string, value: unknown) => void;
  updateDamageDataField: (field: string, value: unknown) => void;
  setFieldErrors: (fieldErrors?: Record<string, boolean>) => void;
  setUploadFile: (uploadFile?: File) => void;
  updateFieldError: (field: string, value: boolean) => void;
  setActiveCml: (activeCml?: Partial<Cml>) => void;
  setBgInfos: (bgImg?: HTMLCanvasElement | HTMLImageElement | null, bgDimension?: TDimension, isCurrentBg?: boolean) => void;
  setActiveDamage: (activeDamage?: Partial<Damage>) => void;
  getLatestEventCmlByCmlId: (cmlId: number) => Partial<EventCml> | undefined | null;
  addLatestEventCml: (cmlId: number, eventCml: Partial<EventCml> | null) => void;
  getLatestEventDamageByDmgId: (dmgId: number) => Partial<EventDamage> | undefined | null;
  addLatestEventDamage: (dmgId: number, eventDamage: Partial<EventDamage> | null) => void;
  getCmlShapeColor: (cmlId: number) => string | undefined | null;
  addCmlShapeColor: (cmlId: number, color: string | null) => void;
  getDamageShapeColor: (dmgId: number) => string | undefined | null;
  addDamageShapeColor: (dmgId: number, color: string | null) => void;
  setActiveMarkupId: (activeMarkupId?: string) => void;
  createNewMarkup: (position: fabric.Point) => void;
  setDrawingSrc: (drawingSrc?: TPDFFile, isCurrent?: boolean) => void;
  changeDamageInactiveShown: (damageInactiveShown: boolean) => void;
  changeCmlInactiveShown: (cmlInactiveShown: boolean) => void;
  setFetchDamages: (fetchDamages?: () => void) => void;
  setFetchCmls: (fetchCmls?: () => void) => void;
  setFetchInspectionDrawings: (fetchInspectionDrawings?: () => void) => void;
  setFileDrawing: (fileDrawing?: Partial<Drawing> | null) => void;
  setNativeUploadFile: (nativeUploadFile?: File) => void;
}

const getIdwgUpdateState = (state: Partial<State>, inspectionDrawingChanges: Partial<InspectionDrawing>) : Partial<State> => {
  const newActiveInspectionDrawing: Partial<InspectionDrawing> = {
    ...state.activeInspectionDrawing,
    ...inspectionDrawingChanges,
  };
  const inspectionDrawings: Partial<InspectionDrawing>[] = [
    ...(state.inspectionDrawings ?? []),
  ];
  const newState: Partial<State> = {};
  if (inspectionDrawings) {
    const index = inspectionDrawings.findIndex((item: Partial<InspectionDrawing>) => item.id === newActiveInspectionDrawing.id);
    if (index > -1) {
      inspectionDrawings[index] = newActiveInspectionDrawing;
      newState.inspectionDrawings = inspectionDrawings;
      newState.activeInspectionDrawing = newActiveInspectionDrawing;
      newState.editIdwg = {
        ...newState.activeInspectionDrawing,
      };
    }
  }

  return newState;
};

export const useInspectionDrawingStore = create<State & Actions>()(
  immer((set, get) => ({
    ...initialState,
    setNativeUploadFile(nativeUploadFile) {
      set({nativeUploadFile});
    },
    setFileDrawing(fileDrawing) {
      set({fileDrawing});
    },
    setFetchInspectionDrawings(fetchInspectionDrawings) {
      set({fetchInspectionDrawings});
    },
    setDrawingSrc(drawingSrc?: TPDFFile, isCurrent?: boolean) {
      const newState: Partial<State> = {drawingSrc};
      if (isCurrent === true) {
        newState.currentDrawingSrc = drawingSrc;
      }

      set(newState);
    },
    changeDamageInactiveShown(damageInactiveShown) {
      set({damageInactiveShown});
    },
    changeCmlInactiveShown(cmlInactiveShown) {
      set({cmlInactiveShown});
    },
    createNewMarkup: (position: fabric.Point) => set(state => {
      const newState: Partial<State> = {};
      const {selectedTab} = state;
      if (selectedTab === TAB_CMLS) {
        const coordinates: string = JSON.stringify({x: position.x, y: position.y});
        const cml: Partial<Cml> = {
          id: OBJ_NEW_ID,
          coordinates2d: coordinates,
          markerCoordinates2d: coordinates,
          idwgId: state.activeInspectionDrawing!.id!,
          inspectionDrawing: {...state.activeInspectionDrawing!} as InspectionDrawing,
        };
        newState.updateCmlData = {
          coordinates2d: coordinates,
          markerCoordinates2d: coordinates,
          idwgId: state.activeInspectionDrawing!.id!,
        } as CmlUncheckedCreateInput;
        newState.activeCml = cml;
        newState.editCml = {...cml};
        newState.activeInspectionDrawing = {
          ...state.activeInspectionDrawing,
          cmls: [
            cml as Cml,
            ...(state.activeInspectionDrawing?.cmls ?? []),
          ],
        };
      } else if (selectedTab === TAB_DAMAGES) {
        const coordinates: string = JSON.stringify({x: position.x, y: position.y});
        const dmg: Partial<Damage> = {
          id: OBJ_NEW_ID,
          coordinates2d: coordinates,
          markerCoordinates2d: coordinates,
          idwgId: state.activeInspectionDrawing!.id!,
          inspectionDrawing: {...state.activeInspectionDrawing!} as InspectionDrawing,
        };
        newState.updateDamageData = {
          coordinates2d: coordinates,
          markerCoordinates2d: coordinates,
          idwgId: state.activeInspectionDrawing!.id!,
        } as DamageUncheckedCreateInput;
        newState.activeDamage = dmg;
        newState.editDamage = {...dmg};
        newState.activeInspectionDrawing = {
          ...state.activeInspectionDrawing,
          damages: [
            dmg as Damage,
            ...(state.activeInspectionDrawing?.damages ?? []),
          ],
        };
      }

      return newState;
    }),
    setActiveMarkupId(activeMarkupId) {
      set({activeMarkupId});
    },
    getCmlShapeColor: (cmlId: number) => get().cmlShapeColors?.[cmlId],
    addCmlShapeColor: (cmlId: number, color: string | null) => set(state => (
      {
        cmlShapeColors: {
          ...state.cmlShapeColors,
          [cmlId]: color,
        },
      }
    )),
    getDamageShapeColor: (dmgId: number) => get().damageShapeColors?.[dmgId],
    addDamageShapeColor: (dmgId: number, color: string | null) => set(state => (
      {
        damageShapeColors: {
          ...state.damageShapeColors,
          [dmgId]: color,
        },
      }
    )),
    addLatestEventCml: (cmlId: number, eventCml: Partial<EventCml> | null) => set(state => ({
      latestEventCml: {
        ...state.latestEventCml,
        [cmlId]: eventCml,
      },
    })),
    addLatestEventDamage: (dmgId: number, eventDamage: Partial<EventDamage> | null) => set(state => ({
      latestEventDamages: {
        ...state.latestEventDamages,
        [dmgId]: eventDamage,
      },
    })),
    getLatestEventCmlByCmlId: (cmlId: number) => get().latestEventCml?.[cmlId],
    getLatestEventDamageByDmgId: (dmgId: number) => get().latestEventDamages?.[dmgId],
    setActiveCml: activeCml => set(state => {
      const newState: Partial<State> = {
        activeCml,
        editCml: activeCml ? {...activeCml} : undefined,
        updateCmlData: undefined,
        cmlFieldErrors: undefined,
      };
      if (!activeCml) {
        if (state.activeCml?.id === OBJ_NEW_ID) {
          newState.activeInspectionDrawing = {
            ...state.activeInspectionDrawing,
            cmls: state.activeInspectionDrawing?.cmls?.filter((item: Partial<Cml>) => item.id !== OBJ_NEW_ID),
          };
        }
      }

      return newState;
    }),
    setActiveDamage: activeDamage => set(state => {
      const newState: Partial<State> = {
        activeDamage,
        editDamage: activeDamage ? {...activeDamage} : undefined,
        updateDamageData: undefined,
        damageFieldErrors: undefined,
      };
      if (!activeDamage) {
        if (state.activeDamage?.id === OBJ_NEW_ID) {
          newState.activeInspectionDrawing = {
            ...state.activeInspectionDrawing,
            damages: state.activeInspectionDrawing?.damages?.filter((item: Partial<Damage>) => item.id !== OBJ_NEW_ID),
          };
        }
      }

      return newState;
    }),
    updateFieldError: (field: string, value: boolean) => set(state => {
      state.fieldErrors = {
        ...state.fieldErrors,
        [field]: value,
      };
    }),
    setUploadFile: (uploadFile?: File) => set({uploadFile, fileUploadCounter: get().fileUploadCounter + 1, drawingSrc: uploadFile ?? get().currentDrawingSrc}),
    changeMarkupVisible(id: string, visible: boolean) {
      const state = get();
      const markup: TMarkupObjects | undefined = state.allMarkups?.[id];
      if (markup) {
        for (const [_key, obj] of Object.entries(markup)) {
          obj.visible = visible;
        }
      }
    },
    setFieldErrors(fieldErrors?: Record<string, boolean>) {
      set({
        fieldErrors: fieldErrors ?? {},
      });
    },
    updateDataField: (field: string, value: unknown) => set(state => ({
      updateData: {
        ...state.updateData,
        [field]: state.activeInspectionDrawing?.id === OBJ_NEW_ID ? value : {
          set: value,
        },
      },
      fieldErrors: {
        ...state.fieldErrors,
        [field]: false,
      },
    })),
    updateCmlDataField: (field: string, value: unknown) => set(state => {
      switch (field) {
        case 'nominal':
        case 'alarm1':
        case 'alarm2':
        case 'alarm3':
          value = String(value).trim();
          if (value === '') {
            value = null;
          } else {
            value = (value as string).replace(/,/g, '.');
            value = Number.parseFloat(value as string);
            if (Number.isNaN(value)) {
              value = null;
            } else {
              value = String(value);
            }
          }

          break;
        default:
          break;
      }

      return {
        updateCmlData: {
          ...state.updateCmlData,
          [field]: state.activeCml?.id === OBJ_NEW_ID ? value : {
            set: value,
          },
        },
        cmlFieldErrors: {
          ...state.cmlFieldErrors,
          [field]: false,
        },
      };
    }),
    updateDamageDataField: (field: string, value: unknown) => set(state => ({
      updateDamageData: {
        ...state.updateDamageData,
        [field]: state.activeDamage?.id === OBJ_NEW_ID ? value : {
          set: value,
        },
      },
      damageFieldErrors: {
        ...state.damageFieldErrors,
        [field]: false,
      },
    })),
    changeModalIdwgDetailShown: (modalIdwgDetailShown: boolean) => set({modalIdwgDetailShown}),
    cancelEditData: () => set(state => {
      if (state.activeCml) {
        const newState: Partial<State> = {
          updateCmlData: {},
          cmlFieldErrors: {},
          editCml: {...state.activeCml},
        };
        if (state.activeCml.id === OBJ_NEW_ID) {
          newState.activeCml = undefined;
          newState.editCml = undefined;
          Object.assign(newState, getIdwgUpdateState(get(), {
            cmls: state.activeInspectionDrawing?.cmls?.filter((item: Partial<Cml>) => item.id !== OBJ_NEW_ID),
          }));
        }

        return newState;
      }

      if (state.activeDamage) {
        const newState: Partial<State> = {
          updateDamageData: {},
          damageFieldErrors: {},
          editDamage: {...state.activeDamage},
        };
        if (state.activeDamage.id === OBJ_NEW_ID) {
          newState.activeDamage = undefined;
          newState.editDamage = undefined;
          Object.assign(newState, getIdwgUpdateState(get(), {
            damages: state.activeInspectionDrawing?.damages?.filter((item: Partial<Damage>) => item.id !== OBJ_NEW_ID),
          }));
        }

        return newState;
      }

      return {
        bgImg: state.currentBgImg,
        bgDimension: state.currentBgDimension,
        updateData: {},
        drawingSrc: state.currentDrawingSrc,
        fileUploadCounter: 0,
        uploadFile: undefined,
        fieldErrors: {},
        editIdwg: {...state.activeInspectionDrawing},
      };
    }),
    setSelectedTab(selectedTab: number) {
      set({selectedTab});
    },
    setScale(scale: number) {
      set({scale});
    },
    setViewerState(viewerState) {
      set({viewerState: {
        ...get().viewerState,
        ...viewerState,
      } as TZoomViewerState});
    },
    clearAllMarkups() {
      const {canvas} = get();
      canvas?.getObjects().forEach((o:fabric.FabricObject) => {
        canvas.remove(o);
      });
      set({
        allMarkups: {},
      });
      return true;
    },
    hideAllMarkups() {
      const {allMarkups} = get();
      Object.entries(allMarkups ?? {}).forEach(([_key, value]) => {
        Object.entries(value).forEach(([_keyObj, obj]) => {
          obj.visible = false;
        });
      });
      return true;
    },
    // Add Cml markup
    addCmlMarkup(cml: Partial<Cml>, markup: TMarkupObjects) {
      const state = get();
      const {canvas} = state;
      const allMarkups: Record<string, TMarkupObjects> = {...state.allMarkups};
      if (canvas) {
        set({
          allMarkups: {
            ...allMarkups,
            [`${CML_PREFIX}${cml.id!}`]: markup,
          },
        });
        canvas.add(...Object.values(markup));
        return true;
      }

      return false;
    },
    addDamageMarkup(damage: Partial<Damage>, markup: TMarkupObjects) {
      const state = get();
      const {canvas} = state;
      const allMarkups: Record<string, TMarkupObjects> = {...state.allMarkups};
      if (canvas) {
        set({allMarkups: {
          ...allMarkups,
          [`${DAMAGE_PREFIX}${damage.id!}`]: markup,
        }});
        canvas.add(...Object.values(markup));
        return true;
      }

      return false;
    },
    resetData() {
      get().canvas?.clear();
      set({
        ...initialState,
      });
    },
    setUpdateData(updateData?: InspectionDrawingUncheckedUpdateInput | InspectionDrawingUncheckedCreateInput) {
      set({updateData});
    },
    setUpdateCmlData(updateCmlData: CmlUncheckedUpdateInput | CmlUncheckedCreateInput) {
      set({updateCmlData});
    },
    setUpdateDamageData(updateDamageData: DamageUncheckedUpdateInput | DamageUncheckedCreateInput) {
      set({updateDamageData});
    },
    setEditIdwg(editIdwg) {
      set({editIdwg});
    },
    setEditCml(editCml) {
      set({editCml});
    },
    setEditDamage(editDamage) {
      set({editDamage});
    },
    isSaved() {
      const state = get();
      if (state.activeCml) {
        return !(state.updateCmlData && Object.keys(state.updateCmlData).length > 0);
      }

      if (state.activeDamage) {
        return !(state.updateDamageData && Object.keys(state.updateDamageData).length > 0);
      }

      return !(state.updateData && Object.keys(state.updateData).length > 0);
    },
    // eslint-disable-next-line complexity
    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get();
      if (state.activeCml) {
        if (!Object.keys(state.updateCmlData ?? {}).includes(field) && !forceCheck) {
          return false;
        }

        switch (field) {
          case 'description':
            const description = (state.editCml?.description ?? '').trim();
            return description.length > MAX_LENGTH_VALIDATORS.CML_DESCRIPTION || description === '';
          case 'longDescription':
            const longDescription = (state.editCml?.description ?? '').trim();
            return longDescription.length > MAX_LENGTH_VALIDATORS.CML_LONG_DESCRIPTION;
          case 'position':
            const position = (state.editCml?.position ?? '').trim();
            return position.length > MAX_LENGTH_VALIDATORS.CML_POSITION || position === '';

          case 'codeGroupId':
            return !state.editCml?.codeGroupId;
          case 'nominal':
          case 'alarm1':
          case 'alarm2':
          case 'alarm3':
            const value = state.editCml?.[field]?.trim() ?? '';
            return value !== '' && Number.isNaN(Number.parseFloat(value));
          default:
            break;
        }
      } else if (state.activeDamage) {
        if (!Object.keys(state.updateDamageData ?? {}).includes(field) && !forceCheck) {
          return false;
        }

        switch (field) {
          case 'description':
            const description = (state.editDamage?.description ?? '').trim();
            return description.length > MAX_LENGTH_VALIDATORS.DMG_DESCRIPTION || description === '';
          case 'longDescription':
            const longDescription = (state.editDamage?.longDescription ?? '').trim();
            return longDescription.length > MAX_LENGTH_VALIDATORS.DMG_LONG_DESCRIPTION;
          case 'codeGroupId':
            return !state.editDamage?.codeGroupId;
          default:
            break;
        }
      } else {
        if (!Object.keys(state.updateData ?? {}).includes(field) && !forceCheck) {
          return false;
        }

        switch (field) {
          case 'idwg':
            const idwg = (state.editIdwg?.idwg ?? '').trim();
            return idwg === '' || idwg.length > MAX_LENGTH_VALIDATORS.IDWG;
          case 'description':
            const description = (state.editIdwg?.description ?? '').trim();
            return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '';
          case 'sectorId':
            return !state.editIdwg?.sectorId;
          default:
            break;
        }
      }

      return false;
    },
    hasError() {
      const state = get();
      if (state.activeCml) {
        return ['description', 'position', 'longDescription', 'classId', 'codeGroupId', 'nominal', 'technique', 'alarm1', 'alarm2', 'alarm3', 'technique'].some((field: string) => state.hasFieldError(field, true));
      }

      if (state.activeDamage) {
        return ['description', 'codeGroupId', 'longDescription'].some((field: string) => state.hasFieldError(field, true));
      }

      return ['description', 'idwg', 'sectorId'].some((field: string) => state.hasFieldError(field, true));
    },
    setBgImg(bgImg: HTMLImageElement | HTMLCanvasElement) {
      set({bgImg});
    },
    setActiveInspectionDrawing: (inspectionDrawing?: Partial<InspectionDrawing> | null, isNew?: boolean) => set(state => {
      state.activeInspectionDrawing = inspectionDrawing;
      state.editIdwg = inspectionDrawing ? {...inspectionDrawing} : undefined;
      state.creatingIdwg = (inspectionDrawing && isNew) === true;
      state.updateData = undefined;

      state.uploadFile = undefined;
    }),
    setInspectionDrawings: (inspectionDrawings?: Partial<InspectionDrawing>[]) => set({
      inspectionDrawings,
    }),
    setInspectionDrawingViews: (inspectionDrawingViews?: Partial<InspectionDrawingView>[]) => set({
      inspectionDrawingViews,
    }),
    setBgInfos: (bgImg?: HTMLImageElement | HTMLCanvasElement | null, bgDimension?: TDimension, isCurrentBg?: boolean) => set(_state => {
      const newState: Partial<State> = {
        bgImg,
        bgDimension,
      };
      if (isCurrentBg) {
        newState.currentBgImg = bgImg;
        newState.currentBgDimension = bgDimension;
      }

      return newState;
    }),
    setCanvasInfos(canvas: fabric.Canvas | null, initialCanvasWidth: number, initialCanvasHeight: number) {
      set({canvas, initialCanvasWidth, initialCanvasHeight});
    },
    updateInspectionDrawing: (inspectionDrawing: Partial<InspectionDrawing>, isNew?: boolean) => set(state => {
      const newState = getIdwgUpdateState(get(), inspectionDrawing);

      if (state.activeInspectionDrawing && state.activeInspectionDrawing.id === isNew ? OBJ_NEW_ID : inspectionDrawing.id) {
        newState.activeInspectionDrawing = inspectionDrawing;
      }

      return newState;
    }),
    updateCml: (cml: Partial<Cml>, isNew?: boolean) => set(state => {
      const {canvas, allMarkups} = get() ?? {};

      const cmls: Partial<Cml>[] = [
        ...(state.activeInspectionDrawing?.cmls ?? []),
      ];
      const cmlId: number = isNew ? OBJ_NEW_ID : cml.id!;
      const newState: Partial<State> = {};
      if (cmls) {
        const index = cmls.findIndex((item: Partial<Cml>) => item.id === cmlId);
        if (index > -1) {
          if (!!allMarkups && !!cml.codeGroup?.shape && cmls[index].codeGroup?.shape !== cml.codeGroup?.shape) {
            const currentMarkups = allMarkups[`${CML_PREFIX}${cml.id!}`];
            if (currentMarkups) {
              Object.values(currentMarkups).forEach((o: fabric.FabricObject) => {
                canvas?.remove(o);
              });
              canvas?.renderAll();
              delete allMarkups[`${CML_PREFIX}${cml.id!}`];
              newState.allMarkups = allMarkups as Record<string, TMarkupObjects>;
            }
          }

          cmls[index] = {...cml};
          if (state.activeCml && cmlId === state.activeCml.id!) {
            newState.activeCml = {
              ...state.activeCml,
              ...cml,
            };
            newState.editCml = {...newState.activeCml};
          }

          Object.assign(newState, getIdwgUpdateState(state as Partial<State>, {
            cmls: cmls as Cml[],
          }));
        }
      }

      return newState;
    }),
    updateDamage: (damage: Partial<Damage>, isNew?: boolean) => set(state => {
      const {canvas, allMarkups} = get();

      const damages: Partial<Damage>[] = [
        ...(state.activeInspectionDrawing?.damages ?? []),
      ];
      const dmgId: number = isNew ? OBJ_NEW_ID : damage.id!;
      const newState: Partial<State> = {};
      if (damages) {
        const index = damages.findIndex((item: Partial<Damage>) => item.id === dmgId);
        if (index > -1) {
          if (!!allMarkups && !!damage.codeGroup?.shape && damages[index].codeGroup?.shape !== damage.codeGroup?.shape) {
            const currentMarkups = allMarkups[`${CML_PREFIX}${damage.id!}`];
            if (currentMarkups) {
              Object.values(currentMarkups).forEach((o: fabric.FabricObject) => {
                canvas?.remove(o);
              });
              canvas?.renderAll();
              delete allMarkups[`${DAMAGE_PREFIX}${damage.id!}`];
              newState.allMarkups = allMarkups as Record<string, TMarkupObjects>;
            }
          }

          damages[index] = {...damage};
          if (state.activeDamage && dmgId === state.activeDamage.id!) {
            newState.activeDamage = {
              ...state.activeDamage,
              ...damage,
            };
            newState.editDamage = {
              ...newState.activeDamage,
            };
          }

          Object.assign(newState, getIdwgUpdateState(state as Partial<State>, {
            damages: damages as Damage[],
          }));
        }
      }

      return newState;
    }),
    deleteInspectionDrawing: (inspectionDrawing?: Partial<InspectionDrawing>) => set(state => {
      const inspectionDrawings: Partial<InspectionDrawing>[] = [
        ...(state.inspectionDrawings ?? []),
      ];
      const deletedInspectionDrawing: Partial<InspectionDrawing>|undefined|null = inspectionDrawing ?? state.activeInspectionDrawing;
      const newState: Partial<State> = {};
      if (inspectionDrawings.length > 0 && deletedInspectionDrawing) {
        newState.deleteInspectionDrawingModalOpen = false;
        if (deletedInspectionDrawing === state.activeInspectionDrawing) {
          newState.activeInspectionDrawing = null;
        }

        newState.inspectionDrawings = inspectionDrawings.filter((item: Partial<InspectionDrawing>) => item.id !== deletedInspectionDrawing!.id);
      }

      return newState;
    }),
    deleteCml: (cml?: Partial<Cml>) => set(state => {
      const {allMarkups, canvas} = get();
      let cmls: Partial<Cml>[] = [
        ...(state.activeInspectionDrawing?.cmls ?? []),
      ];
      const deletedCml: Partial<Cml>|undefined|null = cml ?? state.activeCml;
      const newState: Partial<State> = {};
      if (cmls.length > 0 && deletedCml) {
        newState.deleteCmlModalOpen = false;
        if (deletedCml === state.activeCml) {
          newState.activeCml = undefined;
        }

        cmls = cmls.filter((item: Partial<Cml>) => item.id !== deletedCml!.id);
        if (allMarkups) {
          const currentMarkups = allMarkups[`${CML_PREFIX}${deletedCml.id!}`];
          if (currentMarkups) {
            Object.values(currentMarkups).forEach((o: fabric.FabricObject) => {
              canvas?.remove(o);
            });
            canvas?.renderAll();
            delete allMarkups[`${CML_PREFIX}${deletedCml.id!}`];
            newState.allMarkups = allMarkups as Record<string, TMarkupObjects>;
          }
        }

        newState.allMarkups = allMarkups as Record<string, TMarkupObjects>;
        Object.assign(newState, getIdwgUpdateState(state as Partial<State>, {
          cmls: cmls as Cml[],
        }));
      }

      return newState;
    }),
    deleteDamage: (damage?: Partial<Damage>) => set(state => {
      const {allMarkups, canvas} = get();
      let damages: Partial<Damage>[] = [
        ...(state.activeInspectionDrawing?.damages ?? []),
      ];
      const deletedDamage: Partial<Damage>|undefined|null = damage ?? state.activeDamage;
      const newState: Partial<State> = {};
      if (damages.length > 0 && deletedDamage) {
        newState.deleteDamageModalOpen = false;
        if (deletedDamage === state.activeDamage) {
          newState.activeDamage = undefined;
        }

        if (allMarkups) {
          const currentMarkups = allMarkups[`${CML_PREFIX}${deletedDamage.id!}`];
          if (currentMarkups) {
            Object.values(currentMarkups).forEach((o: fabric.FabricObject) => {
              canvas?.remove(o);
            });
            canvas?.renderAll();
            delete allMarkups[`${DAMAGE_PREFIX}${deletedDamage.id!}`];
            newState.allMarkups = allMarkups as Record<string, TMarkupObjects>;
          }
        }

        damages = damages.filter((item: Partial<Damage>) => item.id !== deletedDamage!.id);
        Object.assign(newState, getIdwgUpdateState(state as Partial<State>, {
          damages: damages as Damage[],
        }));
      }

      return newState;
    }),
    changeDeleteInspectionDrawingModalDisplay: (isOpen: boolean) => set({
      deleteInspectionDrawingModalOpen: isOpen,
    }),
    changeDeleteCmlModalDisplay: (isOpen: boolean) => set({
      deleteCmlModalOpen: isOpen,
    }),
    changeDeleteDamageModalDisplay: (isOpen: boolean) => set({
      deleteDamageModalOpen: isOpen,
    }),
    setFlocToDelete: (flocToDelete?: Partial<FunctionalLocation>) => set({
      flocToDelete,
    }),
    setFlocs(flocs) {
      set({flocs});
    },
    setIdwgGrids(idwgGrids) {
      set({idwgGrids});
    },
    setFlocsSelectionDisplayed: (displayed: boolean) => set({
      flocsSelectionDisplayed: displayed,
    }),
    setGridsSelectionDisplay: (displayed: boolean) => set({
      gridsSelectionDisplayed: displayed,
    }),
    setGridToDelete: (gridToDelete?: Partial<Grid>) => set({
      gridToDelete,
    }),
    setFetchDamages(fetchDamages) {
      set({fetchDamages});
    },
    setFetchCmls(fetchCmls) {
      set({fetchCmls});
    },
    deleteIdwgFloc: (flocToDelete?: Partial<FunctionalLocation>, idwg?: Partial<InspectionDrawing>) => set(state => {
      const inspectionDrawing: Partial<InspectionDrawing>|undefined|null = idwg ?? state.activeInspectionDrawing;
      const deletedFloc: Partial<FunctionalLocation> | undefined = {...(flocToDelete ?? state.flocToDelete)};
      const newState: Partial<State> = {};
      if (inspectionDrawing && deletedFloc) {
        if (deletedFloc.id === state.flocToDelete?.id) {
          newState.flocToDelete = undefined;
        }

        const idwgFlocs = inspectionDrawing.idwgFlocs?.filter((item: IdwgFloc) => item.functionalLocation?.id !== deletedFloc.id!);
        if (inspectionDrawing.id === state.activeInspectionDrawing?.id) {
          Object.assign(newState, getIdwgUpdateState(get(), {
            idwgFlocs,
          }));
        } else {
          inspectionDrawing.idwgFlocs = idwgFlocs;
        }
      }

      return newState;
    }),
    deleteIdwgGrid: (gridToDelete?: Partial<Grid>, idwg?: Partial<InspectionDrawing>) => set(state => {
      const inspectionDrawing: Partial<InspectionDrawing>|undefined|null = idwg ?? state.activeInspectionDrawing;
      const deletedGrid: Partial<Grid> | undefined = gridToDelete ?? state.gridToDelete;
      const newState: Partial<State> = {};
      if (inspectionDrawing && deletedGrid) {
        if (deletedGrid === state.gridToDelete) {
          newState.gridToDelete = undefined;
        }

        const idwgGrids = inspectionDrawing.idwgGrids?.filter((item: IdwgGrid) => item.grid?.id !== deletedGrid.id!);
        if (inspectionDrawing.id === state.activeInspectionDrawing?.id) {
          Object.assign(newState, getIdwgUpdateState(get(), {
            idwgGrids,
          }));
        } else {
          inspectionDrawing.idwgGrids = idwgGrids;
        }
      }

      return newState;
    }),
  })),
);
