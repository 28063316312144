import {gql} from '@apollo/client/core';
import {DOCUMENTATION_DOCUMENT_VIEWS_COMMON_FIELDS, DOCUMENTATION_DOCUMENTS_COMMON_FIELDS, DOCUMENTATION_DOCUMENTS_COUNTER_FIELDS} from '../fragments';

export const DOCUMENTATION_DOCUMENT_VIEWS_GET_ALL = gql`
${DOCUMENTATION_DOCUMENT_VIEWS_COMMON_FIELDS}
query GetAllDocumentViews($sites: [String!], $orderBy: [DocumentViewOrderByWithRelationInput!]) {
  documentViews(where: {sector_site_site:  {
     in: $sites
  }}, orderBy: $orderBy) {
    ...DocumentViewCommonFields
  }
}`;

export const DOCUMENTATION_DOCUMENTS_GET_DETAIL_BY_NUMBER = gql`
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
${DOCUMENTATION_DOCUMENTS_COUNTER_FIELDS}
query getDocumentDetailByNumber($number: String!) {
  document(where: {document: $number}) {
    ...DocumentCommonFields
    ...DocumentCounterFields
  }
}`;

export const DOCUMENTATION_DOCUMENTS_CREATE = gql`
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
mutation CreateDocument($data: DocumentUncheckedCreateInput!) {
  createOneDocument(data: $data) {
    ...DocumentCommonFields
  }
}`;

export const DOCUMENTATION_DOCUMENTS_GET_BY_FLOC_IDS = gql`
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
query GetDocumentsByFlocIds($flocIds: [Int!], $orderBy: [DocumentOrderByWithRelationInput!]) {
    documents(where: {flocDocs: {some: {flocId: {in: $flocIds}}}}, orderBy: $orderBy){
        ...DocumentCommonFields
    }
}`;

export const DOCUMENTATION_DOCUMENTS_GET_COUNTER = gql`
query GetDocumentsCounter {
  aggregateDocument {
    _count {
      id
    }
  }
}
`;

export const DOCUMENTATION_DOCUMENTS_UPDATE_BY_ID = gql`
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
mutation UpdateDocumentById($id: Int!, $data: DocumentUncheckedUpdateInput!) {
  updateOneDocument(data: $data, where: {id: $id}) {
    ...DocumentCommonFields
  }
}`;

export const DOCUMENTATION_DOCUMENTS_DELETE_BY_ID = gql`
mutation DeleteDocumentById($id: Int!) {
  deleteOneDocument(where: {id: $id}) {
    id
  }
}`;
