import {CreatePlanFlocsMutation, Plan, PlanFloc, GetPlanFlocsByPlanIdQuery, FunctionalLocation, DeleteOnePlanFlocByIdMutation} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import FlocItems from './FlocList';
import {useLayoutStore} from '@app/stores/layout';
import {useLazyQuery, useMutation} from '@apollo/client';
import {SCHEDULING_PLAN_FLOCS_CREATE_MANY, SCHEDULING_PLAN_FLOCS_DELETE_BY_FLOC_IDS_AND_PLAN_ID, SCHEDULING_PLAN_FLOCS_GET_BY_PLAN_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useEffect} from 'react';
import DeletePlanFlocModal from './DeletePlanFlocModal';
import usePlanStore from '@app/stores/plan';
import {TDbId} from '@app/types/app';

type TFlocBlock = Readonly<{
  plan: Partial<Plan>;
  filterString?: string;
}>;

export default function FlocBlock({plan, filterString} : TFlocBlock) {
  const {flocToDelete, setFlocToDelete, flocs, setFlocs} = usePlanStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [addPlanFlocsApi] = useMutation<CreatePlanFlocsMutation>(SCHEDULING_PLAN_FLOCS_CREATE_MANY);
  const [deletePlanFlocsApi] = useMutation<DeleteOnePlanFlocByIdMutation>(SCHEDULING_PLAN_FLOCS_DELETE_BY_FLOC_IDS_AND_PLAN_ID);
  const [getPlanFlocsByPlanId] = useLazyQuery<GetPlanFlocsByPlanIdQuery>(SCHEDULING_PLAN_FLOCS_GET_BY_PLAN_ID);
  const {t} = useTranslation();

  const refreshPlanFlocsList = () => {
    getPlanFlocsByPlanId({
      variables: {
        planId: plan.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(queryResults => {
      setFlocs((queryResults.data?.planFlocs ?? undefined) as PlanFloc[] | undefined);
    }).catch(_err => {
      AppNotifications.error(t('message.error.default.title'));
    });
  };

  const addItems = async (flocIds: TDbId[]) => {
    await addPlanFlocsApi({
      variables: {
        data: flocIds.map((flocId: TDbId) => ({
          planId: plan.id,
          flocId,
        })),
      },
    });
  };

  const deleteItems = async (flocIds: TDbId[]) => {
    await deletePlanFlocsApi({
      variables: {
        flocIds,
        planId: plan.id!,
      },
    });
  };

  // Validate seletion modal (Add or delete floc )
  const onValidateSelection = async (selectedItems: Partial<FunctionalLocation>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[], setFlocsSelectionDisplayed?: (displayed: boolean) => void) => {
    const promises = [];
    if (oldSelectedIds.length) {
      const planFlocIds = (flocs?.filter((item: Partial<PlanFloc>) => oldSelectedIds.includes(item.flocId!)).map((item: Partial<PlanFloc>) => item.flocId) ?? []) as TDbId[];
      if (planFlocIds.length) {
        promises.push(deleteItems(planFlocIds));
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds));
    }

    if (promises.length) {
      startLoading();
      try {
        for (const asyncCall of promises) {
          await asyncCall;
        }

        refreshPlanFlocsList();
        AppNotifications.success(t('message.success.planFlocUpdated'));
        setFlocsSelectionDisplayed?.(false);
      } catch (err) {
        AppNotifications.error(t('message.error.default.title'));
      }

      stopLoading();
    }
  };

  useEffect(() => {
    refreshPlanFlocsList();
  }, [plan]);

  return (
    <div className='flex flex-col'>
      <div className='pl-0'>
        <FlocItems
          hasActionButtons
          refreshList={refreshPlanFlocsList}
          plan={plan}
          flocs={flocs}
          isDeleteButtonDisabled={!!flocToDelete}
          filterString={filterString}
          onValidateSelection={onValidateSelection}
          onDeleteClick={setFlocToDelete}
        />
      </div>
      <DeletePlanFlocModal open={!!flocToDelete} onDeleted={refreshPlanFlocsList}/>
    </div>
  );
}
