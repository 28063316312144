import React, {useState} from 'react';
import {SchedulingBadge} from '..';
import EventListPage from '@app/components/Common/Block/Event/EventListPage';
import {FORMAT_DATE_DEFAULT, ROUTE_SCHEDULING_INSPECTION_HISTORY} from '@app/utils/constants';
import {EDownloadFileType, EWORKORDER_EVENT_STAGE} from '@app/utils/enums';
import {useTranslation} from 'react-i18next';
import {EventView} from '@app/graphql/__types__/graphql';
import {RadButton, RadBadge} from '@holis/react-ui/rad';
import moment from 'moment';
import {MdOpenInNew} from 'react-icons/md';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';
import {OptimusClientConfig} from '@app/utils/clientConfig';
import {addTokenToUrl, downloadFileFromUrl, generateAbsoluteUrlWithBaseUrl, tailwindColorToBgFgStyle} from '@app/utils/functions';
import {getDocNameTypeLabel, getFileHeader, getGenerationUrlTypeLabel, ItemType} from '@app/utils/hooks/useReportGeneration';
import {useHolisAuth} from '@holis/auth-client-react';
import UsersToSendReportModal from './components/UsersToSendReportModal';
import axios from 'axios';
import {TObjId} from '@app/types/app';
import {useNavigate} from 'react-router-dom';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {GridNode, GridProps} from '@holis/react-ui';

export default function InspectionHistory() {
  const {t} = useTranslation();
  const prms = useUserPermissions();
  const navigate = useNavigate();
  const [eventToPublish, setEventToPublish] = useState<EventView>();
  const {startLoading, stopLoading} = useLayoutStore();
  const {getAccessToken} = useHolisAuth();
  const downloadReport = async (row: EventView) => {
    startLoading();
    const reportUrl = await addTokenToUrl(`${OptimusClientConfig.current.fileBaseUrl}/${getDocNameTypeLabel(ItemType.event)}-${row.event}?type=${EDownloadFileType.REPORT}`, getAccessToken);

    downloadFileFromUrl(reportUrl, `${getFileHeader(ItemType.event)}${row.reportNumber}.pdf`, {
      onEnd() {
        stopLoading();
      },
      onFail(err) {
        console.log(err);
        stopLoading();
        AppNotifications.error(t('message.error.default.title'));
      },
      getAccessToken,
    });
  };

  const handleSendReport = async (selectedItems: TObjId[]) => {
    const token = await getAccessToken();
    const sendReportUrl = `${OptimusClientConfig.current.reportBaseUrl}/${getGenerationUrlTypeLabel(ItemType.event)}/${eventToPublish!.id}/send`;
    const reportUrl = generateAbsoluteUrlWithBaseUrl(ROUTE_SCHEDULING_INSPECTION_HISTORY);
    console.log(reportUrl);
    startLoading();
    axios.post(sendReportUrl, {
      userIds: selectedItems.map(objId => objId.id as string),
      link: reportUrl,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(_res => {
      AppNotifications.success(t('message.success.report.sent'));
      setEventToPublish(undefined);
      navigate(0);
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(stopLoading);
  };

  const renderActionCell = (val: string, node: GridNode<Partial<EventView>>) => {
    const viewDatas = node.data as EventView;
    return (
      <div className='!flex gap-4 items-center'>
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => downloadReport(viewDatas)}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      </div>
    );
  };

  const gridProps: Partial<GridProps<Partial<EventView>>> = {
    columns: [
      {
        field: undefined,
        title: t('label.report'),
        defaultState: {width: 77.5},
        cellRenderer: renderActionCell,
        filter: false,
      },
      {
        field: 'isPublished',
        title: t('label.publish'),
        type: 'boolean',
        defaultState: {width: 120},
        cellRenderer: 'isPublished',
        filter: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'event',
        title: t('label.event'),
        type: 'string',
        defaultState: {
          width: 129,
        },
        cellRenderer: 'textBold',
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {width: 289},
      },
      {
        field: 'schedulingTag_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        defaultState: {width: 294},
      },
      {
        field: 'schedulingTag_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        defaultState: {width: 445},
      },
      {
        field: 'schedulingTag_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'string',
        defaultState: {width: 120},
      },
      {
        field: 'schedulingTag_techClass_class_class',
        title: t('label.class'),
        type: 'string',
        defaultState: {width: 77.5},
      },
      {
        field: 'schedulingTag_classSece',
        title: t('label.classSece'),
        type: 'string',
        defaultState: {width: 100},
      },
      {
        field: 'schedulingTag_sector_site_site',
        title: t('label.site'),
        type: 'string',
        defaultState: {width: 77.5},
      },
      {
        field: 'schedulingTag_sector_sector',
        title: t('label.sector'),
        type: 'string',
        defaultState: {width: 77.5},
      },
      {
        field: 'grid_grid',
        title: t('label.grid'),
        type: 'string',
        defaultState: {
          width: 77.5,
          hidden: true,
        },
      },
      {
        field: 'notes',
        title: t('label.notes'),
        type: 'string',
        defaultState: {
          width: 120,
          hidden: true,
        },
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'string',
        defaultState: {
          width: 77.5,
          hidden: false,
        },
      },
      {
        field: 'inspectionDate',
        title: t('label.inspectionDate'),
        type: 'string',
        cellRenderer: 'date',
        defaultState: {width: 137},
      },
      {
        field: 'inspectorId',
        title: t('label.inspectorId'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'reviewerId',
        title: t('label.reviewerId'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'approverId',
        title: t('label.approverId'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'reportNumber',
        title: t('label.reportNumber'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'reportDescription',
        title: t('label.reportDescription'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true,
        },
      },
      {
        field: 'reportingDate',
        title: t('label.reportingDate'),
        type: 'string',
        defaultState: {
          width: 80,
          hidden: true},
        cellRenderer: 'date',
      },
      {
        field: 'schedulingTag_mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'string',
        defaultState: {width: 77.5},
      },
      {
        field: 'schedulingTag_plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'string',
        defaultState: {width: 77.5},
      },
      {
        field: 'externalId',
        title: t('label.externalId'),
        type: 'string',
        defaultState: {width: 77.5},
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'set',
        defaultState: {width: 117},
        cellRenderer: 'status',
      },
    ],
    cellRenderers: {
      isPublished(_, node) {
        const disabled = !prms.events.publishreport;
        const event = node.data as EventView;
        return event.isPublished
          ? <RadBadge variant='outline' className='bg-green-50 text-green-700 hover:bg-green-50 hover:text-green-700'>{t('label.published')}</RadBadge>
          : <RadButton disabled={disabled} size='sm' className='size-fit p-1' onClick={() => setEventToPublish(event)}>{t('label.publish')}</RadButton>;
      },
      status(val, node) {
        if (!val) {
          return '';
        }

        const viewDatas = node.data;

        return (
          <RadBadge variant='outline' className='py-1 font-normal text-nowrap min-w-[100px] justify-center max-w-full' style={tailwindColorToBgFgStyle(viewDatas.status_displayColor)} title={viewDatas.status_description as string}>
            <div className='text-ellipsis overflow-hidden'>
              {viewDatas.status_description}
            </div>
          </RadBadge>
        );
      },
      date: (val: string) => val ? moment(val).format(FORMAT_DATE_DEFAULT) : '',
      textBold: (val: string) => val ? <span className='font-bold'>{val}</span> : '',
    },
    canSelect: false,
    onRowClick() {},
  };

  return (
    <>
      <EventListPage hasYearClassFilter title='label.menu.inspectionHistory' stage={EWORKORDER_EVENT_STAGE.COMPLETED} badge={<SchedulingBadge/>} route={ROUTE_SCHEDULING_INSPECTION_HISTORY} gridProps={gridProps} columnsAlwaysVisible={['event', 'description', 'schedulingTag_floc', 'schedulingTag_techClass_techClass', 'type_type', 'status', 'exportStatus']}/>
      {!!eventToPublish && <UsersToSendReportModal open event={eventToPublish} onValidate={handleSendReport} onClose={() => setEventToPublish(undefined)}/>}
    </>
  );
}
