import {useMutation} from '@apollo/client';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import {UpdateEventByIdMutation} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENTS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useEventStore} from '@app/stores/event';
import {TFieldsBlock} from '@app/types/app';
import {EFieldType, EWORKORDER_EVENT_STATUS} from '@app/utils/enums';
import {useTranslation} from 'react-i18next';

type ReportingAndInspectionDatesProps = Readonly<{
	readonly?: boolean;
}>;

export function ReportingAndInspectionDates({readonly}: ReportingAndInspectionDatesProps) {
  const {activeEvent, editEvent, setEditEvent} = useEventStore();
  const {t} = useTranslation();
  const [updateEventByIdApi] = useMutation <UpdateEventByIdMutation>(WORKORDER_EVENTS_UPDATE_BY_ID);
  const handleFieldChange = async (field: string, value: unknown) => {
    console.log('handleFieldChange', field, value);
    setEditEvent?.({...editEvent, [field]: value});
    if (value === '') {
      value = null;
    }

    try {
      await updateEventByIdApi({
        variables: {
          id: activeEvent?.id,
          data: {
            [field]: {set: value},
          },
        },
      });
      AppNotifications.success(t('message.success.eventUpdated'));
    } catch (e) {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  const fieldBlocks: TFieldsBlock[] = [
    {
      title: '',
      fields: [
        {
          label: 'label.inspectionDate',
          field: 'inspectionDate',
          fieldType: EFieldType.date,
          initialValue: activeEvent?.inspectionDate,
          value: editEvent?.inspectionDate,
          className: 'w-full',
        },
        {
          label: 'label.reportingDate',
          field: 'reportingDate',
          fieldType: EFieldType.date,
          initialValue: activeEvent?.reportingDate,
          value: editEvent?.reportingDate,
          isDisabled: editEvent?.status === EWORKORDER_EVENT_STATUS.COMPLETED,
          className: 'w-full',
        },
      ],
    },
  ];

  return (
    <div className='mb-4 -mt-2'>
      <FormFieldsBlock
        fieldsBlocks={fieldBlocks}
        isDisabled={readonly}
        onFieldBlur={handleFieldChange}
      />
    </div>
  );
}
