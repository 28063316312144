import React from 'react';

import {useLocation} from 'react-router-dom';

import UserPermissions from '@app/services/permissions/UserPermissions';
import {
  MENU_GROUP_COLORS,
  ROUTE_ADMIN,
  ROUTE_ADMIN_IMPORT_EXPORT,
  ROUTE_ADMIN_USER_ROLE,
  ROUTE_ANOMALY_MANAGEMENT,
  ROUTE_ANOMALY_MANAGEMENT_DAMAGE,
  ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION,
  ROUTE_DOCUMENTATION,
  ROUTE_DOCUMENTATION_DOCS,
  ROUTE_HOME,
  ROUTE_METHOD_ENGINEERING,
  ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION,
  ROUTE_DOCUMENTATION_IDWG,
  ROUTE_SCHEDULING,
  ROUTE_SCHEDULING_EVENTS,
  ROUTE_SCHEDULING_INSPECTION_PLANS,
  ROUTE_WORKPACK,
  ROUTE_WORKPACK_EXEC,
  ROUTE_WORKPACK_PREP,
  ROUTE_WORKPACK_REP,
  ROUTE_SCHEDULING_EXTERNAL_IMPORT,
  ROUTE_SCHEDULING_INSPECTION_HISTORY,
  ROUTE_WORKPACK_DASHBOARD,
} from '@app/utils/constants';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import useVersion from '@app/utils/hooks/useVersion';
import versaAimLogo from '@assets/images/versa-aim.svg?url';
import {ILinkChild, ILinkGroup} from '@holis/react-ui';
import {
  RadSideBar,
  RadSideBarBlock,
  RadSideBarCollapsingElement,
  RadSideBarGroup,
  RadSideBarGroupContent,
  RadSideBarGroupTrigger,
  RadSideBarLink,
  RadSideBarMainContent,
  RadSideBarSeparator,
} from '@holis/react-ui/rad';
import {useTranslation} from 'react-i18next';
import {
  LuAlertTriangle,
  LuCalendarRange,
  LuFileText,
  LuSettings,
  LuUserCog,
  LuWrench,
} from 'react-icons/lu';
import {Link} from 'react-router-dom';

type TMenu = React.PropsWithChildren & {
  readonly projectName?: string;
  readonly companyLogo?: string;
}

type TMenuItemChild = ILinkChild & { isAllowed?: (prms: UserPermissions) => boolean };
interface TMenuItem extends ILinkGroup {
  id?: string, icon: JSX.Element;
  children: TMenuItemChild[];
}

export const MENU_ITEMS: TMenuItem[] = [
  {
    groupName: 'label.menu.metheng',
    id: ROUTE_METHOD_ENGINEERING,
    icon: <LuSettings className='size-full'/>,
    children: [
      {
        title: 'label.menu.methengFloc',
        to: ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION,
        isAllowed(prms) {
          return prms.menu.methengFloc;
        },
      },
      {
        title: 'label.menu.methengInsp',
        isAllowed(prms) {
          return prms.menu.methengInsp;
        },
      },
      {
        title: 'label.menu.methengCML',
        isAllowed(prms) {
          return prms.menu.methengCML;
        },
      },
    ],
  },
  {
    groupName: 'label.menu.doc',
    id: ROUTE_DOCUMENTATION,
    icon: <LuFileText className='size-full'/>,
    children: [
      {
        title: 'label.menu.docDocument',
        to: ROUTE_DOCUMENTATION_DOCS,
        isAllowed(prms) {
          return prms.menu.docDocument;
        },
      },
      {
        title: 'label.menu.docDwg',
        to: ROUTE_DOCUMENTATION_IDWG,
        isAllowed(prms) {
          return prms.menu.docDwg;
        },
      },
    ],
  },
  {
    groupName: 'label.menu.insprog',
    id: ROUTE_SCHEDULING,
    icon: <LuCalendarRange className='size-full'/>,
    children: [
      {
        title: 'label.menu.insprogEvent',
        to: ROUTE_SCHEDULING_EVENTS,
        isAllowed(prms) {
          return prms.menu.insprogEvent;
        },
      },
      {
        title: 'label.menu.insprogPlan',
        to: ROUTE_SCHEDULING_INSPECTION_PLANS,
        isAllowed(prms) {
          return prms.menu.insprogPlan;
        },
      },
      {
        title: 'label.menu.insprogReport',
        to: ROUTE_SCHEDULING_INSPECTION_HISTORY,
        isAllowed(prms) {
          return prms.menu.insprogReport;
        },
      },
      {
        title: 'label.menu.insprogImport',
        to: ROUTE_SCHEDULING_EXTERNAL_IMPORT,
        isAllowed(prms) {
          return prms.menu.insprogImport;
        },
      },
    ],
  },
  {
    groupName: 'label.menu.dwims',
    id: ROUTE_WORKPACK,
    icon: <LuWrench className='size-full'/>,
    children: [
      {
        title: 'label.menu.dwimsDashboard',
        to: ROUTE_WORKPACK_DASHBOARD,
        isAllowed(prms) {
          return prms.menu.dwimsDashboard;
        },
      },
      {
        title: 'label.menu.dwimsPrep',
        to: ROUTE_WORKPACK_PREP,
        isAllowed(prms) {
          return prms.menu.dwimsPrep;
        },
      },
      {
        title: 'label.menu.dwimsExec',
        to: ROUTE_WORKPACK_EXEC,
        isAllowed(prms) {
          return prms.menu.dwimsExec;
        },
      },
      {
        title: 'label.menu.dwimsReport',
        to: ROUTE_WORKPACK_REP,
        isAllowed(prms) {
          return prms.menu.dwimsReport;
        },
      },
    ],
  },
  {
    groupName: 'label.menu.anomaly',
    id: ROUTE_ANOMALY_MANAGEMENT,
    icon: <LuAlertTriangle className='size-full'/>,
    children: [
      {
        title: 'label.menu.anomalyNotif',
        to: ROUTE_ANOMALY_MANAGEMENT_NOTIFICATION,
        isAllowed(prms) {
          return prms.menu.anomalyNotif;
        },
      },
      {
        title: 'label.menu.anomalyDamage',
        to: ROUTE_ANOMALY_MANAGEMENT_DAMAGE,
        isAllowed(prms) {
          return prms.menu.anomalyDamage;
        },
      },
    ],
  },
  {
    groupName: 'label.menu.admin',
    id: ROUTE_ADMIN,
    icon: <LuUserCog className='size-full'/>,
    children: [
      {
        title: 'label.menu.adminUserrole',
        to: ROUTE_ADMIN_USER_ROLE,
        isAllowed(prms) {
          return prms.menu.adminUserrole;
        },
      },
      {
        title: 'label.menu.adminAsset',
        to: '#',
        isAllowed(prms) {
          return prms.menu.adminAsset;
        },
      },
      {
        title: 'label.menu.adminRef',
        to: '#',
        isAllowed(prms) {
          return prms.menu.adminRef;
        },
      },
      {
        title: 'label.menu.adminImport',
        to: ROUTE_ADMIN_IMPORT_EXPORT,
        isAllowed(prms) {
          return prms.menu.adminImport;
        },
      },
    ],
  },
];

const menuItemsFilteredWithPermissions = (prms: UserPermissions): TMenuItem[] => {
  const filteredMenuGroups: TMenuItem[] = [];
  for (const group of MENU_ITEMS) {
    const filteredChildren: ILinkChild[] = [];
    for (const child of group.children) {
      if (child.isAllowed && !child.isAllowed(prms)) {
        continue;
      }

      filteredChildren.push(child);
    }

    if (filteredChildren.length > 0) {
      filteredMenuGroups.push({
        ...group,
        children: filteredChildren,
      });
    }
  }

  return filteredMenuGroups;
};

export default function Menu({projectName, companyLogo}: TMenu) {
  const {t} = useTranslation();
  const {pathname} = useLocation();
  const prms = useUserPermissions();
  let menuItems: TMenuItem[] = [
    ...menuItemsFilteredWithPermissions(prms),
  ];
  menuItems = menuItems.map((group: TMenuItem) => {
    if (group.title) {
      group.title = t(group.title);
    }

    if (group.groupName) {
      group.groupName = t(group.groupName);
    }

    return {
      ...group,
      children: group.children.map((child: ILinkChild) => {
        if (child.title) {
          child.title = t(child.title);
        }

        return {
          ...child,
          active: child?.to === pathname,
        };
      }),
    };
  });

  const version = useVersion();

  return (
    <RadSideBar className='bg-gray-50'>
      <RadSideBarBlock className='min-h-20'>
        <Link to={ROUTE_HOME} className='flex flex-col gap-2 items-center px-4'>
          <img src={companyLogo} alt='company logo'/>
          <div>
            {projectName ?? ''}
          </div>
        </Link>
      </RadSideBarBlock>

      <RadSideBarSeparator/>

      <RadSideBarMainContent>
        <div className='flex flex-col gap-1 w-full px-3'>
          {menuItems.map((menuItem, index) => (
            <RadSideBarGroup
              key={`sideBarGroup-${menuItem.groupName}`}
              id={`sideBarGroup-${menuItem.groupName}`}
            >
              <RadSideBarGroupTrigger
                tooltipContent={menuItem.groupName}
                className='h-8'
              >
                <div className='flex items-center gap-2 pl-0.5 truncate'>
                  <span className={`size-5 p-0.5 rounded text-white ${MENU_GROUP_COLORS[index]}`}>
                    {menuItem.icon}
                  </span>

                  <RadSideBarCollapsingElement className='font-semibold'>
                    {menuItem.groupName}
                  </RadSideBarCollapsingElement>
                </div>
              </RadSideBarGroupTrigger>

              <RadSideBarGroupContent dropdownLabel={menuItem.groupName}>
                {menuItem.children.map(child => (
                  <RadSideBarLink
                    key={`sideBarGroupItem-${child.title}`}
                    active={child.active}
                    disabled={!child.to || child.to === '#'}
                    className={`h-7 hover:bg-blue-100 ${child.active ? 'bg-blue-100 text-blue-600 font-semibold' : ''}`}
                  >
                    <Link to={child.to ?? '#'}>
                      {child.title}
                    </Link>
                  </RadSideBarLink>
                ))}
              </RadSideBarGroupContent>
            </RadSideBarGroup>
          ))}
        </div>
      </RadSideBarMainContent>

      <RadSideBarSeparator/>

      <RadSideBarBlock className='flex justify-start gap-2 min-h-12 px-3 text-muted-foreground font-medium' sideBarClosedClassName='justify-center'>
        <img src={versaAimLogo} className='h-7 rounded'/>

        <RadSideBarCollapsingElement className='flex flex-col items-start text-xs font-semibold'>
          <span className='font-bold text-foreground'>
            VERSA AIM
          </span>

          {version && `v${version}`}
        </RadSideBarCollapsingElement>
      </RadSideBarBlock>
    </RadSideBar>
  );
}
