import {useLazyQuery} from '@apollo/client';
import NotificationList from '@app/components/Common/Block/Notification/NotificationBlock/NotificationList';
import {GetNotificationsByEvtIdQuery, Notification} from '@app/graphql/__types__/graphql';
import {NOTIFICATIONS_GET_BY_EVT_ID} from '@app/graphql/requests';
import {useEventStore} from '@app/stores/event';
import useOptimusConfig from '@app/utils/hooks/useOptimusConfig';
import {useEffect} from 'react';

type IanListProps = {
  className?: string;
  readonly?: boolean;
}

export default function IanList({readonly}: Readonly<IanListProps>) {
  const config = useOptimusConfig();
  const {activeEvent, setEventNotifications, eventNotifications, setFetchEventNotifications} = useEventStore();

  const [getEventNotificationsApi, {loading, error, called: eventNotifApiCalled, refetch: eventNotifRefetch}] = useLazyQuery<GetNotificationsByEvtIdQuery>(NOTIFICATIONS_GET_BY_EVT_ID);

  const getEventNotifications = () => {
    (eventNotifApiCalled ? eventNotifRefetch({
      evtId: activeEvent?.id,
    }) : getEventNotificationsApi({
      variables: {
        evtId: activeEvent?.id,
      },
      fetchPolicy: 'no-cache',
    })).then(queryResult => {
      setEventNotifications((queryResult.data?.notifications ?? []) as Partial<Notification>[]);
    });
  };

  useEffect(() => {
    getEventNotifications();
    setFetchEventNotifications(getEventNotifications);
  }, []);

  return (
    <NotificationList error={!!error} createIanBtnDisabled={readonly || config.getActionIsDisabled('event', 'notificationAdd', activeEvent?.status)} isLoading={loading} notifications={eventNotifications}/>
  );
}
