import {gql} from '@apollo/client/core';
import {INSPECTION_POINTS_COMMON_FIELDS, WORKORDER_EVENTS_COMMON_FIELDS, WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS} from '../fragments';

export const WORKORDER_EVENT_INSPECTION_POINTS_CREATE = gql`
${WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS}
mutation createEventInspectionPoint($inspId: Int!, $evtId: Int!) {
  createOneEventInspectionPoint (data: {inspId: $inspId, wrkoId: $evtId}) {
    ...EventInspectionPointCommonFields
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_INSPECTION_POINTS_CREATE_MANY = gql`
mutation CreateEventInspectionPoints($data: [EventInspectionPointCreateManyInput!]!) {
  createManyEventInspectionPoint(data: $data) {
    count
  }
}`;

export const WORKORDER_EVENT_INSPECTION_POINTS_DELETE_ONE_BY_ID = gql`
mutation DeleteOneEventInspectionPointById($id: Int!) {
  deleteOneEventInspectionPoint(where: {id: $id}) {
    id
  }
}
`;

export const WORKORDER_EVENT_INSPECTION_POINTS_DELETE_MANY = gql`
mutation DeleteEventInspectionPoints($ids: [Int!]) {
  deleteManyEventInspectionPoint (where: {id: {in: $ids}}) {
    count
  }
}`;

export const WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS}
${INSPECTION_POINTS_COMMON_FIELDS}
query GetEventInspectionPointsByEvtId($evtId: Int!, $orderBy: [EventInspectionPointOrderByWithRelationInput!]) {
  eventInspectionPoints (where: {wrkoId: {equals: $evtId}}, orderBy: $orderBy) {
    ...EventInspectionPointCommonFields
    inspectionPoint {
      ...InspectionPointCommonFields
      _count {
        pictures
      }
    }
  }
}`;

export const WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_INSP_ID = gql`
${WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS}
${WORKORDER_EVENTS_COMMON_FIELDS}
query GetEventInspectionPointsByInspId($inspId: Int!, $orderBy: [EventInspectionPointOrderByWithRelationInput!]) {
  eventInspectionPoints (where: {inspId: {equals: $inspId}}, orderBy: $orderBy) {
    ...EventInspectionPointCommonFields
    event {
      ...EventCommonFields
    }
  }
}`;

export const WORKORDER_EVENT_INSPECTION_POINTS_GET_BY_ID = gql`
${WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS}
${WORKORDER_EVENTS_COMMON_FIELDS}
query GetEventInspectionPointById($id: Int!) {
  eventInspectionPoint (where: {id: $id}) {
    ...EventInspectionPointCommonFields
    event {
      ...EventCommonFields
    }
  }
}`;

export const WORKORDER_EVENT_INSPECTION_POINTS_UPDATE_BY_ID = gql`
${WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS}
${INSPECTION_POINTS_COMMON_FIELDS}
mutation UpdateEventInspectionPointById($id: Int!, $data: EventInspectionPointUncheckedUpdateInput!) {
  updateOneEventInspectionPoint(where: {id: $id}, data: $data) {
    ...EventInspectionPointCommonFields
    inspectionPoint {
      ...InspectionPointCommonFields
      _count {
        pictures
      }
    }
  }
}
`;

export const WORKORDER_EVENT_INSPECTION_POINTS_GET_COUNTER = gql`
query GetWorkorderEventInspectionPointsCounter {
  aggregateEventInspectionPoint {
    _count {
      id
    }
  }
}
`;

export const WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID = gql`
${WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS}
query GetLatestEventInspectionPointByInspId($inspId: Int!) {
  findFirstEventInspectionPoint(where: {inspId: {equals: $inspId}}, orderBy: [{reportingDate: {sort: desc}}, {id: desc}]) {
    ...EventInspectionPointCommonFields
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_INSP_ID = gql`
${WORKORDER_EVENT_INSPECTION_POINTS_COMMON_FIELDS}
query GetLatestEventInspectionPointWithQualReadingNonEmptyByInspId($inspId: Int!) {
  lastEventInspectionPointWithQualReading (inspId: $inspId) {
    ...EventInspectionPointCommonFields
    event {
      id
      event
    }
  }
}`;
