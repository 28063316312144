import {useLazyQuery} from '@apollo/client';
import AppCheckbox from '@app/components/Common/Form/Checkbox';
import {FlocView, GetAllFlocsViewWithDetailQuery} from '@app/graphql/__types__/graphql';
import {FLOCS_GET_VIEW_ALL_WITH_DETAIL_BY_SITES} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {OBJ_NEW_ID, ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL} from '@app/utils/constants';
import {RadButton} from '@holis/react-ui/rad';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {generatePath, useNavigate} from 'react-router-dom';
import CreateFlocModal from './Create';
import {generatePathWithBaseUrl, textEllipsisCellRenderer, textEllipsisWrapper} from '@app/utils/functions';
import AppGrid from '@app/components/Common/AppGrid';
import {CellRenderer, GridProps} from '@holis/react-ui';

const GRID_NAME = 'methodEngineering-flocsGrid';

export default function FunctionalLocationPage() {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {flocViews, setFlocViews, creatingFloc, setActiveFloc, setFetchFlocs} = useFlocStore();
  const {t} = useTranslation();
  const [getFlocListApi, {
    loading,
    error,
  }] = useLazyQuery<GetAllFlocsViewWithDetailQuery>(FLOCS_GET_VIEW_ALL_WITH_DETAIL_BY_SITES, {
    variables: {
      orderBy: [
        {
          id: 'desc',
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const fetchFlocs = () => {
    if (selectedSites?.length) {
      getFlocListApi({
        variables: {
          sites: selectedSites!,
        },
      })
        .then(queryResults => setFlocViews((queryResults.data?.flocViews ?? []) as Array<Partial<FlocView>>));
    } else {
      setFlocViews([]);
    }
  };

  const handleNewFloc = () => {
    setActiveFloc({
      id: OBJ_NEW_ID,
    }, true);
  };

  useEffect(() => {
    fetchFlocs();
    setFetchFlocs(fetchFlocs);
  }, [selectedSites]);

  const actionCellRenderer : CellRenderer<Partial<FlocView>> = (_, node) => (
    <RadButton
      size='icon'
      variant='outline'
      className='size-fit p-1'
      onClick={e => {
        const flocNumber = node.data.floc;
        if (e.ctrlKey || e.metaKey) {
          window.open(generatePathWithBaseUrl(generatePath(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL.replace(':number', encodeURIComponent(flocNumber!)))), '_blank');
          return;
        }

        return navigate(ROUTE_METHOD_ENGINEERING_FUNCTIONAL_LOCATION_DETAIL.replace(':number', encodeURIComponent(flocNumber!)));
      }}
    >
      <MdOpenInNew size={18}/>
    </RadButton>
  );

  const gridProps: GridProps<Partial<FlocView>> = {
    columns: [
      {
        field: undefined,
        title: '',
        defaultState: {
          width: 60,
          filter: false,
        },
        cellRenderer: actionCellRenderer,
        cannotHide: true,
        filter: false,
      },
      {
        field: 'floc',
        title: t('label.floc'),
        type: 'string',
        cellRenderer: 'bold',
        defaultState: {
          width: 329,
        },
        cannotHide: true,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {
          width: 428,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'techClass_techClass',
        title: t('label.techClass'),
        type: 'set',
        defaultState: {
          width: 117,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'referenceDocument',
        title: t('label.referenceDocument'),
        type: 'string',
        defaultState: {
          width: 249,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'sector_sector',
        title: t('label.sector'),
        type: 'set',
        defaultState: {
          width: 99,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'processFunction_processFunction',
        title: t('label.processFunction'),
        type: 'string',
        defaultState: {
          width: 132,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'grid_grid',
        title: t('label.grid'),
        type: 'set',
        defaultState: {
          width: 99,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'parent_floc',
        title: t('label.parent'),
        type: 'string',
        defaultState: {
          width: 332,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'planningPlant_code',
        title: t('label.planningPlant'),
        type: 'set',
        defaultState: {
          width: 121,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'plantWorkCenter_code',
        title: t('label.plantWorkCenter'),
        type: 'set',
        defaultState: {
          width: 135,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'set',
        defaultState: {
          width: 110,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'set',
        defaultState: {
          width: 129,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'strategy_strategy',
        title: t('label.strategy'),
        type: 'set',
        defaultState: {
          width: 170,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'integritySystem',
        title: t('label.integritySystem'),
        type: 'string',
        defaultState: {
          width: 140,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'classification',
        title: t('label.classification'),
        type: 'string',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      /// ....
      {
        field: 'longDescription',
        title: t('label.longDescription'),
        type: 'string',
        cellRenderer: 'raw',
        defaultState: {
          hidden: true,
        },
      },
      {
        field: 'catalog_code',
        title: t('label.catalog'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'parent_floc',
        title: t('label.parent'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'grid_grid',
        title: t('label.grid'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'classSece',
        title: t('label.classSece'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'legislation',
        title: t('label.legislation'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'docName',
        title: t('label.docName'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'corrosionLoop',
        title: t('label.corrosionLoop'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'consequenceProd_code',
        title: t('label.consequenceProd'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'shutdown_code',
        title: t('label.shutdown'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'externalRef',
        title: t('label.externalRef'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'flagStatus',
        title: t('label.flagStatusLabel'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'catalogProfile_catalog',
        title: t('label.catalogProfile'),
        type: 'string',
        defaultState: {
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },

      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          hidden: true,
        },
      },
    ],
    cellRenderers: {
      damage: val => <AppCheckbox disabled checked={!!val}/>,
      flagStatus: val => textEllipsisWrapper(val ? t(`label.flagStatus.${val}`) : ''),
      // eslint-disable-next-line react/no-danger
      raw: (val: unknown) => textEllipsisWrapper(<div dangerouslySetInnerHTML={{__html: val as string}}/>),
      bold: (val: unknown) => textEllipsisWrapper(<span className='font-bold'>{val as string}</span>),
    },
    data: flocViews ?? [],
  };

  return (
    <div className='h-full'>
      {creatingFloc && (
        <CreateFlocModal
          isOpen={creatingFloc}
          onOpenChange={isOpen => {
            if (!isOpen) {
              setActiveFloc(undefined);
            }
          }}
        />
      )}

      { (error && <div>{error.message}</div>) || <AppGrid
        name={GRID_NAME}
        fileNameOnExport={t('label.menu.functionalLocations')}
        title={t('label.menu.functionalLocations')}
        isLoading={loading}
        actions={
          <RadButton
            size='sm'
            className='flex gap-1 text-sm'
            onClick={handleNewFloc}
          >
            <LuPlusCircle/> {t('label.addFloc')}
          </RadButton>
        }
        {...gridProps}
      />}
    </div>
  );
}
