import {useLazyQuery} from '@apollo/client';
import NotificationList from '@app/components/Common/Block/Notification/NotificationBlock/NotificationList';
import {GetNotificationsByFlocIdsQuery, Notification} from '@app/graphql/__types__/graphql';
import {NOTIFICATIONS_GET_BY_FLOC_IDS} from '@app/graphql/requests';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {useEffect} from 'react';

export default function IanList() {
  const {activeFloc, notifications, setNotifications} = useFlocStore();
  const [getNotifsByFlocApi, {loading, error}] = useLazyQuery<GetNotificationsByFlocIdsQuery>(NOTIFICATIONS_GET_BY_FLOC_IDS);

  const getFlocNotifications = () => {
    if (activeFloc?.id) {
      getNotifsByFlocApi({
        variables: {
          flocIds: [activeFloc.id],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setNotifications((queryResult.data?.notifications ?? []) as Partial<Notification>[]));
    } else {
      setNotifications([]);
    }
  };

  useEffect(() => {
    getFlocNotifications();
  }, [activeFloc!.id]);

  return (
    <div className='mb-4'>
      <NotificationList notifications={notifications} isLoading={loading} error={!!error}/>
    </div>
  );
}
