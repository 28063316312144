import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import {Damage, Picture, Event, GetPicturesByQuery} from '@app/graphql/__types__/graphql';
import {PICTURES_GET_MANY_BY} from '@app/graphql/requests';
import {TCarouselCallback} from '@holis/react-ui';
import BasePictureCarousel from '@app/components/Common/Block/Picture/Block/PictureCarousel';
import {EApiOperator} from '@app/utils/enums';

type TPictureCarousel = Readonly<{
  onCarouselRender?: TCarouselCallback;
  damage: Partial<Damage>;
  event?: Partial<Event>;
  readonly?: boolean;
  onChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
}>

export default function PictureCarousel({damage, onCarouselRender, event, onChanged, readonly}: TPictureCarousel) {
  const {data, loading, error, refetch} = useQuery<GetPicturesByQuery>(PICTURES_GET_MANY_BY, {
    variables: {
      data: {
        dmgeId: {
          equals: damage.id!,
        },
        ...(event?.id ? {wrkoId: {equals: event.id!}} : {}),
      },
      orderBy: [
        {date: {sort: 'desc'}},
        {id: 'desc'},
      ],
    },
    fetchPolicy: 'no-cache',
  });
  const [isLoading, setLoading] = useState<boolean>(loading);
  const handleImagesChanged = async (images?: Partial<Picture>[], operator?: EApiOperator) => {
    await refetch();
    onChanged?.(images, operator);
  };

  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <div className='flex flex-col w-full'>
      <BasePictureCarousel readonly={readonly} pictures={data?.pictures as Partial<Picture>[]} isLoading={isLoading} uploadParameters={{dmgeId: damage!.id!, ...(event?.id ? {wrkoId: event?.id} : {}), flocId: damage.flocId}} error={error} onChanged={handleImagesChanged} onCarouselRender={onCarouselRender}/>
    </div>
  );
}
