import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import {EventCountByStatusSitePlannedDateResponse} from '@app/graphql/__types__/graphql';
import {useDataStore} from '@app/stores/data';
import {useEventPerformanceDashboardStore} from '@app/stores/event/performanceDashboard';
import {ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS} from '@app/utils/constants';
import {tailwindColorToHex} from '@app/utils/functions';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {BarChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Bar, LabelList, LegendProps, TooltipProps} from 'recharts';
import {NameType} from 'recharts/types/component/DefaultTooltipContent';
import {ValueType} from 'tailwindcss/types/config';

type TBarChartData = {
  site: string;
  counters: {
    [key: string]: number;
  };
}

export default function EventCounterBarChart() {
  const {filteredData, getStageColor, setAvailableStages, availableStages, classFilters, plannedDateFilters} = useEventPerformanceDashboardStore();
  const [chartData, setChartData] = useState<Array<TBarChartData>>();
  const {selectedSites} = useDataStore();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const onClickBarCell = (stage: string, site: string) => {
    navigate(ROUTE_WORKPACK_DASHBOARD_FILTERED_RESULTS, {
      state: {
        stages: [stage],
        sites: [site],
        classes: classFilters,
        plannedDateFilters,
      },
    });
  };

  const renderLegend = (props: unknown) => (
    <ul className='m-4'>
      {
        (props as unknown as LegendProps).payload?.map(entry => (
          <li key={`item-${entry.value}`} className='flex gap-2 items-center'><span className='w-3 h-3 inline-block' style={{background: entry.color ?? 'white'}}/> <span className='flex-1'>{entry.value}</span></li>
        ))
      }
    </ul>
  );

  function CustomTooltip({active, payload, label}: TooltipProps<ValueType, NameType>) {
    if (active && payload && payload.length) {
      return (
        <div className='bg-white p-4 border border-gray-200 shadow-md'>
          <div className='mb-3 pb-1 border-b font-bold'>{`${label}: ${payload.reduce((prev, curr) => prev + Number(curr.value), 0)}`}</div>
          <div className='flex flex-col gap-2'>
            {payload.map(pld => {
              const color = getStageColor((pld.dataKey as string).replace('counters.', ''));
              const textColor = tailwindColorToHex(color, '700');
              return (
                <div key={pld.name} className='font-bold' style={{color: textColor}}>{pld.name}: {pld.value}</div>
              );
            })}
          </div>
        </div>
      );
    }

    return null;
  }

  useEffect(() => {
    if (typeof filteredData !== 'undefined') {
      const chartDataToBuild: Record<string, TBarChartData> = {};
      selectedSites?.forEach(site => {
        chartDataToBuild[site] = {
          site,
          counters: {},
        };
      });
      const evtStages: string[] = [];
      filteredData?.forEach((item: EventCountByStatusSitePlannedDateResponse) => {
        const {site, stage, counter} = item;
        if (Object.keys(chartDataToBuild).includes(site)) {
          if (!chartDataToBuild[site].counters[stage]) {
            chartDataToBuild[site].counters[stage] = 0;
          }

          if (!evtStages.includes(stage)) {
            evtStages.push(stage);
          }

          chartDataToBuild[site].counters[stage] += counter;
        }
      });
      setAvailableStages(_.sortBy(evtStages, [stage => `${_.capitalize(getStageColor(stage))}: ${t(`label.eventStages.${stage.toLowerCase()}`)}`]));
      setChartData(Object.values(chartDataToBuild));
    }
  }, [filteredData]);

  return (
    <SpinnerLoaderComponent isLoading={typeof chartData === 'undefined'}>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          barSize={100}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray='3 3'/>
          <XAxis dataKey='site'/>
          <YAxis/>
          <Tooltip content={<CustomTooltip/>} animationDuration={100}/>
          <Legend verticalAlign='middle' align='right' width={200} layout='vertical' content={renderLegend}/>
          {availableStages?.map((stage: string) => (
            <Bar key={stage} isAnimationActive name={`${t(`label.eventStages.${stage.toLowerCase()}`)}`} className='hover:cursor-pointer hover:opacity-85' stackId='site' fill={tailwindColorToHex(getStageColor(stage), '300')} dataKey={`counters.${stage}`} activeBar={{opacity: 0.9}} onClick={(props: unknown) => onClickBarCell(stage, (props as unknown as {site: string}).site)}>
              <LabelList dataKey={`counters.${stage}`} fill={tailwindColorToHex(getStageColor(stage), '800')} fontWeight='bold' position='center'/>
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </SpinnerLoaderComponent>
  );
}
