import {gql} from '@apollo/client';
import {WORKORDER_EVENT_WORKFLOWS_COMMON_FIELDS} from '../fragments';

export const WORKORDER_EVENT_WORKFLOWS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_WORKFLOWS_COMMON_FIELDS}
query GetEventWorkflowsByEvtId($evtId: Int!) {
  eventWorkflows(where: {wrkoId: {equals: $evtId}}, orderBy: [{actionDate: {sort: desc}}, {id: desc}]) {
    ...EventWorkflowCommonFields
  }
}
`;

export const WORKORDER_EVENT_WORKFLOWS_CREATE = gql`
${WORKORDER_EVENT_WORKFLOWS_COMMON_FIELDS}
mutation CreateEventWorkflow($data: EventWorkflowUncheckedCreateInput!) {
  createOneEventWorkflow(data: $data) {
    ...EventWorkflowCommonFields
  }
}
`;

