import Actions from '@app/components/Common/Block/Cml/CmlModal/components/Actions';
import GeneralInfo from '@app/components/Common/Block/Cml/CmlModal/components/GeneralInfo';
import PictureCarousel from '@app/components/Common/Block/Cml/CmlModal/components/pictures/PictureCarousel';
import MultiplePanelsModal, {TMultiplePanelsModal} from '@app/components/Modal/NewMultiplePanels';
import {Cml, Event, EventCml, GetLatestEventCmlByCmlIdQuery, Picture, UpdateCmlByIdMutation, UpdateEventCmlByIdMutation} from '@app/graphql/__types__/graphql';
import useCmlStore from '@app/stores/cml';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {EApiOperator} from '@app/utils/enums';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {TCarouselApi} from '@holis/react-ui';
import {useEffect, useRef, useState} from 'react';
import DeleteCmlModal from './components/DeleteCmlModal';
import EventCmlHistory from './components/events/EventCmlsHistory';
import Footer from './components/Footer';
import Header from './components/Header';
import MeasurementBlock from './components/measurement';
import {CMLS_UPDATE_BY_ID, WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID, WORKORDER_EVENT_CMLS_UPDATE_BY_ID} from '@app/graphql/requests';
import {useLazyQuery, useMutation} from '@apollo/client';
import {useHolisAuth} from '@holis/auth-client-react';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import ClassPositionItemSelector from '@app/components/Common/ClassPositionItemSelector';
type TCmlModal = TMultiplePanelsModal<Partial<Cml>> & Readonly<{
  event?: Partial<Event>;
  eventCml?: Partial<EventCml>;
  onCreatedOrUpdated?: (cml?: Partial<Cml>, operator?: EApiOperator) => void;
  onPicturesChanged?: (images?: Partial<Picture>[], operator?: EApiOperator) => void;
  allItems?: Partial<EventCml|Cml>[];
}>

export default function CmlModal({item, event, onOpenChange, onCreatedOrUpdated, onPicturesChanged, eventCml, allItems, ...restProps}: TCmlModal) {
  const isNew = !item.id || item.id === OBJ_NEW_ID;
  const prms = useUserPermissions();
  const {t} = useTranslation();
  const {user} = useHolisAuth();
  const {deleteCmlModalOpen, resetData, changeDeleteCmlModalDisplay, setActiveCml, activeCml} = useCmlStore();
  const cmlUpdateDatas = useRef<Partial<Cml>>({});
  const evtCmlUpdateDatas = useRef<Partial<EventCml>>({});
  const [itemToOpen, setItemToOpen] = useState<Partial<Cml>>();
  const [isModalOpened, setIsModalOpened] = useState(true);
  const [allItemsFetched, setAllItemsFetched] = useState<Partial<EventCml>[]>();

  const [editedCml, setEditedCml] = useState<Partial<Cml>>(item);
  const [editedEventCml, setEditedEventCml] = useState<Partial<EventCml|undefined>>(eventCml);

  const [pictureCarouselApi, setPictureCarouselApi] = useState<TCarouselApi>();
  const [updateCmlApi] = useMutation<UpdateCmlByIdMutation>(CMLS_UPDATE_BY_ID);
  const [updateEventCmlApi] = useMutation<UpdateEventCmlByIdMutation>(WORKORDER_EVENT_CMLS_UPDATE_BY_ID);
  const [getLastEventCmlApi] = useLazyQuery<GetLatestEventCmlByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_LATEST_BY_CML_ID);

  const handlePictureCarouselRender = (api: TCarouselApi) => {
    setPictureCarouselApi(api);
  };

  const handleRightPanelResize = () => {
    pictureCarouselApi?.calculatePagination();
  };

  const handleCloseModal = () => {
    resetData();

    if (itemToOpen) {
      setActiveCml(itemToOpen);
      setItemToOpen(undefined);
      setIsModalOpened(true);
      return;
    }

    onOpenChange?.(false);
    restProps.onClose?.();
  };

  const handleCmlDeleted = () => {
    handleCloseModal();
    onCreatedOrUpdated?.(item);
  };

  const handleFieldUpdated = (imeType: 'cml' | 'meas', field: string, value: unknown) => {
    if ((value as { id: string })?.id) {
      value = (value as { id: string }).id;
    }

    if (imeType === 'cml') {
      if (field !== 'classId') {
        cmlUpdateDatas.current = {...cmlUpdateDatas.current, [field]: value};
      }
    } else {
      evtCmlUpdateDatas.current = {...evtCmlUpdateDatas.current, [field]: value};
    }
  };

  const saveAlarms = async () => {
    const r = await updateCmlApi({
      variables: {
        id: item.id,
        data: {
          nominal: cmlUpdateDatas.current.nominal ? {set: cmlUpdateDatas.current.nominal} : undefined,
          alarm1: cmlUpdateDatas.current.alarm1 ? {set: cmlUpdateDatas.current.alarm1} : undefined,
          alarm2: cmlUpdateDatas.current.alarm2 ? {set: cmlUpdateDatas.current.alarm2} : undefined,
          alarm3: cmlUpdateDatas.current.alarm3 ? {set: cmlUpdateDatas.current.alarm3} : undefined,
        },
      },
    });
    setActiveCml((r.data?.updateOneCml as Partial<Cml>));
  };

  const handleSave = async () => {
    const cmlUpdateRequest: Record<string, unknown> = {};
    const evtCmlUpdateRequest: Record<string, unknown> = {};

    Object.keys(cmlUpdateDatas.current).forEach(key => {
      if (key === 'classId') {
        return;
      }

      cmlUpdateRequest[key] = {set: cmlUpdateDatas.current[key as keyof Cml] ?? null};
    });
    Object.keys(evtCmlUpdateDatas.current).forEach(key => {
      evtCmlUpdateRequest[key] = {set: evtCmlUpdateDatas.current[key as keyof EventCml] ?? null};
    });

    try {
      if (Object.keys(cmlUpdateDatas.current).length) {
        const r = await updateCmlApi({variables: {id: item.id, data: cmlUpdateRequest}});
        setEditedCml(r.data?.updateOneCml as Partial<Cml>);
      }

      if (Object.keys(evtCmlUpdateDatas.current).length) {
        evtCmlUpdateRequest.reader = {set: user?.username};
        evtCmlUpdateRequest.reportingDate = {set: new Date()};
        const r = await updateEventCmlApi({variables: {id: eventCml?.id, data: evtCmlUpdateRequest}});
        setEditedEventCml(r.data?.updateOneEventCml as Partial<EventCml>);
        setEditedCml(r.data?.updateOneEventCml?.cml as Partial<Cml>);
      }

      cmlUpdateDatas.current = {};
      evtCmlUpdateDatas.current = {};

      AppNotifications.success(t('message.success.eventCmlUpdated'));
      onCreatedOrUpdated?.(item, isNew ? EApiOperator.CREATE : EApiOperator.UPDATE);
    } catch {
      AppNotifications.error(t('message.error.default.title'));
    }
  };

  const handleBlur = async () => {
    console.log(cmlUpdateDatas, editedCml, evtCmlUpdateDatas, editedEventCml);
    for (const key in cmlUpdateDatas.current) {
      if (cmlUpdateDatas.current[key as keyof Cml] !== editedCml[key as keyof Cml]) {
        await handleSave();
        return;
      }
    }

    for (const key in evtCmlUpdateDatas.current) {
      if (evtCmlUpdateDatas.current[key as keyof EventCml] !== editedEventCml?.[key as keyof EventCml]) {
        await handleSave();
        return;
      }
    }
  };

  const fetchAllItemsEventCmls = async () => {
    const fetchedItems = [];
    for (const e of allItems ?? []) {
      if (e.__typename === 'Cml') {
        const r = await getLastEventCmlApi({
          variables: {
            cmlId: e.id!,
          },
        });
        if (r.data?.findFirstEventCml) {
          fetchedItems.push({...r.data?.findFirstEventCml, cml: e} as Partial<EventCml>);
        }
      } else {
        fetchedItems.push(e as Partial<EventCml>);
      }
    }

    setAllItemsFetched(fetchedItems);
  };

  useEffect(() => {
    if (allItems) {
      fetchAllItemsEventCmls();
    }
  }, [allItems]);

  useEffect(() => {
    setEditedCml(item);
  }, [item]);

  useEffect(() => {
    setEditedEventCml(eventCml);
  }, [eventCml]);

  const getItemSelector = () => allItemsFetched && <ClassPositionItemSelector<Partial<EventCml>>
    items={allItemsFetched.map(ecml => ({
      id: ecml.cmlId!,
      itemClass: ecml.cml?.codeGroup?.class?.class,
      itemPosition: ecml.cml?.position,
      itemColor: ecml.qualReading?.integrityCondition?.color as string,
      item: ecml,
    }))}
    selectedId={activeCml?.id!}
    onItemSelect={ecml => setActiveCml(ecml.cml)}
  />;

  return (
    <MultiplePanelsModal
      autoSaveId='modal-cml'
      header={<Header actions={isNew ? null : <Actions cml={item} deleteDisabled={!prms.cmls.delete} onCreatedOrUpdated={onCreatedOrUpdated}/>} cml={item}/>}
      footer={
        <div className='flex flex-col w-full'>
          {getItemSelector()}
          <Footer cml={item} onCancelClick={() => setIsModalOpened(false)}/>
        </div>
      }
      panelsOnResize={[undefined, handleRightPanelResize]}
      panelsDefaultSize={[45, 55]}
      panelsMinSize={[30, 55]}
      item={item}
      isSaved={() => true}
      onOpenChange={opened => {
        if (opened) {
          setIsModalOpened(true);
        } else {
          handleCloseModal();
        }
      }}
      {...restProps}
      isOpen={isModalOpened}
      onClose={handleCloseModal}
    >
      <GeneralInfo cml={editedCml} readonly={!prms.cmls.update} onFieldUpdated={(f, v) => handleFieldUpdated('cml', f, v)} onAlarmChanged={saveAlarms} onFieldBlur={handleBlur}/>
      <div className='flex flex-col w-full overflow-auto'>
        {!!eventCml && <MeasurementBlock event={event!} readonly={!prms.cmls.measurement} cml={editedCml} eventCml={eventCml} onFieldUpdated={(f, v) => handleFieldUpdated('meas', f, v)} onFieldBlur={handleBlur}/>}
        <EventCmlHistory event={event} cml={editedCml!} eventCml={eventCml} onCreatedOrUpdated={onCreatedOrUpdated}/>
        <PictureCarousel readonly={!prms.cmls.update && !prms.cmls.measurement} cml={editedCml} event={event} onCarouselRender={handlePictureCarouselRender} onChanged={onPicturesChanged}/>
        <DeleteCmlModal open={deleteCmlModalOpen} cml={item} changeDeleteCmlModalDisplay={changeDeleteCmlModalDisplay} onCmlDeleted={handleCmlDeleted}/>
      </div>
    </MultiplePanelsModal>
  );
}
