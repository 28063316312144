import {useLazyQuery} from '@apollo/client';
import MeasurementValueContainer from '@app/components/Common/Block/ItemCard/MeasurementValueContainer';
import {EventInspectionPoint, GetLatestEventInspectionPointByInspIdQuery, GetLatestEventInspectionPointWithQualReadingNonEmptyByInspIdQuery, InspectionPoint, RefMeasPointValCode} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID, WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_INSP_ID} from '@app/graphql/requests';
import useInspectionPointStore from '@app/stores/insp';
import {QUALREADING_COLOR} from '@app/utils/constants';
import {isValidUnit} from '@app/utils/functions';
import {
  RadBadge,
  RadCard,
  RadCardContent,
  RadContextMenu,
  RadContextMenuContent,
  RadContextMenuItem,
  RadContextMenuTrigger,
} from '@holis/react-ui/rad';
import {MouseEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuCamera, LuExternalLink} from 'react-icons/lu';

type TInspectionPointItem = Readonly<{
  inspectionPoint: Partial<InspectionPoint>;
  eventInspectionPoint?: Partial<EventInspectionPoint>;
  readonly?: boolean;
  hasMenuContext?: boolean;
  onClick?: (e: MouseEvent) => void;
}>;

export default function InspectionPointItem({inspectionPoint, eventInspectionPoint, hasMenuContext, onClick}: TInspectionPointItem) {
  const [evtInsp, setEvtInsp] = useState<Partial<EventInspectionPoint | null>>();
  const {setActiveInspectionPoint} = useInspectionPointStore();
  const [getLatestEventCmlByCmlIdApi] = useLazyQuery<GetLatestEventInspectionPointByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_BY_INSP_ID);
  const [qualReading, setQualReading] = useState<Partial<RefMeasPointValCode> | null>();
  const [getLatestEventInspWithQualReadingNonEmptyByInspIdApi] = useLazyQuery<GetLatestEventInspectionPointWithQualReadingNonEmptyByInspIdQuery>(WORKORDER_EVENT_INSPECTION_POINTS_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_INSP_ID);

  const {t} = useTranslation();

  useEffect(() => {
    if (!eventInspectionPoint) {
      getLatestEventCmlByCmlIdApi({
        variables: {
          inspId: inspectionPoint.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => setEvtInsp(queryResult.data?.findFirstEventInspectionPoint as Partial<EventInspectionPoint>)).catch(setEvtInsp);
    } else {
      setEvtInsp(eventInspectionPoint);
    }
  }, [eventInspectionPoint]);

  useEffect(() => {
    if (typeof evtInsp !== 'undefined') {
      setQualReading(evtInsp?.qualReading ?? null);
    }
  }, [evtInsp]);

  useEffect(() => {
    if (!eventInspectionPoint && evtInsp?.id && (!evtInsp?.qualReading || evtInsp.qualReading.codeCond === '00')) {
      getLatestEventInspWithQualReadingNonEmptyByInspIdApi({
        variables: {
          inspId: inspectionPoint!.id,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        if (queryResult.data?.lastEventInspectionPointWithQualReading) {
          setQualReading((queryResult.data?.lastEventInspectionPointWithQualReading as EventInspectionPoint).qualReading ?? null);
        }
      });
    }
  }, [evtInsp]);

  return (
    <RadContextMenu key={`inspection-point-${inspectionPoint.id}`}>
      <RadContextMenuTrigger disabled={!hasMenuContext}>
        <RadCard className={'rounded-md' + (hasMenuContext ? ' cursor-pointer hover:bg-muted' : '')}>
          <RadCardContent className='flex p-2 gap-2 items-center' onClick={hasMenuContext && onClick ? onClick : undefined}>

            <div className='self-stretch rounded-md bg-blue-50 p-2 min-w-14 text-center'>
              <div className='text-primary font-extrabold'>{inspectionPoint?.class?.class}</div>
              <div className='font-extrabold'>{inspectionPoint?.position}</div>
            </div>

            <div className='flex-1 flex flex-col gap-1'>
              <div className='font-bold text-sm'>{inspectionPoint?.description}</div>
              <div className='font-medium text-xs mt-1 mb-1'>
                <RadBadge className='bg-gray-400 hover:bg-gray-400 py-0'>{inspectionPoint.technique?.technique ?? '-'}</RadBadge>
    &nbsp; | &nbsp;
                {inspectionPoint?.functionalLocation?.floc}
              </div>
            </div>

            <div className='flex gap-2 items-center self-stretch'>
              {(!!inspectionPoint._count?.pictures || !!eventInspectionPoint?.inspectionPoint?._count?.pictures) && (
                <LuCamera className='text-primary' size={20}/>
              )}
              <MeasurementValueContainer
                className='self-stretch'
                measurementValue={isValidUnit(inspectionPoint.char?.unit) && typeof evtInsp?.quantReading === 'string' && evtInsp?.quantReading !== '' ? evtInsp?.quantReading : qualReading?.valCode} measurementColor={(qualReading?.integrityCondition?.color ?? QUALREADING_COLOR) as string}/>
            </div>

          </RadCardContent>
        </RadCard>
      </RadContextMenuTrigger>

      <RadContextMenuContent>
        <RadContextMenuItem onClick={() => setActiveInspectionPoint(inspectionPoint)}>
          <LuExternalLink className='mr-2'/>
          {t('label.open')}
        </RadContextMenuItem>
      </RadContextMenuContent>
    </RadContextMenu>
  );
}
