import {Grid, TGridProps} from '@app/components/Common/Grid';
import RoleModal from '@app/components/Modal/Admin/Role';
import {Role} from '@app/graphql/__types__/graphql';
import useUsersStore, {RoleWithUsers} from '@app/stores/users';
import {RadButton} from '@holis/react-ui/rad';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';

export default function Roles() {
  const {roles, editingRole, setEditingRole} = useUsersStore();
  const {t} = useTranslation();

  const getPermissionsFormatted = (perms: string) => {
    let permsObj: Record<string, boolean>;
    try {
      permsObj = JSON.parse(perms) as Record<string, boolean>;
    } catch (e) {
      console.error('Error parsing permissions', perms);
      return '- parsing error -';
    }

    return (
      Object.keys(permsObj)
        .filter(perm => permsObj[perm])
        .join(', ')
    );
  };

  const gridProps: TGridProps = useMemo(() => ({
    columns: [
      {
        field: 'action',
        title: 'action',
        type: 'string',
        titleHidden: true,
        cellRenderer: 'action',
        filter: false,
      },
      {
        field: 'role',
        title: 'Role',
        type: 'string',
        width: 120,
      },
    ],
    cellRenderers: {
      action: (_val: string, role: unknown) => (
        <RadButton
          size='icon'
          variant='outline'
          className='size-fit p-1'
          onClick={() => setEditingRole(role as Role)}
        >
          <MdOpenInNew size={18}/>
        </RadButton>
      ),
      permissions(_val: unknown, r: unknown) {
        const role = r as Role;
        const permissions = role.permissions ? [...role.permissions].sort((pa, pb) => pa.resource.localeCompare(pb.resource)) : [];
        return (
          <div>
            {permissions?.sort((pa, pb) => pa.resource.localeCompare(pb.resource)).map(permission => (
              <div key={permission.resource}>
                <span className='font-bold'>{permission.resource}:</span>
                <span className='ml-1'>
                  {getPermissionsFormatted(permission.permissions)}
                </span>
              </div>
            ))}
          </div>
        );
      },
    },
  }), [roles]);

  const columnsAlwaysVisible: TNestedKeys<RoleWithUsers>[] = useMemo(() => ([
    'action',
  ]), [roles]);

  return (
    <div className='h-full'>
      <Grid
        gridName='admin-rolesGrid'
        queryData={{
          data: roles ?? [],
          loading: !roles,
        }}
        gridProps={gridProps}
        columnsAlwaysVisible={columnsAlwaysVisible}
      >
        <RadButton
          size='sm'
          className='flex gap-1 text-sm'
          onClick={() => setEditingRole({id: -1, role: '', permissions: []})}
        >
          <LuPlusCircle/> {t('label.addRole')}
        </RadButton>
      </Grid>

      {editingRole && <RoleModal open onClose={() => setEditingRole(undefined)}/>}
    </div>
  );
}
