import {useLazyQuery} from '@apollo/client';
import AppGrid from '@app/components/Common/AppGrid';
import DamageModal from '@app/components/Common/Block/Damage/DamageModal';
import {Damage, DamageView, GetAllDamageViewsQuery, GetDamageDetailByIdQuery} from '@app/graphql/__types__/graphql';
import {DAMAGE_VIEWS_GET_ALL, DAMAGES_GET_DETAIL_BY_ID} from '@app/graphql/requests';
import {useDamagesStore} from '@app/stores/anomalyManagement/damage';
import useDamageStore from '@app/stores/damage';
import {useDataStore} from '@app/stores/data';
import {FORMAT_DATE_DEFAULT, GRID_DEFAULT_LAYOUT_DMG} from '@app/utils/constants';
import {textEllipsisCellRenderer, textEllipsisWrapper} from '@app/utils/functions';
import {ensureDefaultGridLayout} from '@app/utils/functions/layout';
import {CellRenderer, GridProps} from '@holis/react-ui';
import {RadButton} from '@holis/react-ui/rad';
import moment from 'moment';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {MdOpenInNew} from 'react-icons/md';
import {useNavigate} from 'react-router-dom';

const GRID_NAME = 'anomalyManagement-damageGrid';

export default function AnomalyManagementDamagePage() {
  const {setDamageViews: setDamages, setFetchDamages, damageViews: damages, fetchDamages} = useDamagesStore();
  const {setActiveDamage, activeDamage} = useDamageStore();
  const {selectedSites} = useDataStore();
  const {t} = useTranslation();
  const [getDamagesApi, {loading, error}] = useLazyQuery<GetAllDamageViewsQuery>(DAMAGE_VIEWS_GET_ALL);
  const [getDamageByIdApi] = useLazyQuery<GetDamageDetailByIdQuery>(DAMAGES_GET_DETAIL_BY_ID);
  const navigate = useNavigate();

  const getDamages = () => {
    if (selectedSites?.length) {
      getDamagesApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setDamages(queryResults.data?.damageViews));
    } else {
      setDamages([]);
    }
  };

  const onOpenDamageClick = (id: number) => {
    getDamageByIdApi({
      variables: {
        id,
      },
    }).then(res => {
      setActiveDamage(res.data?.damage as Partial<Damage>);
    });
  };

  useEffect(() => {
    getDamages();
    setFetchDamages(getDamages);
  }, [selectedSites]);

  const actionCellRenderer: CellRenderer<Partial<DamageView>> = (_, node) => (
    <RadButton
      size='icon'
      variant='outline'
      className='size-fit p-1'
      onClick={() => onOpenDamageClick(node.data.id!)}
    >
      <MdOpenInNew size={18}/>
    </RadButton>
  );

  const gridProps: GridProps<Partial<DamageView>> = {
    columns: [
      {
        field: undefined,
        title: t('label.action'),
        defaultState: {
          width: 75,
        },
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: true,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          width: 101,
          hidden: true,
        },
      },
      {
        field: 'floc_floc',
        title: t('label.functionalLocation'),
        type: 'string',
        defaultState: {
          width: 383,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'position',
        title: t('label.position'),
        type: 'string',
        defaultState: {
          width: 100,
        },
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {
          width: 418,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'notif_notif',
        title: t('label.IAN'),
        type: 'string',
        defaultState: {
          width: 186,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'codeGroup_class_class',
        title: t('label.class'),
        type: 'set',
        defaultState: {
          width: 156,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_sector_site_site',
        title: t('label.site'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_sector_sector',
        title: t('label.sector'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'codeGroup_codeGroup',
        title: t('label.codeGroup'),
        type: 'set',
        defaultState: {
          width: 156,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'part_part',
        title: t('label.part'),
        type: 'set',
        defaultState: {
          width: 156,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'grid_grid',
        title: t('label.grid'),
        type: 'string',
        defaultState: {
          width: 156,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'accessibility_code',
        title: t('label.accessibility'),
        type: 'set',
        defaultState: {
          width: 156,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'notif_externalId',
        title: t('label.notification'),
        type: 'string',
        defaultState: {
          width: 156,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'notif_externalStatus',
        title: t('label.notificationStatus'),
        type: 'set',
        defaultState: {
          width: 156,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'flagStatus',
        title: t('label.status'),
        type: 'set',
        defaultState: {
          width: 80,
          hidden: true,
        },
        cellRenderer: 'flagStatus',
      },
      {
        field: 'idwg_idwg',
        title: t('label.idwg'),
        type: 'string',
        defaultState: {
          width: 201,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_techClass_class_class',
        title: t('label.technicalClass'),
        type: 'set',
        defaultState: {
          width: 100,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'display2d',
        title: t('label.2d'),
        defaultState: {
          width: 80,
          hidden: true,
        },
        type: 'string',
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'display3d',
        title: t('label.3d'),
        defaultState: {
          width: 80,
          hidden: true,
        },
        type: 'string',
        cellRenderer: textEllipsisCellRenderer,
      },
    ],
    cellRenderers: {
      flagStatus: (val: string) => textEllipsisWrapper(val ? t(`label.flagStatus.${val}`) : ''),
      date: (val: string) => textEllipsisWrapper(val ? moment(val).format(FORMAT_DATE_DEFAULT) : ''),
    },
    data: damages ?? [],
  };

  useEffect(() => {
    // Open damage modal if query param is present
    if (!damages) {
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    let dmg: Partial<DamageView> | undefined;
    if (queryParams.has('opendamage')) {
      dmg = damages.find(dmg => dmg.id === Number(queryParams.get('opendamage')));
    }

    if (dmg) {
      setActiveDamage(dmg as Damage);
    }

    if (queryParams.has('opendamage')) {
      queryParams.delete('opendamage');
      navigate({search: queryParams.toString()});
    }
  }, [damages]);

  useEffect(() => {
    ensureDefaultGridLayout(GRID_NAME, GRID_DEFAULT_LAYOUT_DMG);
  }, []);

  return (
    <div className='h-full'>
      {(error && <div>{error.message}</div>) || <AppGrid
        name={GRID_NAME}
        fileNameOnExport={t('label.damage')}
        isLoading={loading}
        {...gridProps}
        title={t('label.damage')}
      />}

      {!!activeDamage && <DamageModal confirmOnExitNoSave isOpen item={activeDamage} onCreatedOrUpdated={fetchDamages}/>}
    </div>
  );
}
