import {InspectionPoint} from '@app/graphql/__types__/graphql';
import {OBJ_NEW_ID} from '@app/utils/constants';
import React from 'react';
import IdText from '@app/components/Common/Text/IdText';
import {tailwindColorToBgFgStyle} from '@app/utils/functions';
import {RadBadge, RadButton} from '@holis/react-ui/rad';
import {useTranslation} from 'react-i18next';
import {LuXCircle} from 'react-icons/lu';
type TFooter = Readonly<{
    inspectionPoint: Partial<InspectionPoint>;
    onCancelClick?: () => void;
}>

export default function Footer({inspectionPoint, onCancelClick}: TFooter) {
  const {t} = useTranslation();
  return (
    <div className='flex w-full h-full justify-between font-normal items-center px-2 pt-2'>
      <div className='flex items-center gap-2 h-full'>
        {inspectionPoint.id !== OBJ_NEW_ID && (
          <>
            <RadBadge variant='outline' className='py-1 font-normal' style={tailwindColorToBgFgStyle(inspectionPoint.statusObject?.displayColor)}>{inspectionPoint.statusObject?.description ?? '-'}</RadBadge>
            <IdText id={inspectionPoint.id}/>
          </>
        )}
      </div>
      <div className='flex gap-2'>
        <RadButton variant='outline' className='gap-2' onClick={onCancelClick}><LuXCircle/> {t('label.close')}</RadButton>
      </div>
    </div>
  );
}
