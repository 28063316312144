import {Plan, PlanView} from '@app/graphql/__types__/graphql';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
  plans?: Partial<Plan>[];
  planViews?: Partial<PlanView>[];
  fetchPlans?: () => void;
};

const initialState: State = {
  plans: undefined,
};

type Actions = {
  setPlans: (plans?: Partial<Plan>[]) => void;
  setPlanViews: (planViews?: Partial<PlanView>[]) => void;
  setFetchPlans: (fetchPlans?: () => void) => void;
};

type SchedulingInspectionPlanState = State & Actions;

const useSchedulingInspectionPlanStore = create<SchedulingInspectionPlanState>()(
  immer(set => ({
    ...initialState,
    setPlans(plans) {
      set({plans});
    },
    setPlanViews(planViews) {
      set({planViews});
    },
    resetData() {
      set({
        ...initialState,
      });
    },
    setFetchPlans(fetchPlans) {
      set({fetchPlans});
    },
  })),
);

export default useSchedulingInspectionPlanStore;
