import {useQuery} from '@apollo/client';
import BorderedTable from '@app/components/Common/TreeList/BorderedTable';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import {GetAllPlanStatusesQuery, GetPlanWorkflowsByPlanIdQuery, Plan, PlanWorkflow} from '@app/graphql/__types__/graphql';
import {APP_PLAN_STATUSES_GET_MANY, SCHEDULING_PLAN_WORKFLOWS_GET_BY_PLAN_ID} from '@app/graphql/requests';
import {FORMAT_DATETIME} from '@app/utils/constants';
import {IColumn, TDataRowTreeList} from '@holis/react-ui';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import usePlanStore from '@app/stores/plan';
import {useMemo} from 'react';
import SingleContentModal from '@app/components/Modal/SingleFormModal';

type TPlanWorkflowTable = Readonly<IConfirmModal & {
  plan: Partial<Plan>;
}>;

export default function PlanWorkflowTableModal({plan, ...restProps}: TPlanWorkflowTable) {
  const {t} = useTranslation();
  const {changeWorkflowModalDisplay} = usePlanStore();
  const {data, loading, error} = useQuery<GetPlanWorkflowsByPlanIdQuery>(SCHEDULING_PLAN_WORKFLOWS_GET_BY_PLAN_ID, {
    variables: {
      planId: plan.id,
    },
    fetchPolicy: 'no-cache',
  });

  const planStatusesResult = useQuery<GetAllPlanStatusesQuery>(APP_PLAN_STATUSES_GET_MANY);

  const cellRenderers = useMemo(() => ({
    date(_val: string, rowData: unknown) {
      const dataRow = (rowData as Partial<PlanWorkflow>);
      return <span>{dataRow.actionDate ? moment(dataRow.actionDate).format(FORMAT_DATETIME) : ''}</span>;
    },
    status(val: string) {
      return <>{val.replace(/\|/g, ' => ')}</>;
    },
  }), [planStatusesResult]);

  const columns: IColumn[] = [
    {
      field: 'actionDate',
      title: t('label.date'),
      type: 'string',
      cellRenderer: 'date',
      width: 100,
      filter: false,
    },
    {
      field: 'status',
      title: t('label.status'),
      type: 'string',
      width: 120,
      filter: false,
      cellRenderer: 'status',
    },
    {
      field: 'description',
      title: t('label.description'),
      type: 'string',
      width: 120,
      filter: false,
    },
    {
      field: 'userLogin',
      title: t('label.user'),
      type: 'string',
      width: 140,
      filter: false,
    },
  ];

  const handleCloseModal = () => {
    changeWorkflowModalDisplay(false);
  };

  return (
    <SingleContentModal
      containerClassName='max-w-[1400px] min-w-[200px] max-h-[90%] h-full'
      isOpen={restProps.open}
      title={`${t('label.workflowForPlan')}: ${plan.plan}`}
      onOpenChange={opened => {
        if (!opened) {
          handleCloseModal();
        }
      }}>
      <BorderedTable
        hasSearchBar={false}
        listProps={{
          columns,
          isLoading: loading,
          error: !!error,
          data: (data?.planWorkflows ?? []) as unknown as TDataRowTreeList[],
          cellRenderers,
        }}/>
    </SingleContentModal>
  );
}
