import {useLazyQuery} from '@apollo/client';
import SelectionConfirmModal, {TSelectionConfirmModalWithoutListProps} from '@app/components/Modal/Confirm/Selection';
import {EventView, GetAllUserSitesQuery, GetRegisteredUsersQuery, UserInfo, UserSite} from '@app/graphql/__types__/graphql';
import {USER_SITES_GET_ALL} from '@app/graphql/requests';
import {USER_GET_REGISTERED} from '@app/graphql/requests/usersRoles';
import {TDataRowTreeList} from '@holis/react-ui';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

type TUsersToSendReportModal = TSelectionConfirmModalWithoutListProps & Readonly<{
  event: Partial<EventView>;
}>;

export default function UsersToSendReportModal({event, ...restProps}: TUsersToSendReportModal) {
  const [users, setUsers] = useState<GetRegisteredUsersQuery['userRoles']>();
  const [getRegisteredUsers] = useLazyQuery<GetRegisteredUsersQuery>(USER_GET_REGISTERED);
  const [getAllUserSites] = useLazyQuery<GetAllUserSitesQuery>(USER_SITES_GET_ALL);
  const {t} = useTranslation();
  const getAppUsers = async () => {
    const users = (await getRegisteredUsers()).data?.userRoles;
    const userSites = (await getAllUserSites()).data?.userSites as UserSite[];
    const filteredUsers = users?.filter(user => {
      const sites = userSites.filter(userSite => userSite.userId === user.userId).map(userSite => userSite.siteId);
      return !event.schedulingTag_sector_site_id || sites.includes(event.schedulingTag_sector_site_id);
    });

    setUsers(filteredUsers ?? []);
  };

  useEffect(() => {
    getAppUsers();
  }, []);

  const usersAndInfosWithId = users?.map(user => ({...user, ...user.userInfo, id: user.userId, notifyNewReport: (user.userInfo as UserInfo).userRole?.notifyNewReport}));

  return (
    <SelectionConfirmModal
      title={t('label.users')}
      description={t('label.selectUsersToSendReport')}
      validateBtnLabel={t('label.publish')}
      items={usersAndInfosWithId ?? []}
      selectedIds={usersAndInfosWithId?.filter(u => !!u.notifyNewReport).map(u => u.userId)}
      listProps={{
        listProps: {
          isLoading: !users,
          data: (usersAndInfosWithId ?? []) as unknown as TDataRowTreeList[],
          columns: [
            {
              field: 'action',
              title: 'action',
              type: 'string',
              titleHidden: true,
              cellRenderer: 'action',
              filter: false,
            },
            {
              field: 'username',
              title: t('label.username'),
              type: 'string',
              width: 200,
              sort: 'asc',
            },
            {
              field: 'email',
              title: t('label.email'),
              type: 'string',
              width: 300,
            },
            {
              field: 'firstName',
              title: t('label.firstName'),
              type: 'string',
              width: 200,
            },
            {
              field: 'lastName',
              title: t('label.lastName'),
              type: 'string',
              width: 200,
            },
          ],
        },
      }}
      {...restProps}
    />
  );
}
