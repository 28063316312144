import React, {PropsWithChildren, ReactElement, useRef} from 'react';

import {GridPageContent, TDataRowTreeList, TreeList, TTreeList} from '@holis/react-ui';
import {addRadSon} from '@holis/react-ui/rad';
import * as XLSX from 'xlsx';

import './grid.css';
import TNestedKeys from 'node_modules/@holis/react-ui/dist/src/components/List/TreeList/types/TNestedKeys';
import {useTranslation} from 'react-i18next';
import {renderToString} from 'react-dom/server';
import {fitToColumnExcel} from '@app/utils/functions';

export type TGridProps<TRow extends TDataRowTreeList = TDataRowTreeList> = Partial<TTreeList<TRow>>;
type TQueryData = {
  data: unknown[];
  loading: boolean;
  error?: Error | null;
};

type TProps<TRow extends TDataRowTreeList = TDataRowTreeList> = Readonly<{
  /** Unique name for the session/local storage of this specific grid instance. */
  readonly gridName: string;
  readonly queryData: TQueryData;
  readonly gridProps: TGridProps<TRow>;
  readonly title?: React.ReactNode;
  readonly columnsAlwaysVisible?: TNestedKeys<TRow>[];
}> & PropsWithChildren;

export function Grid(props: TProps) {
  const {t} = useTranslation();
  const treeListApi = useRef<TreeList['api'] | null>(null);
  const onSaveLayout = () => {
    addRadSon.success(t('message.success.layoutSaved'));
  };

  const onResetLayout = () => {
    addRadSon.success(t('message.success.defaultLayoutRestored'));
  };

  const reactElementToText = (element: ReactElement) => {
    const text = renderToString(element);
    return text.replace(/<[^>]*>?/gm, '');
  };

  const exportExcel = () => {
    const currentState = treeListApi?.current?.getCurrentState();
    if (!currentState) {
      return;
    }

    const {columns} = currentState;
    const shownColumns = columns.filter(col => !col.hidden && !('excelRenderer' in col && col.excelRenderer === false) && col.cellRenderer !== 'action');
    const wb = XLSX.utils.book_new();
    const excelData: (string|number)[][] = [];
    const headerTitles = shownColumns.map(col => col.title);
    excelData.push(headerTitles as (string | number)[]);
    const datas = (props.queryData?.data ?? props.gridProps.data) as TDataRowTreeList[];
    datas.forEach(row => {
      if (!row.filtered) {
        const rowData = row.data ?? row;
        if (!rowData) {
          return;
        }

        const excelRowData = shownColumns.map(col => {
          if ('excelRenderer' in col && typeof col.excelRenderer === 'function') {
            return col.excelRenderer(rowData);
          }

          if (col.cellRenderer && typeof props.gridProps.cellRenderers?.[col.cellRenderer] === 'function') {
            const node = props.gridProps.cellRenderers?.[col.cellRenderer](rowData[col.field], rowData);
            if (node) {
              return reactElementToText(node as ReactElement);
            }
          }

          return rowData[col.field];
        });
        excelData.push(excelRowData);
      }
    });
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    ws['!cols'] = fitToColumnExcel(excelData);
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFileXLSX(wb, 'exports.xlsx');
  };

  return (
    <GridPageContent
      disableFooter
      defaultColsFit='container'
      gridName={props.gridName}
      queryData={props.queryData}
      gridProps={{...props.gridProps, showFooter: true}}
      actions={props.children}
      title={props.title}
      columnsAlwaysVisible={props.columnsAlwaysVisible}
      onTreelistRender={api => {
        treeListApi.current = api;
      }}
      onSaveLayout={onSaveLayout}
      onResetLayout={onResetLayout}
      onExport={exportExcel}
    />
  );
}
