import {useLazyQuery, useMutation} from '@apollo/client';
import AppGrid from '@app/components/Common/AppGrid';
import PlanModal from '@app/components/Modal/Scheduling/Plan';
import {GetAllPlanViewsQuery, PlanView, GeneratePlanReportsMutation} from '@app/graphql/__types__/graphql';
import {SCHEDULING_PLAN_VIEWS_GET_ALL, SCHEDULING_PLANS_GENERATE_REPORTS} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useDataStore} from '@app/stores/data';
import {useLayoutStore} from '@app/stores/layout';
import usePlanStore from '@app/stores/plan';
import useSchedulingInspectionPlanStore from '@app/stores/scheduling/inspectionPlan';
import {FORMAT_DATE_DEFAULT, OBJ_NEW_ID, ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL} from '@app/utils/constants';
import {ESCHEDULING_PLAN_STATUS} from '@app/utils/enums';
import {generatePathWithBaseUrl, tailwindColorToBgFgStyle, textEllipsisCellRenderer, textEllipsisWrapper} from '@app/utils/functions';
import {CellRenderer, GridProps} from '@holis/react-ui';
import {RadBadge, RadButton, RadCheckbox} from '@holis/react-ui/rad';
import moment from 'moment';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {LuBan, LuFolderCheck, LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {generatePath, useNavigate} from 'react-router-dom';

export default function SchedulingInspectionPlanPage() {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {setActivePlan, creatingPlan} = usePlanStore();
  const {planViews, setPlanViews, setFetchPlans} = useSchedulingInspectionPlanStore();
  const {t} = useTranslation();
  const [getPlansApi, {loading, error}] = useLazyQuery<GetAllPlanViewsQuery>(SCHEDULING_PLAN_VIEWS_GET_ALL);
  const [generateReportInProgress, setGenerateReportInProgress] = useState<boolean>(false);
  const [selectedPlans, setSelectedPlans] = useState<number[]>([]);
  const {stopLoading, startLoading} = useLayoutStore();
  const [generatePlanReportsApi] = useMutation<GeneratePlanReportsMutation>(SCHEDULING_PLANS_GENERATE_REPORTS);

  const selectUnselectPlan = (id: number) => {
    if (selectedPlans.includes(id)) {
      setSelectedPlans(selectedPlans.filter(eid => eid !== id));
    } else {
      setSelectedPlans([...selectedPlans, id]);
    }
  };

  const cancelGeneratePlanReports = () => {
    setSelectedPlans([]);
    setGenerateReportInProgress(false);
  };

  const generateReports = () => {
    startLoading();
    generatePlanReportsApi({
      variables: {
        ids: selectedPlans,
      },
    }).then(queryResult => {
      if (queryResult.data?.generatePlanReports) {
        AppNotifications.success(t('message.report.generation.requestsSaved'));
        cancelGeneratePlanReports();
      } else {
        AppNotifications.error(t('message.error.default.title'));
      }
    }).catch(() => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(stopLoading);
  };

  const getPlans = () => {
    getPlansApi({
      variables: {
        sites: selectedSites,
        orderBy: [
          {
            id: 'desc',
          },
        ],
      },
      fetchPolicy: 'no-cache',
    }).then(queryResult => setPlanViews(queryResult.data?.planViews));
  };

  const handleNewPlan = () => {
    setActivePlan({
      id: OBJ_NEW_ID,
      status: ESCHEDULING_PLAN_STATUS.INIT,
    }, true);
  };

  useEffect(() => {
    getPlans();
    setFetchPlans(getPlans);
  }, [selectedSites]);

  const actionCellRenderer: CellRenderer<Partial<PlanView>> = (_, node) => (
    <div className='!flex gap-4 items-center'>
      {generateReportInProgress && <RadCheckbox
        aria-label='Select row'
        className='w-4 h-4 align-bottom'
        checked={selectedPlans.includes(node.data.id!)}
        onClick={() => {
          selectUnselectPlan(node.data.id!);
        }}
      >
        <MdOpenInNew size={18}/>
      </RadCheckbox>}
      <RadButton
        size='icon'
        variant='outline'
        className='size-fit p-1'
        onClick={e => {
          const planNumber = node.data.plan;
          if (e.ctrlKey || e.metaKey) {
            window.open(generatePathWithBaseUrl(generatePath(ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL.replace(':number', planNumber!))), '_blank');
            return;
          }

          return navigate(ROUTE_SCHEDULING_INSPECTION_PLAN_DETAIL.replace(':number', planNumber!));
        }}
      >
        <MdOpenInNew size={18}/>
      </RadButton>
    </div>
  );

  const gridProps: GridProps<Partial<PlanView>> = {
    columns: [
      {
        field: undefined,
        title: '',
        defaultState: {
          width: 60,
        },
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: false,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          width: 80,
        },
      },
      {
        field: 'status',
        title: t('label.status'),
        type: 'set',
        defaultState: {
          width: 150,
        },
        cellRenderer: 'status',
        cannotHide: false,
      },
      {
        field: 'plan',
        title: t('label.plan'),
        type: 'string',
        defaultState: {
          width: 150,
        },
        cellRenderer: 'bold',
        cannotHide: false,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {
          width: 289,
        },
        cannotHide: false,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cannotHide: false,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'revision',
        title: t('label.revision'),
        type: 'string',
        defaultState: {
          width: 60,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'strategy_strategy',
        title: t('label.strategy'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'plannerGroup_code',
        title: t('label.plannerGroup'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'mainWorkCenter_code',
        title: t('label.mainWorkCenter'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'startDate',
        title: t('label.startDate'),
        type: 'date',
        defaultState: {
          width: 100,
        },
        cellRenderer: 'startDate',
      },
      {
        field: 'schedulingUnit',
        title: t('label.schedulingUnit'),
        type: 'set',
        defaultState: {
          width: 100,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'notes',
        title: t('label.notes'),
        type: 'string',
        defaultState: {
          width: 200,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_floc',
        title: t('label.schedulingTag'),
        type: 'string',
        defaultState: {
          width: 294,
        },
        cannotHide: false,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_description',
        title: t('label.schedulingTagDescription'),
        type: 'string',
        defaultState: {
          width: 445,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_techClass_techClass',
        title: t('label.technicalClass'),
        type: 'set',
        defaultState: {
          width: 150,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_techClass_class_class',
        title: t('label.class'),
        type: 'set',
        defaultState: {
          width: 150,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_classSece',
        title: t('label.classSece'),
        type: 'set',
        defaultState: {
          width: 150,
          hidden: true,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_sector_site_site',
        title: t('label.site'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_sector_sector',
        title: t('label.sector'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_docName',
        title: t('label.referenceDocument'),
        type: 'string',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'floc_grid_grid',
        title: t('label.grid'),
        type: 'string',
        defaultState: {
          width: 100,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
    ],
    cellRenderers: {
      bold: val => textEllipsisWrapper(<span className='font-bold'>{val ?? ''}</span>),
      startDate: val => textEllipsisWrapper(val ? moment(val).format(FORMAT_DATE_DEFAULT) : ''),
      status(val, node) {
        if (!val) {
          return '';
        }

        const viewDatas = node.data;

        return (
          <RadBadge variant='outline' className='py-1 font-normal text-nowrap min-w-[100px] justify-center max-w-full' style={tailwindColorToBgFgStyle(viewDatas.status_displayColor)} title={viewDatas.status_description as string}>
            <div className='text-ellipsis overflow-hidden'>
              {viewDatas.status_description}
            </div>
          </RadBadge>
        );
      },
    },
    data: planViews ?? [],
  };

  return (
    <div className='h-full'>
      {creatingPlan && <PlanModal
        isOpen={creatingPlan} onOpenChange={isOpen => {
          if (!isOpen) {
            setActivePlan(undefined);
          }
        }}
      />}

      {(error && <div>{error.message}</div>) || <AppGrid
        name='scheduling-inspectionPlansGrid'
        title={t('label.inspectionPlan')}
        isLoading={loading}
        {...gridProps}
        actions={<>
          {!generateReportInProgress && <RadButton className='h-8 rounded-md px-3 gap-2 text-sm' onClick={() => setGenerateReportInProgress(true)}><LuFolderCheck/> {t('label.generatePlanReports')}</RadButton>}
          {
            generateReportInProgress && (
              <>
                <RadButton
                  size='sm'
                  className='flex gap-1 text-sm'
                  disabled={!selectedPlans.length}
                  onClick={generateReports}
                >
                  <LuFolderCheck/> {t('label.generateCountPlanReports', {count: selectedPlans.length})}
                </RadButton>
                <RadButton
                  size='sm'
                  variant='secondary'
                  className='flex gap-1 text-sm'
                  onClick={() => {
                    cancelGeneratePlanReports();
                  }}>
                  <LuBan/> {t('label.cancelPlanReportGeneration')}
                </RadButton>
              </>
            )
          }
          {!generateReportInProgress && <RadButton
            size='sm'
            className='flex gap-1 text-sm'
            onClick={handleNewPlan}
          >
            <LuPlusCircle/> Add plan
          </RadButton>}
        </>}
      />}
    </div>
  );
}
