import {FetchResult, useMutation, useQuery} from '@apollo/client';
import ElevatedButton from '@app/components/Common/Button/ElevatedButton';
import AppAutocomplete from '@app/components/Common/Form/Autocomplete';
import FormFieldsBlock from '@app/components/Common/Form/FormFieldsBlock';
import EaseLinearTransition from '@app/components/Common/Trannsition/EaseLinearTransition';
import {Event, FunctionalLocation, GetAllRefDocsCatalogsQuery, GetAllRefDwgTypesQuery, GetAllSectorsQuery, IdwgFloc, IdwgGrid, InspectionDrawing, Notification, RefDwgType, Sector, UpdateInspectionDrawingByIdMutation} from '@app/graphql/__types__/graphql';
import {DOCUMENTATION_CATALOGS_GET_MANY, DWG_REF_TYPES_GET_MANY, INSPECTION_DRAWINGS_UPDATE_BY_ID, SECTORS_GET_ALL} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useDataStore} from '@app/stores/data';
import {useEventStore} from '@app/stores/event';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import {useLayoutStore} from '@app/stores/layout';
import {MAX_LENGTH_VALIDATORS, useInspectionDrawingStore} from '@app/stores/methodEngineering/inspectionDrawing';
import {TFieldsBlock, TMaybeCodeDescriptionDatas, TRenderAutocompleteProps} from '@app/types/app';
import {EDownloadFileType, EDrawingFileContentType, EFieldType, EImportService} from '@app/utils/enums';
import {getFileNameWithoutExtension, handleFormInputKeydown, renderCodeAndDescription, renderSectorLabel, setObjValueByPath} from '@app/utils/functions';
import {Button} from '@holis/react-ui';
import {t} from 'i18next';
import React, {InputHTMLAttributes, KeyboardEvent, TextareaHTMLAttributes, useEffect, useState} from 'react';
import {FiChevronsRight} from 'react-icons/fi';
import styled from 'styled-components';
import FlocList from '../Floc/FlocList';
import GridList from '../Grid/GridList';
import BottomActions from './BottomActions';
import {OptimusClientConfig} from '@app/utils/clientConfig';

const StyledContainer = styled(EaseLinearTransition)`

`;

type TInspectionInfo = Readonly<{
  isShowing?: boolean;
  idwg: Partial<InspectionDrawing>;
  objectItem?: Partial<Event | Notification | FunctionalLocation>;
}>;

export default function InspectionInfo({isShowing, idwg, objectItem}: TInspectionInfo) {
  const {fetchEventInspectionDrawings} = useEventStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const {fetchInspectionDrawings} = useInspectionDrawingStore();
  const {hasFieldError, updateDataField, updateInspectionDrawing, editIdwg, setEditIdwg, fieldErrors, changeModalIdwgDetailShown, activeInspectionDrawing, idwgFlocs, idwgGrids} = useIdwgStore(`${IDWG_PREFIX}${idwg.id}`);
  const sectorsResult = useQuery<GetAllSectorsQuery>(SECTORS_GET_ALL);
  const refDwgTypesResult = useQuery<GetAllRefDwgTypesQuery>(DWG_REF_TYPES_GET_MANY);
  const fileRef = React.createRef<HTMLInputElement>();
  const [fieldBlocks, setFieldBlocks] = useState<TFieldsBlock[]>([]);
  const {uploadDownloadService} = useDataStore();
  const [updateIdwgById] = useMutation<UpdateInspectionDrawingByIdMutation>(INSPECTION_DRAWINGS_UPDATE_BY_ID);
  const onFileUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.[0]) {
      startLoading();
      const uploadFile = e.target.files[0];
      const docName = getFileNameWithoutExtension(uploadFile.name);
      uploadDownloadService!.uploadFile(uploadFile, {async: false}, EImportService.UPLOAD_DRAWING).then(response => {
        if (response.status !== 200) {
          const responseJson = JSON.parse(response.data);
          throw new Error(typeof responseJson === 'object' && uploadFile.name && responseJson.errors?.files?.[uploadFile.name] ? responseJson.errors.files![uploadFile.name] : response.data.message ?? response.statusText);
        } else if (idwg!.id) {
          updateIdwgById({variables: {id: idwg!.id, data: {docName: {set: docName}}}}).then(newData => {
            const newIdwg = {
              ...editIdwg,
              ...newData?.data?.updateOneInspectionDrawing,
            } as InspectionDrawing;
            updateInspectionDrawing(newIdwg);
            AppNotifications.success(t('message.success.drawingUpdated'));
            if (objectItem) {
              if (objectItem.__typename === 'Event') {
                fetchEventInspectionDrawings?.();
              }
            } else {
              fetchInspectionDrawings?.();
            }
          });
        } else {
          setEditIdwg({
            ...editIdwg,
            docName,
          });
          updateDataField('docName', docName);
        }
      }).catch((_error: Error) => {
        AppNotifications.error(_error.message ?? t('message.error.uploadDocumentationIdwg'));
      }).finally(() => {
        stopLoading();
      });
    }
  };

  const refDocsCatalogsResult = useQuery<GetAllRefDocsCatalogsQuery>(DOCUMENTATION_CATALOGS_GET_MANY, {
    variables: {
      orderBy: [{
        code: 'asc',
      }],
      where: {
        isActive: {
          equals: true,
        },
      },
    },
  });
  const handleFieldChange = (field: string, value: unknown, update?: boolean) => {
    const editedIdwg = {...editIdwg};
    setObjValueByPath(editedIdwg, field, value);
    setEditIdwg(editedIdwg);
    if (update) {
      updateDataField(field, value);
    }
  };

  const closeIdwgDetailModal = () => {
    changeModalIdwgDetailShown(false);
  };

  const renderAutocomplete = (props: TRenderAutocompleteProps) : React.ReactNode => {
    const {fieldRow, setInputValue, renderMenuItemLabel, dbValue, field, foreignField, inputProps} = props ?? {};
    return (
      <AppAutocomplete
        onSelect={(item: Record<string, unknown> | null) => {
          if ((item?.id ?? null) !== dbValue) {
            if (!!item && typeof renderMenuItemLabel!(item) === 'string') {
              setInputValue?.(renderMenuItemLabel!(item) as string);
            }

            const editedIdwg = {...editIdwg};
            setObjValueByPath(editedIdwg, foreignField!, item);
            setObjValueByPath(editedIdwg, field!, item?.id ?? null);
            setEditIdwg(editedIdwg);
            updateDataField(field!, item?.id ?? null);
          }
        }}
        {...props}
        inputProps={{
          ...inputProps,
          onKeyDown: (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => handleFormInputKeydown(e, fieldRow!, val => setInputValue?.(val ?? ''), handleFieldChange),
        }}
      />
    );
  };

  useEffect(() => {
    if (editIdwg) {
      const BLOCK_IDENTIFICATION: TFieldsBlock = {
        title: 'label.drawingData',
        fields: [
          {
            label: 'label.reference',
            field: 'idwg',
            fieldType: EFieldType.text,
            initialValue: activeInspectionDrawing!.idwg,
            value: editIdwg.idwg,
            hasError: hasFieldError('idwg'),
            isRequired: true,
            inputProps: {
              maxLength: MAX_LENGTH_VALIDATORS.IDWG,
            } as InputHTMLAttributes<HTMLInputElement>,
          },
          {
            label: 'label.description',
            field: 'description',
            fieldType: EFieldType.text,
            initialValue: activeInspectionDrawing!.description,
            hasError: hasFieldError('description'),
            value: editIdwg.description,
            isRequired: true,
            inputComponent: 'textarea',
            inputProps: {
              rows: 2,
              maxLength: MAX_LENGTH_VALIDATORS.DESCRIPTION,
            } as TextareaHTMLAttributes<HTMLTextAreaElement>,
          },
          {
            label: 'label.type',
            field: 'typeId',
            initialValue: renderCodeAndDescription({code: activeInspectionDrawing!.type?.type, description: activeInspectionDrawing!.type?.description} as TMaybeCodeDescriptionDatas),
            value: renderCodeAndDescription({code: editIdwg!.type?.type, description: editIdwg!.type?.description} as TMaybeCodeDescriptionDatas),
            foreignObject: editIdwg.type,
            foreignField: 'type',
            dbValue: activeInspectionDrawing!.typeId as number,
            fieldType: EFieldType.autocomplete,
            itemsQueryResult: refDwgTypesResult,
            getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllRefDwgTypesQuery>)?.data?.refDwgTypes ?? [],
            renderMenuItemLabel: field => renderCodeAndDescription({code: (field as Partial<RefDwgType>).type, description: (field as Partial<RefDwgType>).description} as TMaybeCodeDescriptionDatas),
            renderInput: renderAutocomplete,
          },
          {
            label: 'label.site',
            field: 'site',
            isDisabled: true,
            value: editIdwg.sector?.site?.site,
          },
          {
            label: 'label.sector',
            field: 'sectorId',
            foreignField: 'sector',
            hasError: hasFieldError('sectorId'),
            foreignObject: editIdwg!.sector,
            initialValue: renderSectorLabel(activeInspectionDrawing!.sector),
            value: renderSectorLabel(editIdwg.sector),
            dbValue: activeInspectionDrawing!.sectorId,
            fieldType: EFieldType.autocomplete,
            itemsQueryResult: sectorsResult,
            getItemsFromResult: (result: FetchResult) => (result as FetchResult<GetAllSectorsQuery>)?.data?.sectors ?? [],
            renderMenuItemLabel: (field: unknown) => renderSectorLabel(field as Sector),
            renderInput: renderAutocomplete,
            isRequired: true,
          },
          {
            label: 'label.fileName',
            field: 'docName',
            hasError: hasFieldError('docName'),
            value: editIdwg.docName,
            isDisabled: true,
          },
        ],
      };

      const BLOCK_FLOCS: TFieldsBlock = {
        title: 'label.functionalLocationList',
        fields: [
          {
            label: false,
            field: 'idwgFlocs',
            value: idwgFlocs,
            renderContent: ({value}) => <FlocList idwg={idwg} flocs={(value as IdwgFloc[])?.map((idwgFloc: IdwgFloc) => idwgFloc.functionalLocation)}/>,
          },
        ],
      };

      const BLOC_GRID: TFieldsBlock = {
        title: 'label.grid',
        fields: [
          {
            label: false,
            field: 'idwgFlocs',
            value: idwgGrids,
            renderContent: ({value}) => <GridList idwg={idwg} grids={(value as IdwgGrid[])?.map((idwgGrid: IdwgGrid) => idwgGrid.grid)}/>,
          },
        ],
      };
      setFieldBlocks([BLOCK_IDENTIFICATION, BLOCK_FLOCS, BLOC_GRID]);
    }
  }, [editIdwg, idwgGrids, idwgFlocs, sectorsResult, fieldErrors, refDocsCatalogsResult]);
  return (
    <StyledContainer
      isShowing={isShowing}
      className='flex flex-col w-1/2 h-full absolute z-50 min-w-[200px] bg-white right-0 p-1 border-none shadow-md'
    >
      <div className='flex justify-between'>
        <ElevatedButton shapePreset={Button.ShapePresets.Square} className='ml-1 mt-1 bg-gray-200 p-1' onClick={closeIdwgDetailModal}><FiChevronsRight className='text-gray-700' size={20}/></ElevatedButton>
        <input ref={fileRef} accept='application/pdf,.pdf' className='hidden' type='file' onChange={onFileUploadChange}/>
      </div>
      <FormFieldsBlock blockClassName='pl-8' className='overflow-auto flex-grow' fieldsBlocks={fieldBlocks} onFieldChange={handleFieldChange}/>
      <BottomActions objectItem={objectItem} className='border-none' downloadFileName={activeInspectionDrawing?.docName ? `${activeInspectionDrawing?.docName}.pdf` : null} downloadUrl={activeInspectionDrawing?.docName ? `${OptimusClientConfig.current.fileBaseUrl}/${activeInspectionDrawing?.docName}?type=${EDownloadFileType.DRAWING}&contentType=${EDrawingFileContentType.PDF}` : null} idwg={idwg} fileRef={fileRef}/>
    </StyledContainer>
  );
}
