import {ResolvableTo, RecursiveKeyValuePair} from 'tailwindcss/types/config';
import colors from 'tailwindcss/colors';

export const MENU_GROUP_COLORS = [
  'bg-blue-400',
  'bg-green-400',
  'bg-orange-400',
  'bg-purple-400',
  'bg-pink-400',
  'bg-cyan-400',
  'bg-red-400',
  'bg-yellow-400',
];

export const twColors: ResolvableTo<RecursiveKeyValuePair<string, string>> = {
  border: 'hsl(var(--border))',
  input: 'hsl(var(--input))',
  ring: 'hsl(var(--ring))',
  background: 'hsl(var(--background))',
  foreground: 'hsl(var(--foreground))',
  primary: {
    DEFAULT: 'hsl(var(--primary))',
    foreground: 'hsl(var(--primary-foreground))',
  },
  secondary: {
    DEFAULT: 'hsl(var(--secondary))',
    foreground: 'hsl(var(--secondary-foreground))',
  },
  destructive: {
    DEFAULT: 'hsl(var(--destructive))',
    foreground: 'hsl(var(--destructive-foreground))',
  },
  muted: {
    DEFAULT: 'hsl(var(--muted))',
    foreground: 'hsl(var(--muted-foreground))',
  },
  accent: {
    DEFAULT: 'hsl(var(--accent))',
    foreground: 'hsl(var(--accent-foreground))',
  },
  popover: {
    DEFAULT: 'hsl(var(--popover))',
    foreground: 'hsl(var(--popover-foreground))',
  },
  card: {
    DEFAULT: 'hsl(var(--card))',
    foreground: 'hsl(var(--card-foreground))',
  },
};

export const CML_NOMINAL = colors.green[500];
export const CML_ALARM1 = colors.yellow[500];
export const CML_ALARM2 = colors.orange[500];
export const CML_ALARM3 = colors.red[500];
export const DEFAULT_SHAPE_COLOR = colors.gray[300];
export const QUALREADING_COLOR = colors.gray[200];
export const INTEGRITY_CONDITION_COLOR = colors.gray[200];
