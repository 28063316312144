import {renderToString} from 'react-dom/server';
import {ReactElement} from 'react';
import {utils} from '@holis/react-ui';
import _ from 'lodash';
import {APP_QUERY_ACCESS_TOKEN} from '../constants';
import axios, {AxiosError} from 'axios';
import {TGetFileFromUrlOptions} from '@app/types/app';
import colors from 'tailwindcss/colors';
import {Maybe} from 'graphql/jsutils/Maybe';

export const {searchArray} = utils;
export function getObjValueByPath(obj: Record<string, unknown>, path: string) {
  return _.get(obj, path);
}

export function setObjValueByPath(obj: Record<string, unknown>, path: string, value: unknown) {
  if (!path) {
    return null;
  }

  _.set(obj, path, value);
}

export function reactElementToString(elem: ReactElement) {
  return renderToString(elem);
}

export async function addTokenToUrl(urlStr: string, getAccessToken: () => Promise<string|undefined>): Promise<string> {
  const accessToken = await getAccessToken();
  if (accessToken) {
    urlStr = changeUrlParam(urlStr, APP_QUERY_ACCESS_TOKEN, accessToken);
  }

  return urlStr;
}

export async function downloadFileFromBlobData(data: Blob, fileName: string) {
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); // or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export async function createObjUrlFromUrl(url: string, options?: TGetFileFromUrlOptions) : Promise<string | null> {
  const {onSuccess, onFail, onEnd, getAccessToken, downloadFileName} = options ?? {};
  try {
    const dataBlob = await createBlobFromUrl(url, {getAccessToken});
    if (dataBlob) {
      onSuccess?.();
      onEnd?.();
      const file = new File([dataBlob], downloadFileName ?? '');
      return URL.createObjectURL(file);
    }

    throw new Error('unknown');
  } catch (err) {
    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
  return null;
}

const blobUrlCache: Record<string, Blob> = {};

export async function createBlobFromUrl(url: string, options?: TGetFileFromUrlOptions, nbAttemps = 1) : Promise<Blob | null> {
  const {onSuccess, onFail, onEnd, getAccessToken} = options ?? {};

  if (!options?.noCache && blobUrlCache[url]) {
    setTimeout(() => {
      onSuccess?.();
      onEnd?.();
    }, 500);
    return Promise.resolve(blobUrlCache[url]);
  }

  try {
    if (getAccessToken && !hasUrlParam(url, APP_QUERY_ACCESS_TOKEN)) {
      url = await addTokenToUrl(url, getAccessToken);
    }

    const res = await axios.get(url, {
      responseType: 'blob',
    });
    if (res.status === 200) {
      blobUrlCache[url] = res.data as Blob;
      onSuccess?.();
      onEnd?.();
      return blobUrlCache[url];
    }

    throw new Error('unknown');
  } catch (err: unknown) {
    if (err instanceof AxiosError) {
      const res = err.response;
      if (res?.status === 401 && getAccessToken && nbAttemps < 10) {
        const newToken = await getAccessToken();
        if (newToken) {
          url = changeUrlParam(url, APP_QUERY_ACCESS_TOKEN, newToken);
          return createBlobFromUrl(url, options, nbAttemps + 1);
        }
      }
    }

    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
  return null;
}

export async function createBase64FromUrl(url: string, options?: TGetFileFromUrlOptions) : Promise<string | null> {
  const {onSuccess, onFail, onEnd, getAccessToken} = options ?? {};
  try {
    const dataBlob = await createBlobFromUrl(url, {getAccessToken});
    if (dataBlob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string | null);
          onSuccess?.();
          onEnd?.();
        };

        reader.onerror = () => {
          console.log('reject', reader.error);
          reject(reader.error);
        };

        reader.readAsDataURL(dataBlob);
      });
    }

    throw new Error('unknown', {cause: url});
  } catch (err) {
    console.log('catch error', err);
    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
  return null;
}

export async function downloadFileFromUrl(url: string, fileName: string, options?: TGetFileFromUrlOptions) {
  const {onSuccess, onFail, onEnd} = options ?? {};
  try {
    const dataBlob = await createBlobFromUrl(url, options);
    if (dataBlob) {
      downloadFileFromBlobData(dataBlob, fileName);
      onSuccess?.();
    } else {
      throw new Error('unknown', {cause: url});
    }
  } catch (err) {
    if (onFail) {
      onFail(err);
    } else {
      onEnd?.();
      throw err;
    }
  }

  onEnd?.();
}

export function getFileNameWithoutExtension(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, '');
}

export function getFileExtension(fileName: string) {
  return fileName.split('.').pop()?.toLowerCase() ?? '';
}

export function isValidUnit(unit?: string | null) {
  const realUnit = unit?.trim() ?? '';
  return realUnit !== '' && realUnit !== '-';
}

export function generatePathWithBaseUrl(url: string) {
  const baseUrl = import.meta.env.BASE_URL;
  return `${baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`}${url.startsWith('/') ? url.substring(1) : url}`;
}

export function generateAbsoluteUrlWithBaseUrl(url: string) {
  const baseUrl = import.meta.env.BASE_URL;
  return `${location.protocol}//${location.host}${baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`}${url.startsWith('/') ? url.substring(1) : url}`;
}

export function stringNumFormat(val?: string, defaultEmptyValue: string | null | number = null) : string | null | number {
  let value: string | null | number = val?.trim().replace(',', '.') ?? '';
  if (value === '') {
    value = defaultEmptyValue;
  }

  return value;
}

function hasUrlParam(urlStr:string, param: string): boolean {
  const url = new URL(urlStr);
  const params = url.searchParams;

  return params.has(param);
}

function changeUrlParam(urlStr:string, param: string, value: string | number): string {
  const url = new URL(urlStr);
  const params = url.searchParams;

  if (params.has(param)) {
    params.set(param, String(value));
  } else {
    params.append(param, String(value));
  }

  // change the search property of the main url
  url.search = params.toString();

  // the new url string
  return url.toString();
}

interface ILocalOptions extends Intl.CollatorOptions {
  locales?: string;
}
export const localeIncludes = (
  string: string,
  searchInput: string,
  {locales = undefined, ...options}: ILocalOptions = {
    sensitivity: 'base',
  },
) => {
  if (!string) {
    return false;
  }

  if (searchInput === undefined || searchInput === null) {
    throw new Error('localeIncludes requires at least 2 parameters');
  }

  const stringLength = string.length;
  const searchInputLength = searchInput.length;
  const lengthDiff = stringLength - searchInputLength;

  for (let i = 0; i <= lengthDiff; i++) {
    if (string.substring(i, i + searchInputLength).localeCompare(searchInput, locales, options) === 0) {
      return true;
    }
  }

  return false;
};

export const tailwindColorToBgFgStyle = (color?: Maybe<string>) => {
  if (!color) {
    return {
      backgroundColor: colors.gray[200],
      color: colors.gray[700],
    };
  }

  const rgbBgColor = colors[color as keyof typeof colors]?.[200] ?? colors.gray[200];
  const rgbFgColor = colors[color as keyof typeof colors]?.[700] ?? colors.gray[700];
  return {
    backgroundColor: rgbBgColor,
    color: rgbFgColor,
  };
};

export const tailwindColorToHex = (color?: Maybe<string>, level: keyof typeof colors.gray = '200') => {
  if (!color || color === 'grey') {
    color = 'gray';
  }

  return colors[color as keyof typeof colors]?.[level] ?? colors.gray[level];
};

export const tailwindColorFromHex = (hexColor: string) => {
  const color = Object.entries(colors).find(([, value]) => Object.values(value).includes(hexColor));
  return color ? color[0] : 'gray';
};

export const isStringNotEmpty = (s?: unknown) => typeof s === 'string' && s !== '';

export const isFalsyOrWhiteSpace = (s?: string) => !s || s.trim() === '';

export const ucFirst = (s: string) => s?.length ? s.charAt(0).toUpperCase() + s.slice(1) : '';
