/* eslint-disable camelcase */
import {InspectionPoint, InspectionPointUncheckedCreateInput, InspectionPointUncheckedUpdateInput} from '@app/graphql/__types__/graphql';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {TFunction} from 'i18next';
import {z} from 'zod';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

// All state in inspection drawing page (list, detail modal, cml modal, damage modal)
type State = {
    activeInspectionPoint?: Partial<InspectionPoint>|null;
    deleteInspectionPointModalOpen: boolean;
    updateData: InspectionPointUncheckedUpdateInput | InspectionPointUncheckedCreateInput;
    editInspectionPoint?: Partial<InspectionPoint> | null;
    fieldErrors: Record<string, boolean>;
}

const initialState: State = {
  activeInspectionPoint: undefined,
  deleteInspectionPointModalOpen: false,
  updateData: {},
  editInspectionPoint: undefined,
  fieldErrors: {},
};

export const MAX_LENGTH_VALIDATORS = {
  DESCRIPTION: 255,
  LONG_DESCRIPTION: 255,
  POSITION: 10,
};

export const ZOD_INSP_DATAS = (t: TFunction) => ({
  position: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.POSITION).min(1, {message: t('message.error.form.required')}),
  description: z.string().max(MAX_LENGTH_VALIDATORS.DESCRIPTION).optional(),
  longDescription: z.string().max(MAX_LENGTH_VALIDATORS.LONG_DESCRIPTION).optional(),
  classId: z.number({required_error: t('message.error.form.required')}),
  codeGroupId: z.number({required_error: t('message.error.form.required')}),
});

type Actions = {
    setActiveInspectionPoint: (activeInspectionPoint?: Partial<InspectionPoint> | null) => void;
    updateInspectionPoint: (inspectionPoint: Partial<InspectionPoint>) => void;
    deleteInspectionPoint: (inspectionPoints?: Partial<InspectionPoint>) => void;
    changeDeleteInspectionPointModalDisplay: (isOpen: boolean) => void;
    resetData: () => void;
    setUpdateData: (updateData: InspectionPointUncheckedUpdateInput | InspectionPointUncheckedCreateInput) =>void;
    setEditInspectionPoint: (inspectionPoint: Partial<InspectionPoint> | null) => void;
    isSaved: () => boolean;
    hasError: ()=>boolean;
    hasFieldError: (field: string, forceCheck?: boolean)=>boolean;
    updateDataField: (field: string, value: unknown) => void;
    setFieldErrors: (fieldErrors?: Record<string, boolean>) => void;
    updateFieldError: (field: string, value: boolean) => void;
    cancelEditData: () => void;

}

type InspectionPointState = State & Actions;

const getInspectionPointUpdateState = (state: Partial<State>, inspectionPointChanges: Partial<InspectionPoint>) : Partial<State> => {
  const newActiveInspectionPoint: Partial<InspectionPoint> = {
    ...state.activeInspectionPoint,
    ...inspectionPointChanges,
  };
  const newState: Partial<State> = {};
  newState.activeInspectionPoint = newActiveInspectionPoint;
  newState.editInspectionPoint = {
    ...newState.activeInspectionPoint,
  };

  return newState;
};

const useInspectionPointStore = create<InspectionPointState>()(
  immer((set, get) => ({
    ...initialState,
    updateFieldError: (field: string, value: boolean) => set(state => ({
      fieldErrors: {
        ...state.fieldErrors,
        [field]: value,
      },
    })),
    setFieldErrors(fieldErrors?: Record<string, boolean>) {
      set({
        fieldErrors: fieldErrors ?? {},
      });
    },
    updateDataField(field: string, value: unknown) {
      const {editInspectionPoint, updateData, fieldErrors} = get();
      set({
        updateData: {
          ...updateData,
          [field]: editInspectionPoint?.id === OBJ_NEW_ID ? value : {
            set: value,
          },
        },
        fieldErrors: {
          ...fieldErrors,
          [field]: false,
        },
      });
    },
    cancelEditData: () => set(state => ({
      updateData: {},
      uploadFile: undefined,
      fieldErrors: {},
      editInspectionPoint: {...state.activeInspectionPoint},
    })),
    resetData() {
      set({
        ...initialState,
      });
    },
    setUpdateData(updateData: InspectionPointUncheckedUpdateInput) {
      set({updateData});
    },
    setEditInspectionPoint(editInspectionPoint) {
      set({editInspectionPoint});
    },
    isSaved() {
      const state = get();
      return !(state.updateData && Object.keys(state.updateData).length > 0);
    },

    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get();
      if (!Object.keys(state.updateData ?? {}).includes(field) && !forceCheck) {
        return false;
      }

      if (state.fieldErrors?.[field] === true) {
        return true;
      }

      switch (field) {
        case 'position':
          const position = (state.editInspectionPoint?.position ?? '').trim();
          return position.length > MAX_LENGTH_VALIDATORS.POSITION;
        case 'description':
          const description = (state.editInspectionPoint?.description ?? '').trim();
          return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '';
        default:
          break;
      }

      return false;
    },
    hasError() {
      const state = get();
      return ['description', 'position'].some((field: string) => state.hasFieldError(field, true));
    },
    setActiveInspectionPoint: (activeInspectionPoint?: Partial<InspectionPoint> | null) => set({activeInspectionPoint, editInspectionPoint: activeInspectionPoint ? {...activeInspectionPoint} : undefined, updateData: activeInspectionPoint?.id === OBJ_NEW_ID ? {flocId: activeInspectionPoint?.flocId!} as InspectionPointUncheckedCreateInput : {}}),
    updateInspectionPoint: (inspectionPoint: Partial<InspectionPoint>) => set(getInspectionPointUpdateState(get(), inspectionPoint)),
    deleteInspectionPoint: (inspectionPoint?: Partial<InspectionPoint>) => set(state => {
      const deletedInspectionPoint: Partial<InspectionPoint>|undefined|null = inspectionPoint ?? state.activeInspectionPoint;
      const newState: Partial<State> = {};
      newState.deleteInspectionPointModalOpen = false;
      if (deletedInspectionPoint === state.activeInspectionPoint) {
        newState.activeInspectionPoint = null;
      }

      return newState;
    }),
    changeDeleteInspectionPointModalDisplay: (isOpen: boolean) => set({
      deleteInspectionPointModalOpen: isOpen,
    }),
  })),
);

export default useInspectionPointStore;
