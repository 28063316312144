import {CSSProperties, HTMLAttributes} from 'react';
import {ControllerFieldState} from 'react-hook-form';

import InputLabel, {TInputLabelProps} from './components/InputLabel';
import {RadFormControl, RadFormItem, RadFormMessage} from '@holis/react-ui/rad';
import {twMerge} from 'tailwind-merge';

type TInputLabelWrapperProps = {
	readonly orientation?: 'horizontal' | 'vertical';
	readonly labelWidth?: string;
  readonly fieldState?: ControllerFieldState;
} & HTMLAttributes<HTMLDivElement> & Omit<TInputLabelProps, 'label'> & {readonly label? : string};

export default function InputLabelWrapper({prepend, label, children, isMuted, orientation, labelWidth, isRequired, fieldState, ...divProps}: TInputLabelWrapperProps) {
  const labelStyles: CSSProperties = {};
  if (labelWidth) {
    labelStyles.width = labelWidth;
  }

  const DivOrRadFormItem = fieldState ? RadFormItem : 'div';

  return (
    <DivOrRadFormItem {...divProps} className={twMerge('flex space-y-0', orientation === 'horizontal' ? 'items-center gap-4' : 'flex-col gap-1', divProps.className)}>
      {label && <InputLabel fieldState={fieldState} style={labelStyles} prepend={prepend} label={label} isMuted={isMuted} isRequired={isRequired}/>}
      {fieldState ? <RadFormControl>{children}</RadFormControl> : children}
      {fieldState && <RadFormMessage className='text-xs'>{fieldState.error?.message}</RadFormMessage>}
    </DivOrRadFormItem>
  );
}
