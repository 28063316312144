import {Document} from '@app/graphql/__types__/graphql';
import {t} from 'i18next';
import DocumentCard from '../../DocumentCard';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SearchBar from '@app/components/Common/SearchBar';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {LuPlusSquare, LuFile} from 'react-icons/lu';
import {useEffect, useState} from 'react';
import {EAppFlagStatus} from '@app/utils/enums';
import {searchArray} from '@app/utils/functions';

type TDocumentList = Readonly<{
	documents: Partial<Document>[];
	readonly?: boolean;
	docSelected?: (doc: Partial<Document>) => void;
	manageDocsSelected?: () => void;
  addToAttachmentsSelected?: (doc: Partial<Document>) => void;
}>;

export default function DocumentList({documents, readonly, docSelected, manageDocsSelected, addToAttachmentsSelected}: TDocumentList) {
  const [filteredDocuments, setFilteredDocuments] = useState<Partial<Document>[]>(documents ?? []);
  const [filterString, setSearchInput] = useState<string>('');

  useEffect(() => {
    setFilteredDocuments(
      searchArray(
        documents ?? [],
        filterString,
        undefined,
        ['__typename'],
      ).filter((document: Partial<Document>) => (
        document.flagStatus === EAppFlagStatus.ACTIVE.toString()
      )),
    );
  }, [documents, filterString]);

  return (
    <div className='flex flex-col gap-1 mt-3'>
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key='manage-docs' disabled={readonly || !manageDocsSelected} onClick={manageDocsSelected}><LuPlusSquare className='mr-2'/> {t('label.manageDocumentation')}</RadDropdownMenuItem>,
        ]}
        actions={
          <SearchBar
            className='w-[300px]'
            onChange={e => setSearchInput(e.target?.value)}
          />
        }
      >
        <div className='flex items-center'>
          <LuFile size={20} className='mr-2'/>

          {t('label.documentation')}
        </div>
      </FormGroupHeader>
      {
        documents?.length === 0
          ? <div className='text-gray-400 text-sm ps-4'>{t('label.noDocumentation')}</div>
          : filteredDocuments?.map(doc => (
            <DocumentCard
              key={doc.id}
              hasContextMenu
              doc={doc!}
              addToAttachments={addToAttachmentsSelected ? () => addToAttachmentsSelected(doc) : undefined}
              onOpen={() => docSelected?.(doc)}
            />
          ))

      }
    </div>

  );
}
