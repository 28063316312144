import {
  PinturaDefaultImageWriterResult,
  PinturaImageState,
  Shape,
} from '@pqina/pintura';

export type TAppPinturaDefaultImageWriterResult = Omit<PinturaDefaultImageWriterResult, 'imageState'> & {
  imageState: Omit<PinturaImageState, 'annotation'> & {
    annotation?: TAppAnnotation[];
  };
};

enum AnnotationType {
  PATH = 'path',
  LINE = 'line',
  RECTANGLE = 'rectangle',
  ELLIPSE = 'ellipse',
  TEXT = 'text',
  TRIANGLE = 'triangle',
}

export type TAppAnnotation = {
  annotationType: AnnotationType;
  pathClose?: boolean;
} & Pick<Shape,
  'x'
  | 'y'
  | 'width'
  | 'height'
  | 'left'
  | 'top'
  | 'right'
  | 'bottom'
  | 'rx'
  | 'ry'
  | 'x1'
  | 'y1'
  | 'x2'
  | 'y2'
  | 'strokeColor'
  | 'strokeWidth'
  | 'cornerRadius'
  | 'fontSize'
  | 'fontFamily'
  | 'textAlign'
  | 'text'
  | 'textOutlineColor'
  | 'textOutlineWidth'
  | 'rotation'
  | 'points'
  | 'color'
  | 'backgroundColor'
  | 'lineStart'
  | 'lineEnd'
>;

const dummyAppAnnotation: TAppAnnotation = {
  annotationType: AnnotationType.RECTANGLE,
  pathClose: undefined,
  x: undefined,
  y: undefined,
  width: undefined,
  height: undefined,
  left: undefined,
  top: undefined,
  right: undefined,
  bottom: undefined,
  rx: undefined,
  ry: undefined,
  x1: undefined,
  y1: undefined,
  x2: undefined,
  y2: undefined,
  strokeColor: undefined,
  strokeWidth: undefined,
  cornerRadius: undefined,
  fontSize: undefined,
  fontFamily: undefined,
  textAlign: undefined,
  text: undefined,
  textOutlineColor: undefined,
  textOutlineWidth: undefined,
  rotation: undefined,
  points: undefined,
  color: undefined,
  backgroundColor: undefined,
  lineStart: undefined,
  lineEnd: undefined,
};

function getAnnotationType(annotation: Shape): AnnotationType | undefined {
  let annotationType: AnnotationType | undefined;
  const annotationKeys = Object.keys(annotation);

  const earlyBreakKey = annotationKeys.find(k => k === 'x3' || k === 'y3' || k === 'text');

  // "Line" and "Triangle" both share the "x1" property, so we check if the annotation is a triangle first
  // and return early if it is, that way we can be sure that the annotation is a line and not a triangle
  // if we find "x1" in the keys
  if (earlyBreakKey === 'x3' || earlyBreakKey === 'y3') {
    return AnnotationType.TRIANGLE;
  }

  // Same case as above to avoid a text annotation being treated as regular rectangle
  if (earlyBreakKey === 'text') {
    return AnnotationType.TEXT;
  }

  annotationKeys.forEach(key => {
    switch (key) {
      // Path
      case 'points': {
        annotationType = AnnotationType.PATH;
        break;
      }

      // Ellipse
      case 'rx': {
        annotationType = AnnotationType.ELLIPSE;
        break;
      }

      // Rectangle
      case 'width': {
        annotationType = AnnotationType.RECTANGLE;
        break;
      }

      // Line
      case 'x1': {
        annotationType = AnnotationType.LINE;
        break;
      }

      default:
        break;
    }
  });

  return annotationType;
}

function pinturaAnnotationToAppAnnotation(annotation: Shape): TAppAnnotation | undefined {
  const annotationType = getAnnotationType(annotation);
  const appAnnotationObjectKeys: string[] = Object.keys(dummyAppAnnotation);

  if (!annotationType) {
    return;
  }

  let appAnnotation: TAppAnnotation = {annotationType};

  Object.entries(annotation).forEach(entry => {
    if (appAnnotationObjectKeys.find(k => entry[0] === k) === undefined) {
      return;
    }

    if ((entry[0] === 'lineEnd' || entry[0] === 'lineStart') && entry[1] === 'none') {
      appAnnotation = {...appAnnotation, [entry[0]]: undefined};
    } else {
      appAnnotation = {...appAnnotation, [entry[0]]: entry[1]};
    }
  });

  return appAnnotation;
}

export function cleanAllAnnotations(annotations: Shape[]): TAppAnnotation[] {
  const appAnnotations: TAppAnnotation[] = [];

  annotations.forEach((annotation: Shape) => {
    const appAnnotation = pinturaAnnotationToAppAnnotation(annotation);

    if (!appAnnotation) {
      return;
    }

    appAnnotations.push(appAnnotation);
  });

  return appAnnotations;
}
