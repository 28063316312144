import {gql} from '@apollo/client';
import {FILE_REPORTS_COMMON_FIELDS} from '../fragments';

export const FILE_REPORTS_GET_BY_NAME = gql`
${FILE_REPORTS_COMMON_FIELDS}
query GetReportByName($docName: String!) {
    report(where: {docName: $docName}){
        ...FileReportCommonFields
    }
}
`;

