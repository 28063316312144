import {FunctionalLocation} from '@app/graphql/__types__/graphql';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {EFlocRightSideTab} from '@app/utils/enums';
import {useTranslation} from 'react-i18next';
import PicturePage from './PicturePage';
import FlocOverviewDrawing from '@app/components/Common/Block/Floc/FlocBlock/FlocOverviewDrawing';
import DrawingViewers from '@app/components/Common/Block/InspectionDrawing/DrawingViewers';
import EventPage from './EventPage';
import {RadTabs, RadTabsList, RadTabsTrigger, RadBadge} from '@holis/react-ui/rad';
import DocumentationPage from './DocumentationPage';

type TProps = Readonly<{
  floc: Partial<FunctionalLocation>;
}>;

export default function RightSide({floc}: TProps) {
  const {t} = useTranslation();
  const {documents, events, pictures, setRightSideTabSelectedValue, rightSideTabSelectedValue, flocInspectionDrawings, setSelectedDrawing, selectedDrawing} = useFlocStore();
  const tabs = [
    {label: t('label.picture'), value: EFlocRightSideTab.PICTURES, counter: pictures?.length ?? floc?._count?.pictures},
    {label: t('label.drawing'), value: EFlocRightSideTab.DRAWINGS, counter: flocInspectionDrawings?.length ?? floc?._count?.idwgFlocs},
    {label: t('label.overview'), value: EFlocRightSideTab.OVERVIEW},
    {label: t('label.event'), value: EFlocRightSideTab.EVENTS, counter: events?.length ?? floc?._count?.events},
    {label: t('label.documentation'), value: EFlocRightSideTab.DOCUMENTATION, counter: documents?.length ?? floc?._count?.flocDocs},
  ];

  return (
    <div className='h-full w-full flex flex-col'>
      <RadTabs className='pt-2 px-4' value={rightSideTabSelectedValue}>
        <RadTabsList>
          { tabs.map(tab => (
            <RadTabsTrigger key={tab.label} value={tab.value} onClick={() => setRightSideTabSelectedValue(tab.value)}>
              {tab.label}
              {tab.counter !== undefined && <RadBadge className='rounded-full ml-1 text-2xs p-1 h-4 min-w-4 justify-center'>{tab.counter}</RadBadge>}
            </RadTabsTrigger>
          )) }
        </RadTabsList>
      </RadTabs>

      <div className={`pt-4 px-4 ${rightSideTabSelectedValue === EFlocRightSideTab.OVERVIEW ? 'pb-0' : 'pb-4'} flex-1 flex flex-col overflow-auto`}>
        <PicturePage visible={rightSideTabSelectedValue === EFlocRightSideTab.PICTURES}/>

        <DrawingViewers objectItem={floc} drawings={flocInspectionDrawings} setSelectedDrawing={setSelectedDrawing} selectedDrawing={selectedDrawing} visible={rightSideTabSelectedValue === EFlocRightSideTab.DRAWINGS}/>

        <FlocOverviewDrawing
          floc={floc}
          visible={rightSideTabSelectedValue === EFlocRightSideTab.OVERVIEW}
        />

        <EventPage visible={rightSideTabSelectedValue === EFlocRightSideTab.EVENTS}/>

        <DocumentationPage visible={rightSideTabSelectedValue === EFlocRightSideTab.DOCUMENTATION}/>
      </div>
    </div>
  );
}
