import {gql} from '@apollo/client/core';
import {DOCUMENTATION_DOCUMENTS_COMMON_FIELDS, WORKORDER_EVENT_DOCUMENTS_COMMON_FIELDS} from '../fragments';

export const WORKORDER_EVENT_DOCUMENTS_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_DOCUMENTS_COMMON_FIELDS}
${DOCUMENTATION_DOCUMENTS_COMMON_FIELDS}
query GetEventDocumentsByEvtId($evtId: Int!) {
  eventDocuments (where: {wrkoId: {equals: $evtId}}, orderBy: [{sort: {sort: asc}}]) {
    ...EventDocumentCommonFields
    document {
      ...DocumentCommonFields
    }
  }
}`;

export const WORKORDER_EVENT_DOCUMENTS_CREATE_MANY = gql`
mutation CreateEventDocuments($data: [EventDocumentCreateManyInput!]!) {
  createManyEventDocument(data: $data) {
    count
  }
}`;

export const WORKORDER_EVENT_DOCUMENTS_DELETE_MANY = gql`
mutation DeleteEventDocuments($ids: [Int!]) {
  deleteManyEventDocument (where: {id: {in: $ids}}) {
    count
  }
}`;

