import {useFlocStore} from '@app/stores/methodEngineering/floc';
import {useEffect} from 'react';
import PictureTab from '@app/components/Common/Block/Picture/Block/PictureTab';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';

type TProps = Readonly<{
  visible: boolean;
}>;

export default function PicturePage({visible}: TProps) {
  const prms = useUserPermissions();
  const {pictures, activeFloc, fetchPictures} = useFlocStore();

  useEffect(() => {
    fetchPictures?.();
  }, [fetchPictures]);

  return (
    <PictureTab
      canManage={prms.flocs.update || prms.flocs.create}
      isLoading={typeof pictures === 'undefined'}
      pictures={pictures}
      className={visible ? '' : 'hidden'}
      uploadParameters={{flocId: activeFloc!.id}}
      onPicturesChanged={fetchPictures}
    />
  );
}
