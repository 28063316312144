import {DataRow, GridApi, GridNode, GridWrapper} from '@holis/react-ui';
import {addRadSon} from '@holis/react-ui/rad';
import {getObjValueByPath, getTextFromReactNode} from '@holis/react-ui/utils';
import {ComponentProps, useCallback, useEffect, useState} from 'react';
import XLSX from 'xlsx';

const onSaveLayout = () => {
  addRadSon.success('Layout saved');
};

const onResetLayout = () => {
  addRadSon.info('Layout restored');
};

type TProps<T extends DataRow> = ComponentProps<typeof GridWrapper<T>> & Readonly<{
	fileNameOnExport?: string
}>

export default function AppGrid<T extends object>({data, onRender, onRowDblClick, onRowClick, ...props}: TProps<T>) {
  const [api, setApi] = useState<(InstanceType<GridApi<T>>) | null>(null);
  const onExport = useCallback(() => {
    if (!data || !api) {
      return;
    }

    const cache = new Map<string, string[]>();

    const columns = api.getColumns().filter(c => c.state?.hidden !== true);
    const _data = api.getVisibleRows().filter(r => r.filtered !== true).map(row => {
      const _row = [];
      for (const column of columns) {
        if ((column.field) === 'id') {
          continue;
        }

        const v = (column.field ? getObjValueByPath(row.data, column.field, null, cache) : undefined) as T[keyof T];
        _row.push(column.cellRenderer ? getTextFromReactNode(column.cellRenderer(v, row, column)) : v);
      }

      return _row;
    });
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([columns.map(c => getTextFromReactNode(c.title)), ..._data], {
      dense: true,
    });
    const name = props.fileNameOnExport ?? props.name ?? 'TableData';
    XLSX.utils.book_append_sheet(wb, ws, name);
    XLSX.writeFile(wb, name + '.xlsx');
  }, [data, api, props.fileNameOnExport, props.name]);

  const handleRender = useCallback((api: InstanceType<GridApi<T>>) => {
    onRender?.(api);
    setApi(api);
  }, []);

  useEffect(() => {
    if (!api) {
      return;
    }

    api.addEventListener('stateSaved', onSaveLayout);
    api.addEventListener('stateReset', onResetLayout);
    return () => {
      api.removeEventListener('stateSaved', onSaveLayout);
      api.removeEventListener('stateReset', onResetLayout);
    };
  }, [api]);

  useEffect(() => {
    if (!api || !onRowDblClick) {
      return;
    }

    const handleDblClick = ({data}: { data?: GridNode<T> }) => {
      if (data) {
        onRowDblClick(data);
      }
    };

    api.addEventListener('rowDblClick', handleDblClick);
    return () => {
      api.removeEventListener('rowDblClick', handleDblClick);
    };
  }, [api, onRowDblClick]);

  useEffect(() => {
    if (!api || !onRowClick) {
      return;
    }

    const handleClick = ({data}: { data?: GridNode<T> }) => {
      if (data) {
        onRowClick(data);
      }
    };

    api.addEventListener('rowClick', handleClick);

    return () => {
      api.removeEventListener('rowClick', handleClick);
    };
  }, [api, onRowClick]);

  for (const col of props.columns) {
    if (typeof col.title === 'string') {
      col.title = <div className='font-bold text-primary grid-col-title'>{col.title}</div>;
    }
  }

  return (
    <div className='relative size-full text-sm'>
      <GridWrapper
        showFooter
        data={data ?? []}
        onRender={handleRender}
        onExport={onExport}
        {...props}
        title={typeof props.title === 'string'
          ? <div className='text-lg font-semibold'>{props.title}</div>
          : props.title}
      />
    </div>
  );
}
