import {Notification} from '@app/graphql/__types__/graphql';
import {useTranslation} from 'react-i18next';
import {TAdditionalActions} from '@app/types/app';
import {EAdditionalAction} from '@app/utils/enums';
import useNotificationStore from '@app/stores/notification';
import DetailsPageTitleBar from '@app/components/Layout/DetailsPageTitleBar';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {LuDownload, LuGitFork, LuRefreshCw, LuTrash2} from 'react-icons/lu';
import useReportGeneration, {ItemType} from '@app/utils/hooks/useReportGeneration';
import ReportGeneratingMesaggeWrapper from '../../../Reporting/ReportGeneratingMesaggeWrapper';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';

type THeader = Readonly<{
    notification: Partial<Notification>
    readonly?: boolean
}>

export default function Header({notification, readonly}: THeader) {
  const {t} = useTranslation();
  const prms = useUserPermissions();
  const {downloadReport, hasReport, isReportGenerating, launchReportGeneration} = useReportGeneration(ItemType.notif, notification.id, notification.notif);
  const {changeDeleteNotificationModalDisplay, activeNotification, changeWorkflowModalDisplay} = useNotificationStore();

  const handleShowWorkflow = () => {
    changeWorkflowModalDisplay(true);
  };

  const canGenerateReport = prms.notifications.create || prms.notifications.update || prms.notifications.approve;
  const canDelete = prms.notifications.delete;
  const additionalActions: TAdditionalActions = {
    [EAdditionalAction.DISPLAY_WORKFLOW]: <RadDropdownMenuItem className='gap-2' onClick={handleShowWorkflow}><LuGitFork/> {t('label.workflow')}</RadDropdownMenuItem>,
    ...(canGenerateReport ? {[EAdditionalAction.GENERATE_REPORT]: <RadDropdownMenuItem className='gap-2' disabled={isReportGenerating} onClick={launchReportGeneration}><LuRefreshCw className={`text-sm${isReportGenerating ? ' animate-spin' : ''}`}/> {t('label.generateReport')}</RadDropdownMenuItem>} : {}),
    ...(hasReport ? {[EAdditionalAction.DOWNLOAD_REPORT]: <RadDropdownMenuItem disabled={isReportGenerating} className='gap-2' onClick={downloadReport}><LuDownload className='text-sm'/> {t('label.downloadReport')}</RadDropdownMenuItem>} : {}),
    ...(canDelete ? {[EAdditionalAction.DELETE]: <RadDropdownMenuItem disabled={readonly} className='gap-2 item-destructive' onClick={() => changeDeleteNotificationModalDisplay(true)}><LuTrash2/> {t('label.deleteNotification')}</RadDropdownMenuItem>} : {}),
  };

  return (
    <DetailsPageTitleBar
      title={`${activeNotification?.notif}`}
      subtitle={`${activeNotification?.functionalLocation?.floc}`}
      actionButtons={{
        additionalActions,
      }}
    >
      <ReportGeneratingMesaggeWrapper isReportGenerating={isReportGenerating}/>
    </DetailsPageTitleBar>
  );
}
