import {useTranslation} from 'react-i18next';
import React, {ReactNode, useEffect, useState} from 'react';
import SingleContentModal from '../../SingleFormModal';
import {RadButton, RadCheckbox} from '@holis/react-ui/rad';
import {TDbId} from '@app/types/app';
import {twMerge} from 'tailwind-merge';
import {TSelectionConfirmModalWithoutListProps} from '../Selection';
import {Cml, Damage, Event, FunctionalLocation, InspectionDrawing, Notification} from '@app/graphql/__types__/graphql';
import InspectionDrawingAccordion from '@app/components/Common/Block/Event/EventModal/components/InspectionDrawingAccordion';
import PanelHeader from '@app/components/Common/Panel/PanelHeader';
import {searchArray} from '@app/utils/functions';
import {NO_DRAWING_ID} from '@app/utils/constants';
import SpinnerLoaderComponent from '@app/components/Loaders/SpinnerLoaderComponent';
import DrawingViewer from '@app/components/Common/Block/InspectionDrawing/DrawingViewer';
import {IDWG_PREFIX} from '@app/stores/idwg';
import _ from 'lodash';
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels';
import AppNotifications from '@app/services/notification';
import DrawingViewers from '@app/components/Common/Block/InspectionDrawing/DrawingViewers';

type TItem = Partial<Cml | Damage>

type TDrawingObjectSelectionConfirmModal = TSelectionConfirmModalWithoutListProps & Readonly<{
  hiddenIds?: TDbId[];
  objectItem?: Partial<Event | FunctionalLocation | Notification>;
  selectedIds?: TDbId[];
  onValidate?: (selectedItems: TItem[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => void;
  isMultiple?: boolean;
  items: TItem[];
  drawings?: Partial<InspectionDrawing>[];
  isRequired?: boolean;
  isLoading?: boolean;
  description?: string;
  searchFilter?: (data: TItem[], searchText: string) => TItem[];
  className?: string;
  cmlHidden?: boolean;
  dmgHidden?: boolean;
  drawingClassName?: string;
  drawingTriggerClassName?: string;
  drawingContentClassName?: string;
  renderItem: (item: TItem, drawingStoreId: string) => ReactNode;
  headerTitle?: ReactNode;
  drawingStoreIdSuffix?: string;
}>;

export default function DrawingObjectSelectionConfirmModal({title, description, hiddenIds, onValidate, isMultiple, renderItem, drawings, searchFilter, selectedIds, items, isRequired, headerTitle, drawingTriggerClassName, drawingContentClassName, drawingClassName, isLoading, drawingStoreIdSuffix, objectItem, cmlHidden, dmgHidden, ...restProps}: TDrawingObjectSelectionConfirmModal) {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedDrawing, setSelectedDrawing] = useState<Partial<InspectionDrawing>>();
  const [drawingsToDisplay, setDrawingsToDisplay] = useState<Partial<InspectionDrawing>[]>();
  const [filteredItems, setFilteredItems] = useState<TItem[]>();
  const [selectedRows, setSelectedRows] = useState<TItem[]>([]);
  const [newSelectedIds, setNewSelectedIds] = useState<TDbId[]>([]);
  const [oldSelectedIds, setOldSelectedIds] = useState<TDbId[]>([]);
  const [itemsByDrawing, setItemsByDrawing] = useState<Record<number, TItem[]>>({});
  const [rightSideWidth, setRightSideWidth] = useState<number>();
  const {t} = useTranslation();
  const handleItemSelectionChanged = (item: TItem, checked?: boolean) => {
    if (isMultiple) {
      if (!selectedRows.includes(item) && checked !== false) {
        setSelectedRows([
          ...selectedRows,
          item,
        ]);
      } else if (selectedRows.includes(item) && checked !== true) {
        setSelectedRows([...selectedRows.filter(filteredItem => filteredItem.id !== item.id)]);
      }
    } else if (checked) {
      setSelectedRows([item]);
    } else {
      setSelectedRows([]);
    }
  };

  const onLeftSideResize = (size: number, _prevSize: number | undefined, containerRef: React.RefObject<HTMLDivElement>) => {
    setRightSideWidth((containerRef.current?.getBoundingClientRect().width ?? window.innerWidth - 250) * (100 - size) / 100);
  };

  const renderDrawingViewer = (idwg: Partial<InspectionDrawing>) => (
    <DrawingViewer
      key={`${IDWG_PREFIX}-${idwg.id}-${drawingStoreIdSuffix ?? ''}`} statusHidden menuContextDisabled
      locateMarkupDisabled
      displayLastQualReadingWithColor
      deleteTechniqueDisabled
      techniqueCreateBtnHidden
      isSelected={selectedDrawing?.id === idwg.id} selectedDrawing={selectedDrawing ? drawings?.find(drawing => drawing.id === selectedDrawing.id) : null}
      setSelectedDrawing={setSelectedDrawing}
      cmlHidden={cmlHidden}
      dmgHidden={dmgHidden}
      objectItem={objectItem}
      containerWidth={rightSideWidth}
      storeId={`${IDWG_PREFIX}-${idwg.id}-${drawingStoreIdSuffix ?? ''}`} cmls={(items.length && items[0].__typename === 'Cml' ? itemsByDrawing[idwg.id!] : []) as Partial<Cml>[]}
      damages={(items.length && items[0].__typename === 'Damage' ? itemsByDrawing[idwg.id!] : []) as Partial<Damage>[]} hasActionList={false} idwg={idwg}
      onMarkupMouseUp={(item: Partial<Cml | Damage>) => {
        AppNotifications.success(t(`label.${item.__typename === 'Cml' ? 'cml' : 'dmg'}Selected`, {name: item.position ?? `#${item.id}`}));
        handleItemSelectionChanged(items?.find(it => it.id === item.id) as TItem, true);
      }}/>
  );

  useEffect(() => {
    setItemsByDrawing(filteredItems?.reduce((acc, item) => {
      let {idwgId} = item;
      if (!idwgId) {
        idwgId = NO_DRAWING_ID;
      }

      if (!acc[idwgId]) {
        acc[idwgId] = [];
      }

      acc[idwgId].push(item);
      return acc;
    }, {} as Record<number, TItem[]>) ?? {});
  }, [filteredItems]);

  useEffect(() => {
    setSelectedRows(items?.filter((item: TItem) => item.id && selectedIds?.includes(item.id)) ?? []);
  }, [items]);

  useEffect(() => {
    const currentSelectedIds = selectedRows.filter((item: TItem) => !!item.id).map((item: TItem) => item.id) as (TDbId)[];
    setNewSelectedIds(currentSelectedIds.filter((id: TDbId) => !selectedIds?.includes(id)));
    setOldSelectedIds(selectedIds?.filter((id: TDbId) => !currentSelectedIds.includes(id)) ?? []);
  }, [selectedRows]);

  useEffect(() => {
    const sortedItems = _.orderBy(items, [
      item => item.id && selectedIds?.includes(item.id) ? 0 : 1,
      item => item.id,
    ]);
    setFilteredItems((searchFilter?.(sortedItems, searchInput) ?? searchArray(sortedItems, searchInput, undefined, ['__type'])).filter(item => !hiddenIds || !hiddenIds.includes(item.id)));
  }, [items, searchInput]);

  useEffect(() => {
    const withDrawings = drawings?.filter(item => item.id !== NO_DRAWING_ID) ?? [];
    setDrawingsToDisplay(withDrawings);
  }, [drawings, itemsByDrawing]);

  console.log(items, filteredItems);

  return (

    <SingleContentModal
      isOpen={restProps.open}
      containerClassName='max-w-[90%] h-[90%]'
      contentClassName='overflow-hidden flex flex-col ml-4'
      title={title}
      description={description}
      footer={
        <div className='w-full flex items-center gap-2 justify-end'>
          {/* <div className='flex-1 text-gray-600'>
            {t('label.selected')}: {selectedRows.length} / {items.filter(item => !(item.id && hiddenIds?.includes(item.id))).length}
          </div> */}
          <RadButton variant='outline' onClick={() => restProps.onClose?.()}>{t('label.cancel')}</RadButton>
          <RadButton disabled={!!isRequired && !selectedRows.length} onClick={() => onValidate?.(selectedRows, newSelectedIds, oldSelectedIds)}>{t('label.select')}</RadButton>
        </div>
      }
      onOpenChange={isOpen => {
        if (!isOpen) {
          restProps.onClose?.();
        }
      }}
    >
      <div className='flex flex-row items-stretch gap-2 h-full w-full'>
        <MultipleResizablePanels
          panelsDefaultSize={[50, 50]}
          panelsMinSize={[30, 40]}
          autoSaveId='drawing-obj-selection'
          resizerClassName='left-0 panelResizer'
          panelsOnResize={[onLeftSideResize, undefined]}
        >
          <div className='flex flex-col w-full h-full'>
            <PanelHeader className='pr-0 mr-[17px]' title={headerTitle} onSearch={setSearchInput}/>
            <SpinnerLoaderComponent isLoading={isLoading} className='overflow-auto' contentClassName='h-auto'>
              <InspectionDrawingAccordion
                selectedDrawing={selectedDrawing}
                drawings={drawings}
                triggerClassName={twMerge('mr-1', drawingTriggerClassName)}
                contentClassName={drawingContentClassName}
                drawingClassName={twMerge('mt-1', drawingClassName)}
                renderDrawingElements={(drawing: Partial<InspectionDrawing>) => itemsByDrawing[drawing.id!]?.map((item: TItem) => (
                  <div key={item.id} className='mt-2 relative flex flex-col justify-center'>
                    <RadCheckbox className='absolute' checked={selectedRows.includes(item)} onCheckedChange={checked => handleItemSelectionChanged(item, !!checked)}/>
                    <div className='flex-1 ml-6 mr-1'>{renderItem(item, `${IDWG_PREFIX}-${drawing.id}-${drawingStoreIdSuffix ?? ''}`)}</div>
                  </div>
                )) ?? []}
                getDrawingElementsCount={(drawing: Partial<InspectionDrawing>) => itemsByDrawing[drawing.id!]?.length ?? 0}
                onDisplayDrawingClick={setSelectedDrawing}
              />
            </SpinnerLoaderComponent>
          </div>
          <div className='w-full h-full pl-4'>
            {typeof drawingsToDisplay !== 'undefined' && renderDrawingViewer && <DrawingViewers key='object-selection-drawings' visible hideCreate hideActions objectItem={objectItem} setSelectedDrawing={setSelectedDrawing} drawings={drawingsToDisplay} selectedDrawing={selectedDrawing} renderDrawingViewer={renderDrawingViewer}/>}
          </div>
        </MultipleResizablePanels>
      </div>
    </SingleContentModal>

  );
}
