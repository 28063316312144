import {useLazyQuery, useMutation} from '@apollo/client';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import {AddAndCalculateIntsByFlocIdMutation, GetIntegrityStatusByFlocIdQuery, IntegrityStatus} from '@app/graphql/__types__/graphql';
import {INTEGRITY_STATUSES_ADD_BY_FLOC_ID, INTEGRITY_STATUSES_GET_ONE_BY_FLOC_ID} from '@app/graphql/requests';
import {useFlocStore} from '@app/stores/methodEngineering/floc';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {RadButton} from '@holis/react-ui/rad';
import {t} from 'i18next';
import {LuCalculator, LuClipboardList} from 'react-icons/lu';
import IntegrityStatusCard from '@app/components/Common/Block/IntegrityStatus/IntegrityStatusBlock/IntegrityStatusCard';

export default function IntegrityStatusBlock() {
  const {integrityStatus, activeFloc, setIntegrityStatus} = useFlocStore();
  const prms = useUserPermissions();
  const [addAndCalculateIntsByFlocIdApi] = useMutation<AddAndCalculateIntsByFlocIdMutation>(INTEGRITY_STATUSES_ADD_BY_FLOC_ID);
  const [getIntegrityStatusByFlocIdApi] = useLazyQuery<GetIntegrityStatusByFlocIdQuery>(INTEGRITY_STATUSES_GET_ONE_BY_FLOC_ID);

  const getFlocInts = () => {
    getIntegrityStatusByFlocIdApi({
      variables: {
        flocId: activeFloc?.id!,
      },
      fetchPolicy: 'no-cache',
    }).then(result => setIntegrityStatus(result.data?.integrityStatus as Partial<IntegrityStatus>)).catch(setIntegrityStatus);
  };

  const calculateInts = async () => {
    await addAndCalculateIntsByFlocIdApi({
      variables: {
        flocId: activeFloc!.id!,
      },
    });
    return getFlocInts();
  };

  return (
    <div className='flex flex-col gap-2'>
      <FormGroupHeader className='mb-1'>
        <div className='flex gap-4 items-center'>
          <div className='flex items-center'>
            <LuClipboardList className='mr-2' size={20}/> {t('label.integrityStatus')}
          </div>
          <RadButton variant='outline' disabled={!prms.flocs.manageIntegrity} className='text-foreground' onClick={calculateInts}><LuCalculator className='mr-2'/> {t('label.calculate')}</RadButton>
        </div>
      </FormGroupHeader>
      {integrityStatus ? <IntegrityStatusCard hasMenuContext readonly={!prms.flocs.manageIntegrity} integrityStatus={integrityStatus}/> : <div className='flex justify-center'>{t('label.noResult')}</div>}
    </div>
  );
}
