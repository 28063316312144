import {useQuery} from '@apollo/client';
import {Cml, EventCml, GetEventCmlsByCmlIdQuery} from '@app/graphql/__types__/graphql';
import {WORKORDER_EVENT_CMLS_GET_BY_CML_ID} from '@app/graphql/requests';
import {tailwindColorToBgFgStyle} from '@app/utils/functions';
import {convertDateDefaultStrToDateEuStr} from '@app/utils/functions/dates';
import {useEffect} from 'react';
import {LuCalendar, LuLineChart, LuLoader2} from 'react-icons/lu';

type TCmlCardDetails = Readonly<{
	cml: Partial<Cml>;
}>;

export default function CmlCardDetails({cml}: TCmlCardDetails) {
  const {data: evtCmls, loading, refetch} = useQuery<GetEventCmlsByCmlIdQuery>(WORKORDER_EVENT_CMLS_GET_BY_CML_ID, {
    variables: {
      cmlId: cml.id!,
      orderBy: [
        {reportingDate: {sort: 'desc'}},
        {id: 'desc'},
      ],
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch?.();
  }, [cml]);

  const firstEvtCmls = (evtCmls?.eventCmls as EventCml[])?.filter(cml => cml.qualReading && Number(cml.qualReading?.codeCond)).slice(0, 3);
  return (
    <div className='h-full flex py-4 text-xs'>
      <div className='min-w-[80px] flex flex-col gap-1 h-full justify-center pr-3 border-r border-gray-200'>
        {
          loading ? <div className='justify-self-center w-full'><LuLoader2 className='m-auto animate-spin'/></div>
            : firstEvtCmls?.map(evtCml => {
              const colorStyle = tailwindColorToBgFgStyle(evtCml.qualReading?.integrityCondition?.color);
              return (
                <div key={evtCml.id}>
                  <div className='flex items-center gap-1'>
                    <div className='w-3 h-3 rounded-full' style={{backgroundColor: colorStyle.color ?? '#ccc'}}/>
                    <div>{evtCml.quantReading ?? '-'} {cml.codeGroup?.unit}</div>
                  </div>
                </div>
              );
            })
        }
      </div>
      <div className='flex-1 pl-3 flex flex-col gap-2 h-full justify-center'>
        <div className='flex gap-2 items-center'>
          <LuLineChart/> {cml.corrRateST} {cml.codeGroup?.unit}/yr
        </div>
        <div className='flex gap-2 items-center'>
          <LuCalendar/> {cml.maxEndDate ? convertDateDefaultStrToDateEuStr(cml.maxEndDate) : '-'}
        </div>
      </div>
    </div>
  );
}
