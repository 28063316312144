import {ApolloCache, DefaultContext, FetchResult, MutationFunctionOptions, OperationVariables} from '@apollo/client';
import {Plan, PlanUncheckedCreateInput, PlanUncheckedUpdateInput, InspectionDrawing, PlanFloc, PlanCount, Event, Item, CreatePlanWorkflowMutation} from '@app/graphql/__types__/graphql';
import {OBJ_NEW_ID} from '@app/utils/constants';
import {ESCHEDULING_PLAN_STATUS} from '@app/utils/enums';
import {convertDateDefaultStrToDateObj} from '@app/utils/functions/dates';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {z} from 'zod';
import {TFunction} from 'i18next';

type State = {
  planFieldErrors: Record<string, boolean>;
  deletePlanModalOpen: boolean;
  editPlan?: Partial<Plan> | null;
  updatePlanData?: PlanUncheckedUpdateInput | PlanUncheckedCreateInput;
  activePlan?: Partial<Plan>;
  creatingPlan: boolean;
  activeInspectionDrawing?: Partial<InspectionDrawing>;
  flocToDelete?: Partial<PlanFloc>;
  isSimulated: boolean;
  simulatingEvents?: Partial<Event>[];
  fetchPlan?: () => Promise<Partial<Plan>>;
  items?: Partial<Item>[];
  events?: Partial<Event>[];
  flocs?: Partial<PlanFloc>[];
  createPlanWorkflowFunc?: (options?: MutationFunctionOptions<CreatePlanWorkflowMutation, OperationVariables, DefaultContext, ApolloCache<unknown>> | undefined) => Promise<FetchResult<CreatePlanWorkflowMutation>>;
  workflowModalDisplayed: boolean;
  flocsSelectionDisplayed: boolean;
};

const initialState: State = {
  deletePlanModalOpen: false,
  editPlan: null,
  updatePlanData: {},
  planFieldErrors: {},
  activePlan: undefined,
  activeInspectionDrawing: undefined,
  flocToDelete: undefined,
  isSimulated: false,
  simulatingEvents: undefined,
  items: undefined,
  workflowModalDisplayed: false,
  flocsSelectionDisplayed: false,
  creatingPlan: false,
  flocs: undefined,
};

export const MAX_LENGTH_VALIDATORS = {
  DESCRIPTION: 50,
  NOTES: 600,
  PLAN: 45,
  REVISION: 5,
};

export const ZOD_PLAN_DATAS = (t: TFunction) => ({
  // eslint-disable-next-line camelcase
  plan: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.PLAN).min(1, {message: t('message.error.form.required')}),
  // eslint-disable-next-line camelcase
  description: z.string({required_error: t('message.error.form.required')}).max(MAX_LENGTH_VALIDATORS.DESCRIPTION).min(1, {message: t('message.error.form.required')}),
  revision: z.string().max(MAX_LENGTH_VALIDATORS.REVISION),
  notes: z.string().max(MAX_LENGTH_VALIDATORS.NOTES),
  // eslint-disable-next-line camelcase
  typeId: z.number({required_error: t('message.error.form.required')}),
  // eslint-disable-next-line camelcase
  flocId: z.number({required_error: t('message.error.form.required')}),
});

type Actions = {
  updatePlan: (plan: Partial<Plan>, isNew?: boolean) => void;
  deletePlan: (plan?: Partial<Plan>) => void;
  setUpdatePlanData: (updatePlanData: PlanUncheckedUpdateInput | PlanUncheckedCreateInput) =>void;
  setEditPlan: (plan?: Partial<Plan> | null) => void;
  updatePlanDataField: (field: string, value: unknown) => void;
  setActivePlan: (activePlan?: Partial<Plan>, isNew?: boolean) => void;
  changeDeletePlanModalDisplay: (isOpen: boolean) => void;
  isSaved: () => boolean;
  hasError: ()=>boolean;
  hasFieldError: (field: string, forceCheck?: boolean)=>boolean;
  cancelEditData: () => void;
  resetData: () => void;
  setFlocToDelete: (flocToDelete?: Partial<PlanFloc>) => void;
  deletePlanFloc: (flocToDelete?: Partial<PlanFloc>, plan?: Partial<Plan>) => void;
  changeSimulated: (isSimulated: boolean, simulatingEvents?: Partial<Event>[]) => void;
  setSimulatingEvents: (simulatingEvents?: Partial<Event>[]) => void;
  setFetchPlan: (fetchPlan?: () => Promise<Partial<Plan>>) => void;
  setItems: (items?: Partial<Item>[]) => void;
  setEvents: (events?: Partial<Event>[]) => void;
  setFlocs: (flocs?: Partial<PlanFloc>[]) => void;
  setCreatePlanWorkflowFunc: (createPlanWorkflowFunc?: (options?: MutationFunctionOptions<CreatePlanWorkflowMutation, OperationVariables, DefaultContext, ApolloCache<unknown>> | undefined) => Promise<FetchResult<CreatePlanWorkflowMutation>>) => void;
  changeWorkflowModalDisplay: (workflowModalDisplayed: boolean) => void;
  updatePlanFieldError: (field: string, value: boolean) => void;
  setFlocsSelectionDisplayed: (displayed: boolean) => void;
};

type PlanState = State & Actions;

const getPlanUpdateState = (state: Partial<State>, planChanges: Partial<Plan>) : Partial<PlanState> => {
  const newActivePlan: Partial<Plan> = {...state.activePlan, ...planChanges};
  return {
    activePlan: newActivePlan,
    editPlan: {...newActivePlan},
  };
};

const usePlanStore = create<PlanState>()(
  immer((set, get) => ({
    ...initialState,
    updatePlanFieldError: (field: string, value: boolean) => set(state => {
      state.planFieldErrors = {
        ...state.planFieldErrors,
        [field]: value,
      };
    }),
    changeWorkflowModalDisplay(workflowModalDisplayed) {
      set({workflowModalDisplayed});
    },
    setCreatePlanWorkflowFunc(createPlanWorkflowFunc) {
      set({createPlanWorkflowFunc});
    },
    setItems(items) {
      set({items});
    },
    setEvents(events) {
      set({events});
    },
    setFlocs(flocs) {
      set({flocs});
    },
    setFetchPlan(fetchPlan) {
      set({fetchPlan});
    },
    setSimulatingEvents(simulatingEvents) {
      set({simulatingEvents});
    },
    changeSimulated(isSimulated, simulatingEvents?: Partial<Event>[]) {
      const newState: Partial<State> = {
        isSimulated,
      };
      if (!isSimulated) {
        newState.simulatingEvents = undefined;
      } else {
        newState.simulatingEvents = simulatingEvents;
      }

      set(newState);
    },
    setFlocToDelete: (flocToDelete?: Partial<PlanFloc>) => set({
      flocToDelete,
    }),
    deletePlanFloc: (flocToDelete?: Partial<PlanFloc>, plan?: Partial<Plan>) => set(state => {
      const relatedPlan: Partial<Plan>|undefined = plan ?? state.activePlan;
      const deletedFloc: Partial<PlanFloc> | undefined = {...(flocToDelete ?? state.flocToDelete)};
      const newState: Partial<State> = {};
      if (relatedPlan && deletedFloc) {
        if (deletedFloc.id === state.flocToDelete?.id) {
          newState.flocToDelete = undefined;
        }

        const planFlocs = relatedPlan.planFlocs?.filter((item: PlanFloc) => item.id !== deletedFloc.id!);

        if (relatedPlan.id === state.activePlan?.id) {
          Object.assign(newState, getPlanUpdateState(get(), {
            planFlocs,
            _count: {
              ...state.activePlan!._count,
              planFlocs: planFlocs?.length ?? 0,
            } as PlanCount,
          }));
        } else {
          relatedPlan.planFlocs = planFlocs;
        }
      }

      return newState;
    }),
    cancelEditData: () => set(state => {
      const newState: Partial<State> = {
        updatePlanData: {},
        planFieldErrors: {},
        editPlan: {...state.activePlan},
      };
      if (state.activePlan?.id === OBJ_NEW_ID) {
        newState.activePlan = undefined;
        newState.editPlan = undefined;
      }

      return newState;
    }),
    resetData() {
      set({
        ...initialState,
      });
    },
    isSaved() {
      const state = get();
      if (state.activePlan) {
        return !(state.updatePlanData && Object.keys(state.updatePlanData).length > 0);
      }

      return true;
    },

    hasFieldError(field: string, forceCheck?: boolean) {
      const state = get();
      if (state.activePlan) {
        if (!Object.keys(state.updatePlanData ?? {}).includes(field) && !forceCheck) {
          return false;
        }

        if (state.planFieldErrors?.[field] === true) {
          return true;
        }

        switch (field) {
          case 'plan':
            const plan = (state.editPlan?.plan ?? '').trim();
            return plan.length > MAX_LENGTH_VALIDATORS.PLAN || plan === '';
          case 'notes':
            const notes = (state.editPlan?.notes ?? '').trim();
            return notes.length > MAX_LENGTH_VALIDATORS.NOTES;
          case 'description':
            const description = (state.editPlan?.description ?? '').trim();
            return description.length > MAX_LENGTH_VALIDATORS.DESCRIPTION || description === '';
          case 'revision':
            const revision = (state.editPlan?.revision ?? '').trim();
            return revision.length > MAX_LENGTH_VALIDATORS.REVISION;
          case 'flocId':
            const flocId = state.editPlan?.flocId;
            return !flocId;
          default:
            break;
        }
      }

      return false;
    },
    hasError() {
      const state = get();
      if (state.activePlan) {
        return ['description', 'revision', 'plan', 'notes', 'flocId'].some((field: string) => state.hasFieldError(field, true));
      }

      return false;
    },
    setActivePlan(activePlan, isNew?: boolean) {
      set({
        activePlan,
        editPlan: activePlan ? {...activePlan} : undefined,
        updatePlanData: activePlan?.id === OBJ_NEW_ID ? {status: ESCHEDULING_PLAN_STATUS.WSCHED} as PlanUncheckedCreateInput : undefined,
        planFieldErrors: undefined,
        creatingPlan: activePlan && isNew,
      });
    },
    updatePlanDataField: (field: string, value: unknown) => set(state => {
      if (field === 'startDate' && typeof value === 'string' && value !== '') {
        value = convertDateDefaultStrToDateObj(value);
      }

      if (field === 'startDate' && !value) {
        value = null;
      }

      if (field === 'functionalLocation') {
        field = 'flocId';
        value = (value as PlanFloc)?.id;
      } else if (field === 'type') {
        field = 'typeId';
        value = (value as Plan)?.id;
      } else if (field === 'strategy') {
        field = 'strategyId';
        value = (value as Plan)?.id;
      } else if (field === 'plannerGroup') {
        field = 'plannerGroupId';
        value = (value as Plan)?.id;
      } else if (field === 'mainWorkCenter') {
        field = 'mainWorkCenterId';
        value = (value as Plan)?.id;
      }

      return {
        updatePlanData: {
          ...state.updatePlanData,
          [field]: state.activePlan?.id === OBJ_NEW_ID ? value : {
            set: value,
          },
        },
        planFieldErrors: {
          ...state.planFieldErrors,
          [field]: false,
        },
      };
    }),
    setUpdatePlanData(updatePlanData: PlanUncheckedUpdateInput | PlanUncheckedCreateInput) {
      set({updatePlanData});
    },
    setEditPlan(editPlan) {
      set({editPlan});
    },
    updatePlan: (plan: Partial<Plan>, isNew?: boolean) => set(_state => {
      const {activePlan} = get() ?? {};
      const planId: number = isNew ? OBJ_NEW_ID : plan.id!;
      const newState: Partial<State> = {};

      if (activePlan && planId === activePlan.id!) {
        newState.activePlan = {
          ...activePlan,
          ...plan,
        };
        newState.editPlan = {...newState.activePlan};
      }

      return newState;
    }),
    deletePlan: (plan?: Partial<Plan>) => set(state => {
      const deletedPlan: Partial<Plan>|undefined|null = plan ?? state.activePlan;
      const newState: Partial<State> = {};
      if (deletedPlan) {
        newState.deletePlanModalOpen = false;
        if (deletedPlan === state.activePlan) {
          newState.activePlan = undefined;
        }
      }

      return newState;
    }),
    changeDeletePlanModalDisplay: (isOpen: boolean) => set({
      deletePlanModalOpen: isOpen,
    }),
    setFlocsSelectionDisplayed: (displayed: boolean) => set({
      flocsSelectionDisplayed: displayed,
    }),
  })),
);

export default usePlanStore;
