import {useLazyQuery} from '@apollo/client';
import AppGrid from '@app/components/Common/AppGrid';
import AppCheckbox from '@app/components/Common/Form/Checkbox';
import IdwgModal from '@app/components/Modal/Documentation/idwg';
import {GetAllInspectionDrawingViewsQuery, InspectionDrawingView} from '@app/graphql/__types__/graphql';
import {INSPECTION_DRAWING_VIEWS_GET_ALL} from '@app/graphql/requests';
import {useDataStore} from '@app/stores/data';
import {useInspectionDrawingStore} from '@app/stores/methodEngineering/inspectionDrawing';
import {OBJ_NEW_ID, ROUTE_DOCUMENTATION_IDWG_DETAIL} from '@app/utils/constants';
import {generatePathWithBaseUrl, textEllipsisCellRenderer, textEllipsisWrapper} from '@app/utils/functions';
import useUserPermissions from '@app/utils/hooks/useUserPermissions';
import {CellRenderer, GridProps} from '@holis/react-ui';
import {RadButton} from '@holis/react-ui/rad';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {LuPlusCircle} from 'react-icons/lu';
import {MdOpenInNew} from 'react-icons/md';
import {generatePath, useNavigate} from 'react-router-dom';

const GRID_NAME = 'methodEngineering-inspectionDrawingsGrid';

export default function IdwgPage() {
  const navigate = useNavigate();
  const {selectedSites} = useDataStore();
  const {inspectionDrawingViews, setInspectionDrawingViews, creatingIdwg, setActiveInspectionDrawing, setFetchInspectionDrawings} = useInspectionDrawingStore();
  const {t} = useTranslation();
  const [getIdwgListApi, {loading, error}] = useLazyQuery<GetAllInspectionDrawingViewsQuery>(INSPECTION_DRAWING_VIEWS_GET_ALL);
  const prms = useUserPermissions();

  const fetchInspectionDrawings = () => {
    if (selectedSites?.length) {
      getIdwgListApi({
        variables: {
          sites: selectedSites,
          orderBy: [
            {
              id: 'desc',
            },
          ],
        },
        fetchPolicy: 'no-cache',
      }).then(queryResults => setInspectionDrawingViews((queryResults.data?.inspectionDrawingViews ?? [])));
    } else {
      setInspectionDrawingViews([]);
    }
  };

  useEffect(() => {
    fetchInspectionDrawings();
    setFetchInspectionDrawings(fetchInspectionDrawings);
  }, [selectedSites]);

  const handleNewIdwg = () => {
    setActiveInspectionDrawing({
      id: OBJ_NEW_ID,
    }, true);
  };

  const actionCellRenderer: CellRenderer<Partial<InspectionDrawingView>> = (_, node) => (
    <RadButton
      size='icon'
      variant='outline'
      className='size-fit p-1'
      onClick={e => {
        const idwgNumber = node.data.idwg!;
        if (e.ctrlKey || e.metaKey) {
          window.open(generatePathWithBaseUrl(generatePath(ROUTE_DOCUMENTATION_IDWG_DETAIL.replace(':number', idwgNumber))), '_blank');
          return;
        }

        return navigate(ROUTE_DOCUMENTATION_IDWG_DETAIL.replace(':number', idwgNumber));
      }}
    >
      <MdOpenInNew size={18}/>
    </RadButton>
  );

  const gridProps: GridProps<Partial<InspectionDrawingView>> = {
    columns: [
      {
        field: undefined,
        title: '',
        defaultState: {
          width: 75,
        },
        cellRenderer: actionCellRenderer,
        filter: false,
        cannotHide: true,
      },
      {
        field: 'id',
        title: 'Id',
        type: 'number',
        defaultState: {
          width: 106,
          hidden: true,
        },
      },
      {
        field: 'sector_site_site',
        title: t('label.site'),
        type: 'set',
        defaultState: {
          width: 100,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'sector_sector',
        title: t('label.sector'),
        type: 'set',
        defaultState: {
          width: 105,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'idwg',
        title: t('label.reference'),
        type: 'string',
        defaultState: {
          width: 250,
          sort: 'asc',
        },
        cellRenderer: 'document',
        cannotHide: true,
      },
      {
        field: 'description',
        title: t('label.description'),
        type: 'string',
        defaultState: {
          width: 461,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'type_type',
        title: t('label.type'),
        type: 'set',
        defaultState: {
          width: 135,
        },
        cannotHide: true,
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'revision',
        title: t('label.revision'),
        type: 'string',
        defaultState: {
          width: 115,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
      {
        field: 'flagStatus',
        title: t('label.status'),
        type: 'set',
        defaultState: {
          width: 170,
          hidden: true,
        },
        cellRenderer: 'flagStatus',
      },
      {
        field: 'docName',
        title: t('label.docName'),
        type: 'string',
        defaultState: {
          width: 400,
        },
        cellRenderer: textEllipsisCellRenderer,
      },
    ],
    cellRenderers: {
      document: val => textEllipsisWrapper(<span className='font-bold'>{val}</span>),
      damage: val => <AppCheckbox disabled checked={!!val}/>,
      flagStatus: val => textEllipsisWrapper(val ? t(`label.flagStatus.${val}`) : ''),
    },
    data: inspectionDrawingViews ?? [],
  };

  return (
    <div className='h-full'>
      {creatingIdwg && (
        <IdwgModal
          isOpen={creatingIdwg}
          onOpenChange={isOpen => {
            if (!isOpen) {
              setActiveInspectionDrawing(undefined);
            }
          }}
        />
      )}

      {(error && <div>{error.message}</div>) || <AppGrid
        name={GRID_NAME}
        fileNameOnExport={t('label.inspectionDrawing')}
        title={t('label.inspectionDrawing')}
        isLoading={loading}
        {...gridProps}
        actions={prms.drawings.create && (
          <RadButton
            size='sm'
            className='flex gap-1 text-sm'
            onClick={handleNewIdwg}
          >
            <LuPlusCircle/> {t('label.addDrawing')}
          </RadButton>
        )}
      />}
    </div>
  );
}
