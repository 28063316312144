import {gql} from '@apollo/client/core';
import {DAMAGES_COMMON_FIELDS, WORKORDER_EVENT_DAMAGES_COMMON_FIELDS, WORKORDER_EVENTS_COMMON_FIELDS} from '../fragments';

export const WORKORDER_EVENT_DAMAGES_CREATE = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
mutation createEventDamage($dmgId: Int!, $evtId: Int!) {
  createOneEventDamage (data: {dmgeId: $dmgId, wrkoId: $evtId}) {
    ...EventDamageCommonFields
    event {
      id
      event
    }
  }
}
`;

export const WORKORDER_EVENT_DAMAGES_UPDATE_BY_ID = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
${DAMAGES_COMMON_FIELDS}
mutation UpdateEventDamageById($id: Int!, $data: EventDamageUncheckedUpdateInput!) {
  updateOneEventDamage (where: {id: $id}, data: $data) {
    ...EventDamageCommonFields
    damage {
      ...DamageCommonFields
    }
    event {
      id
      event
    }
  }
}`;

export const WORKORDER_EVENT_DAMAGES_DELETE = gql`
mutation deleteEventDamage($dmgId: Int!, $evtId: Int!) {
  deleteOneEventDamage (where: {wrkoId_dmgeId: {dmgeId: $dmgId, wrkoId: $evtId}}) {
    id
  }
}`;

export const WORKORDER_EVENT_DAMAGES_GET_BY_DMG_ID = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
query GetEventDamagesByDmgId($dmgId: Int!, $orderBy: [EventDamageOrderByWithRelationInput!]) {
  eventDamages (where: {dmgeId: {equals: $dmgId}}, orderBy: $orderBy) {
    ...EventDamageCommonFields
  }
}`;

export const WORKORDER_EVENT_DAMAGES_GET_BY_EVT_ID = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
${DAMAGES_COMMON_FIELDS}
query GetEventDamagesByEvtId($evtId: Int!, $orderBy: [EventDamageOrderByWithRelationInput!]) {
  eventDamages (where: {wrkoId: {equals: $evtId}}, orderBy: $orderBy) {
    ...EventDamageCommonFields,
    damage{
      ...DamageCommonFields
    }
  }
}`;

export const WORKORDER_EVENT_DAMAGES_CREATE_MANY = gql`
mutation CreateEventDamages($data: [EventDamageCreateManyInput!]!) {
  createManyEventDamage(data: $data) {
    count
  }
}`;

export const WORKORDER_EVENT_DAMAGES_DELETE_MANY = gql`
mutation DeleteEventDamages($ids: [Int!]) {
  deleteManyEventDamage (where: {id: {in: $ids}}) {
    count
  }
}`;

export const WORKORDER_EVENT_DAMAGES_GET_LATEST_BY_DMG_ID = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
query GetLatestEventDamageByDmgId($dmgId: Int!) {
  findFirstEventDamage(where: {dmgeId: {equals: $dmgId}}, orderBy:[{reportingDate: {sort: desc}}, {id: desc}]) {
    ...EventDamageCommonFields
  }
}`;

export const WORKORDER_EVENT_DAMAGES_GET_COUNTER = gql`
query GetWorkorderEventDamagesCounter {
  aggregateEventDamage {
    _count {
      id
    }
  }
}
`;

export const WORKORDER_EVENT_DAMAGES_GET_BY_ID = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
${WORKORDER_EVENTS_COMMON_FIELDS}
query GetEventDamageById($id: Int!) {
  eventDamage (where: {id: $id}) {
    ...EventDamageCommonFields
    event {
      ...EventCommonFields
    }
  }
}`;

export const WORKORDER_EVENT_DAMAGES_GET_BY_EVT_DMG_ID = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
query GetEventDamageByEvtDmgId($evtId: Int!, $dmgId: Int!) {
  eventDamage (where: {wrkoId_dmgeId: {dmgeId: $dmgId, wrkoId: $evtId}}) {
    ...EventDamageCommonFields
  }
}`;

export const WORKORDER_EVENT_DAMAGES_GET_LATEST_WITH_QUALREADING_NON_EMPTY_BY_DMG_ID = gql`
${WORKORDER_EVENT_DAMAGES_COMMON_FIELDS}
query GetLatestEventDamageWithQualReadingNonEmptyByDmgId($dmgId: Int!) {
  lastEventDamageWithQualReading (dmgId: $dmgId) {
    ...EventDamageCommonFields
    event {
      id
      event
    }
  }
}`;
