import {gql} from '@apollo/client';

export const SCHEDULING_PLAN_WORKFLOWS_COMMON_FIELDS = gql`
  fragment PlanWorkflowCommonFields on PlanWorkflow {
    id
    planId
    status
    actionDate
    userLogin
    description
  }
`;
