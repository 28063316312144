import {Cml, Damage, Event, EventCml, EventDamage, FunctionalLocation, InspectionDrawing, Notification} from '@app/graphql/__types__/graphql';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import FormGroupHeader from '@app/components/Common/Form/FormGroupHeader';
import SearchBar from '@app/components/Common/SearchBar';
import {RadDropdownMenuItem} from '@holis/react-ui/rad';
import {LuPlusSquare, LuList, LuCornerDownRight} from 'react-icons/lu';
import InspectionDrawingAccordion from '../Accordion';
type TObjectItemListByDrawing<T = Partial<Cml | EventCml | Damage | EventDamage>> = Readonly<{
  objectItem: Partial<Event | Notification | FunctionalLocation>;
  drawings?: Partial<InspectionDrawing>[];
  handleDisplayDrawing?: (idwg: Partial<InspectionDrawing>) => void;
  readonly?: boolean;
  selectedDrawing?: Partial<InspectionDrawing>;
  searchInput?: (value: string) => void;
  changeItemManageModalDisplay?: (displayed: boolean) => void;
  changeItemListModalDisplay?: (displayed: boolean) => void;
  renderItem: (item: T) => React.ReactNode;
  items?: T[]
  itemsByIdwg?: Record<string, T[]>;
  title?: React.ReactNode;
  actions?: React.ReactNode;
}>;

export default function ObjectItemListByDrawing<T = Partial<Cml | EventCml | Damage | EventDamage>>({itemsByIdwg, items, drawings, handleDisplayDrawing, searchInput, changeItemManageModalDisplay, changeItemListModalDisplay, selectedDrawing, renderItem, title, actions}: TObjectItemListByDrawing<T>) {
  const {t} = useTranslation();
  const [grouped, setGrouped] = useState<boolean>(true);

  return (
    <>
      <FormGroupHeader
        menuItems={[
          <RadDropdownMenuItem key='group-ungroup' onClick={() => setGrouped(!grouped)}><LuCornerDownRight className='mr-2'/> {t('label.groupUngroup')}</RadDropdownMenuItem>,
          ...(changeItemManageModalDisplay ? [<RadDropdownMenuItem key='manage-item' onClick={() => changeItemManageModalDisplay(true)}><LuPlusSquare className='mr-2'/> {t('label.manage')}</RadDropdownMenuItem>] : []),
          ...(changeItemListModalDisplay ? [<RadDropdownMenuItem key='display-list' onClick={() => changeItemListModalDisplay(true)}><LuList className='mr-2'/> {t('label.displayList')}</RadDropdownMenuItem>] : []),
        ]}
        actions={
          <div className='flex items-center'>
            {actions}
            {searchInput && <SearchBar
              className='w-[300px]'
              onChange={e => searchInput(e.target?.value)}
            />}
          </div>
        }>
        <div className='flex items-center'>
          {title}
        </div>
      </FormGroupHeader>

      <div className='mt-3'>

        {
          grouped

            ? <InspectionDrawingAccordion
              selectedDrawing={selectedDrawing}
              drawings={drawings}
              getDrawingElementsCount={idwg => itemsByIdwg?.[idwg.id!]?.length ?? 0}
              renderDrawingElements={idwg => itemsByIdwg?.[idwg.id!]?.map(item => renderItem(item))}
              onDisplayDrawingClick={handleDisplayDrawing}
            />

            : <div className='flex flex-col gap-1'>
              {items?.map(item => renderItem(item))}
            </div>
        }
      </div>
    </>
  );
}
