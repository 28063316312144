import {LuCamera, LuMapPin} from 'react-icons/lu';
import ItemCard from '..';
import ClassPositionContainer from '../ClassPositionContainer';
import MeasurementValueContainer from '../MeasurementValueContainer';
import {TInspectionObject} from '@app/types/app';
import {RadBadge, RadButton} from '@holis/react-ui/rad';
import {useMutation} from '@apollo/client';
import {Cml, UpdateCmlByIdMutation} from '@app/graphql/__types__/graphql';
import {CMLS_UPDATE_BY_ID} from '@app/graphql/requests';
import {useLayoutStore} from '@app/stores/layout';
import AppNotifications from '@app/services/notification';
import {useTranslation} from 'react-i18next';
import {MouseEvent, ReactNode, useEffect, useState} from 'react';
import useIdwgStore, {IDWG_PREFIX} from '@app/stores/idwg';
import {twMerge} from 'tailwind-merge';

type TCmlIdwgCard = Readonly<TInspectionObject> & Readonly<{
  detailPanelComponent?: ReactNode;
  detailPanelComponentShown?: boolean;
  detailPanelComponentWidth?: string;
  onClick?: () => void;
}>;

export default function CmlIdwgCard({hasPicture, position, display2d: display2dInit, title, description, measurementColor, measurementValue, className, id, onDisplay2dChanged, idwgId, drawingStoreId, technique, floc, ...restProps}: TCmlIdwgCard) {
  const {startLoading, stopLoading} = useLayoutStore();
  const [display2d, setDisplay2d] = useState<boolean>();
  const {t} = useTranslation();
  const [updateCmlApi] = useMutation<UpdateCmlByIdMutation>(CMLS_UPDATE_BY_ID);
  const {changeCmlDisplay2dUpdated} = useIdwgStore(drawingStoreId ?? `${IDWG_PREFIX}${idwgId}`);

  const handleChangeDisplay2d = (e: MouseEvent) => {
    e.stopPropagation();
    startLoading();
    updateCmlApi({
      variables: {
        id: id!,
        data: {
          display2d: {
            set: !display2d,
          },
        },
      },
    }).then(result => {
      const newCml = (result.data?.updateOneCml as Partial<Cml>);
      const newDisplay2d = !!newCml.display2d;
      setDisplay2d(newDisplay2d);
      changeCmlDisplay2dUpdated(newCml);
      onDisplay2dChanged?.(newDisplay2d);
    }).catch(_error => {
      AppNotifications.error(t('message.error.default.title'));
    }).finally(() => {
      stopLoading();
    });
  };

  useEffect(() => {
    setDisplay2d(!!display2dInit);
  }, [display2dInit]);

  return (
    <ItemCard className={className} onClick={restProps.onClick}>

      <ClassPositionContainer class={restProps.class} position={position}/>

      <div className='flex-1 flex'>
        <div className='w-full overflow-hidden'>
          <div className='w-full text-sm flex flex-col gap-1 float-left'>
            <div className='font-bold flex gap-1 items-center'>
              {typeof display2d === 'boolean' && <RadButton variant='ghost' size='icon' className='h-8 w-8' onClick={handleChangeDisplay2d}>
                <LuMapPin size={16} className={display2d ? 'text-primary' : 'text-gray-400'}/>
              </RadButton>}
              {title}
            </div>
            <div className=''>{description}</div>
            <div className='text-xs flex mt-1 mb-2'>
              <RadBadge className='bg-gray-400 hover:bg-gray-400 py-0'>{technique ?? '-'}</RadBadge>
              <div>&nbsp; | &nbsp;</div>
              <span>{floc ?? '-'}</span>
            </div>
          </div>
          {restProps.detailPanelComponent && <div
            className={
              twMerge('bg-gray-100 rounded-md border border-gray-200 shadow-xl pl-2 ml-[100%] h-full transition-transform duration-700 ease-in-out',
                restProps.detailPanelComponentShown && '-translate-x-full')
            }
            style={{width: restProps.detailPanelComponentWidth ?? '200px'}}
          >
            {restProps.detailPanelComponent}
          </div>}
        </div>
      </div>

      <div className='flex gap-2 items-center h-full'>
        {!!hasPicture && <LuCamera className='text-primary' size={20}/>}
        <MeasurementValueContainer
          className='self-stretch'
          measurementValue={measurementValue}
          measurementColor={measurementColor}
          measurementValueUnit={restProps.measurementUnit}
          measurementReadingValue={restProps.measurementReadingValue}
        />
      </div>

    </ItemCard>
  );
}
