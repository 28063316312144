import {gql} from '@apollo/client';

export const NOTIFICATION_REF_MATRICES_GET_COUNTER = gql`
query GetRefNotifMatricesCounter {
  aggregateRefNotifMatrix {
    _count {
      _all
    }
  }
}
`;
