export default class PermissionMenu {
  public static resourceName = 'menu';

  public methengFloc = false;
  public methengInsp = false;
  public methengCML = false;

  public docDocument = false;
  public docDwg = false;

  public insprogEvent = false;
  public insprogPlan = false;
  public insprogReport = false;
  public insprogImport = false;

  public dwimsDashboard = false;
  public dwimsPrep = false;
  public dwimsExec = false;
  public dwimsReport = false;

  public anomalyNotif = false;
  public anomalyDamage = false;

  public adminUserrole = false;
  public adminAsset = false;
  public adminRef = false;
  public adminImport = false;
}
