import DrawingObjectSelectionConfirmModal from '@app/components/Modal/Confirm/DrawingObjectSelection';
import {Cml, CreateEventCmlsMutation, Damage, DeleteEventCmlsMutation, EventCml, EventInspectionDrawing, GetCmlsByFlocIdsQuery, InspectionDrawing} from '@app/graphql/__types__/graphql';
import {useEventStore} from '@app/stores/event';
import {NO_DRAWING_ID} from '@app/utils/constants';
import {useEffect, useState} from 'react';
import CmlCard from '../CmlBlock/CmlCard';
import {useTranslation} from 'react-i18next';
import {LuMapPin} from 'react-icons/lu';
import {useLazyQuery, useMutation} from '@apollo/client';
import {CMLS_GET_BY_FLOC_IDS, WORKORDER_EVENT_CMLS_CREATE_MANY, WORKORDER_EVENT_CMLS_DELETE_MANY} from '@app/graphql/requests';
import {TDbId, TObjId} from '@app/types/app';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';

export default function EventCmlSelectionModal() {
  const {t} = useTranslation();
  const {startLoading, stopLoading} = useLayoutStore();
  const [drawings, setDrawings] = useState<Partial<InspectionDrawing>[]>();
  const {editEvent, eventFlocCmls, activeEvent, eventCmls, eventInspectionDrawings, changeCmlSelectionModalDisplay, cmlSelectionModalDisplayed, eventFlocIds, setEventFlocCmls, fetchEventCmls} = useEventStore();

  const [addEventCmlsApi] = useMutation<CreateEventCmlsMutation>(WORKORDER_EVENT_CMLS_CREATE_MANY);
  const [deleteEventCmlsApi] = useMutation<DeleteEventCmlsMutation>(WORKORDER_EVENT_CMLS_DELETE_MANY);

  const [getEventFlocCmlsApi, {loading}] = useLazyQuery<GetCmlsByFlocIdsQuery>(CMLS_GET_BY_FLOC_IDS, {
    fetchPolicy: 'no-cache',
  });

  const addItems = (ids: TDbId[]) => addEventCmlsApi({
    variables: {
      data: ids.map((cmlId: TDbId) => ({
        wrkoId: activeEvent!.id!,
        cmlId,
      })),
    },
  });

  const deleteItems = (eventCmlIds: TDbId[]) => deleteEventCmlsApi({
    variables: {
      ids: eventCmlIds,
    },
  });

  const handleValidateSelection = async (selectedItems: Partial<TObjId>[], newSelectedIds: TDbId[], oldSelectedIds: TDbId[]) => {
    const promises = [];
    if (oldSelectedIds.length) {
      const eventCmlIds = (eventCmls?.filter((item: Partial<EventCml>) => item.cmlId && oldSelectedIds.includes(item.cmlId)).map((item: Partial<EventCml>) => item.id) ?? []) as TDbId[];
      if (eventCmlIds.length) {
        promises.push(deleteItems(eventCmlIds));
      }
    }

    if (newSelectedIds.length) {
      promises.push(addItems(newSelectedIds));
    }

    if (promises.length) {
      startLoading();
      try {
        for (const asyncCall of promises) {
          await asyncCall;
        }

        fetchEventCmls?.();
        changeCmlSelectionModalDisplay(false);
        AppNotifications.success(t('message.success.eventCmlsUpdated'));
      } catch (err) {
        AppNotifications.error(t('message.error.default.title'));
      }

      stopLoading();
    } else {
      changeCmlSelectionModalDisplay(false);
    }
  };

  const getEventFlocCmls = () => {
    if (Array.isArray(eventFlocIds) && eventFlocIds.length) {
      getEventFlocCmlsApi({
        variables: {
          flocIds: eventFlocIds,
        },
        fetchPolicy: 'no-cache',
      }).then(queryResult => {
        setEventFlocCmls((queryResult.data?.cmls ?? []) as Partial<Cml>[]);
      });
    } else {
      setEventFlocCmls([]);
    }
  };

  useEffect(() => {
    const idwgs = eventInspectionDrawings?.map((evtDrawing: Partial<EventInspectionDrawing>) => evtDrawing.inspectionDrawing!) ?? [];
    setDrawings([
      {
        id: NO_DRAWING_ID,
      },
      ...idwgs,
    ]);
  }, [eventInspectionDrawings]);

  useEffect(() => {
    getEventFlocCmls();
  }, [eventFlocIds]);

  return (
    <DrawingObjectSelectionConfirmModal
      hasItems
      isMultiple
      dmgHidden
      isLoading={loading}
      objectItem={editEvent}
      title={t('label.manageCMLs')}
      description={t('label.manageCMLsDescription')}
      headerTitle={<div className='flex items-center text-primary'><LuMapPin/> {t('label.cml')}</div>}
      drawings={drawings}
      open={cmlSelectionModalDisplayed}
      items={eventFlocCmls ?? []}
      drawingStoreIdSuffix='cml-selection-modal'
      selectedIds={eventCmls?.map(item => item.cmlId!) ?? []}
      renderItem={(item: Partial<Cml | Damage>, drawingStoreId: string) => (
        <CmlCard
          hasMenuContext displayLastQualReadingWithColor drawingStoreId={drawingStoreId}
          eventCml={item as Partial<Cml>}
          objectItem={editEvent}
          onDisplay2dChanged={getEventFlocCmls}/>
      )}
      onValidate={handleValidateSelection}
      onClose={() => changeCmlSelectionModalDisplay(false)}/>
  );
}
