import qrc from 'qrcode';
import {io, Socket} from 'socket.io-client';

export class OptimusClientConfig {
  static current: OptimusClientConfig = new OptimusClientConfig();
  apiBaseUrl = '/api';
  apiRestUrl = '/rest';
  fileBaseUrl = '/files';
  pictureBaseUrl = '/pictures';
  fileUploadUrl = '/import';
  fileDownloadUrl = '/export';
  reportBaseUrl = '/app/report';
  authServerUrl = 'https://total.auth.staging.holis.cloud';
  authClientId = '00000000-0000-0000-0000-000000000000';
  projectName = '';
  assetName = '';
  scopes = ['username', 'firstname', 'lastname', 'email'];
  exportEvents = false;

  mobileAppClientId = '';
  mobileSyncUrl = '';
  mobileUpdateUrl = '';
  webSocketPath = '';
  socket: Socket | null = null;
}

class OptimusClientConfigError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'OptimusClientConfigError';
  }
}

export const fetchClientConfig = async () => {
  console.log(import.meta.env.BASE_URL + 'client-config.json');
  const r = await fetch(import.meta.env.BASE_URL + 'client-config.json');
  if (!r.ok) {
    throw new OptimusClientConfigError('Failed to fetch client config: client-config.json');
  }

  try {
    const override = await r.json();
    OptimusClientConfig.current = Object.assign(new OptimusClientConfig(), override);
  } catch (e) {
    throw new OptimusClientConfigError('Failed to parse client config: ' + (e as Error).message);
  }

  const url = new URL(OptimusClientConfig.current.apiBaseUrl!);
  if (url) {
    OptimusClientConfig.current.socket = io(url.origin, {
      path: OptimusClientConfig.current.webSocketPath || undefined,
      withCredentials: true,
    });
  }

  return OptimusClientConfig.current;
};

export const getMobileConfQrCode = async () => {
  const conf = OptimusClientConfig.current;
  if (!conf.mobileUpdateUrl || !conf.mobileAppClientId || !conf.mobileSyncUrl) {
    throw new Error('Mobile configuration is not set');
  }

  const datas = {
    s: conf.mobileSyncUrl,
    u: conf.mobileUpdateUrl,
    i: conf.mobileAppClientId,
    a: conf.authServerUrl,
    c: conf.scopes.join(' '),
  };
  return qrc.toDataURL(JSON.stringify(datas));
};
