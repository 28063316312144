import Astro from '@assets/images/Astro_Checkis.svg?url';

import {
  RadAvatar,
  RadAvatarImage,
  RadDropdownMenu,
  RadDropdownMenuContent,
  RadDropdownMenuItem,
  RadDropdownMenuLabel,
  RadDropdownMenuSeparator,
  RadDropdownMenuTrigger,
} from '@holis/react-ui/rad';

import {useHolisAuth} from '@holis/auth-client-react';
import {LuExternalLink, LuFile, LuLogOut, LuQrCode} from 'react-icons/lu';

import MobileConfigQrCodeModal from '@app/components/Modal/MobileConfigQrCodeModal';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function UserDropdown() {
  const {t} = useTranslation();
  const {user, logout} = useHolisAuth();
  const [showMobileConfig, setShowMobileConfig] = useState(false);

  return (
    <>
      <RadDropdownMenu>
        <RadDropdownMenuTrigger className='rounded-full'>
          <RadAvatar className='h-8 w-8'>
            <RadAvatarImage src={Astro}/>
          </RadAvatar>
        </RadDropdownMenuTrigger>

        <RadDropdownMenuContent align='end' className='w-48 border-border'>
          <RadDropdownMenuLabel className='text-muted-foreground'>
            {user?.username}
          </RadDropdownMenuLabel>

          <RadDropdownMenuSeparator/>

          <RadDropdownMenuLabel>
            {t('label.menu.userMenu.action')}
          </RadDropdownMenuLabel>

          <RadDropdownMenuItem className='gap-2 cursor-pointer' onClick={() => window.open('https://docs.holis.cloud/AIM-Asset-Integrity-Management-17591369912880dc89e2c4fe793f4cdf?pvs=73')}>
            <div className='flex gap-2 items-center flex-1'><LuFile/> {t('label.menu.userMenu.documentation')}</div>
            <div className='opacity-50'><LuExternalLink/></div>
          </RadDropdownMenuItem>

          <RadDropdownMenuItem className='gap-2 cursor-pointer' onClick={() => setShowMobileConfig(true)}>
            <LuQrCode/> {t('label.menu.userMenu.mobileConfiguration')}
          </RadDropdownMenuItem>

          <RadDropdownMenuItem className='gap-2 text-red-400 cursor-pointer' onClick={logout}>
            <LuLogOut/>
            {t('label.menu.userMenu.logout')}
          </RadDropdownMenuItem>
        </RadDropdownMenuContent>
      </RadDropdownMenu>
      { showMobileConfig && <MobileConfigQrCodeModal isOpen onClose={() => setShowMobileConfig(false)}/>}
    </>
  );
}
