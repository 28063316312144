import {useLazyQuery, useMutation} from '@apollo/client';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {DeletePlanByIdMutation, GetOneParameterByCodeQuery, Parameter, UpdatePlanByIdMutation} from '@app/graphql/__types__/graphql';
import {APP_PARAMTERS_GET_ONE_BY_CODE, SCHEDULING_PLANS_DELETE_BY_ID, SCHEDULING_PLANS_UPDATE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import usePlanStore from '@app/stores/plan';
import {APP_PARAMETER_DELETE_PENDING_EVENTS} from '@app/utils/constants';
import {ESCHEDULING_PLAN_STATUS, EWORKORDER_EVENT_STATUS} from '@app/utils/enums';
import {useHolisAuth} from '@holis/auth-client-react';
import {useTranslation} from 'react-i18next';

type TDeletePlanModal = IConfirmModal & {
  readonly onPlanDeleted?: () => void;
  readonly onPlanCancelled?: () => void;
};

export default function DeletePlanModal(props: TDeletePlanModal) {
  const {changeDeletePlanModalDisplay, deletePlan, activePlan, events, createPlanWorkflowFunc} = usePlanStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [deletePlanApi] = useMutation<DeletePlanByIdMutation>(SCHEDULING_PLANS_DELETE_BY_ID);
  const [getOneParameterByCodeApi] = useLazyQuery<GetOneParameterByCodeQuery>(APP_PARAMTERS_GET_ONE_BY_CODE);
  const [updatePlanByIdApi] = useMutation<UpdatePlanByIdMutation>(SCHEDULING_PLANS_UPDATE_BY_ID);
  const {t} = useTranslation();
  const {user} = useHolisAuth();

  const onConfirm = async () => {
    startLoading();

    try {
      const prmDeletePendingEvents = await getOneParameterByCodeApi({
        variables: {code: APP_PARAMETER_DELETE_PENDING_EVENTS},
      });
      let delPlan = false;
      if ((prmDeletePendingEvents.data?.parameter as Parameter)?.value === 'true') {
        delPlan = events?.every(event => event.status === EWORKORDER_EVENT_STATUS.PEND_WAITING_RELEASE) ?? false;
      }

      if (delPlan) {
        await deletePlanApi({
          variables: {id: activePlan!.id},
        });
        deletePlan();
      } else {
        await updatePlanByIdApi({
          variables: {
            id: activePlan!.id,
            data: {
              status: {set: ESCHEDULING_PLAN_STATUS.CANCEL},
            },
          },
        });
        await createPlanWorkflowFunc?.({
          variables: {
            data: {
              planId: activePlan!.id,
              status: `${activePlan!.status}|${ESCHEDULING_PLAN_STATUS.CANCEL}`,
              actionDate: new Date(),
              userLogin: user?.username,
              description: t('label.planActions.descriptions.cancel_plan'),
            },
          },
        });
      }

      if (delPlan) {
        AppNotifications.success(t('message.success.deletePlan'));
        props.onPlanDeleted?.();
      } else {
        AppNotifications.success(t('message.success.cancelPlan'));
        props.onPlanCancelled?.();
      }
    } catch {
      AppNotifications.error(t('message.error.deletePlan'));
    } finally {
      stopLoading();
    }
  };

  return (
    <WarningConfirmModal
      description={t('message.questions.deletePlan')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeletePlanModalDisplay(false)}
      {...props}
    />
  );
}
