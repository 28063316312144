import {useMutation} from '@apollo/client';
import {IConfirmModal} from '@app/components/Modal/Confirm';
import WarningConfirmModal from '@app/components/Modal/Confirm/Warning';
import {DeleteItemByIdMutation} from '@app/graphql/__types__/graphql';
import {SCHEDULING_ITEMS_DELETE_BY_ID} from '@app/graphql/requests';
import AppNotifications from '@app/services/notification';
import {useLayoutStore} from '@app/stores/layout';
import useItemStore from '@app/stores/item';
import {useTranslation} from 'react-i18next';

type TDeleteItemModal = IConfirmModal;

export default function DeleteItemModal({...restProps}: TDeleteItemModal) {
  const {changeDeleteItemModalDisplay, deleteItem, activeItem} = useItemStore();
  const {startLoading, stopLoading} = useLayoutStore();
  const [deleteItemApi] = useMutation<DeleteItemByIdMutation>(SCHEDULING_ITEMS_DELETE_BY_ID);
  const {t} = useTranslation();

  const onConfirm = () => {
    startLoading();
    deleteItemApi({
      variables: {id: activeItem!.id},
    }).then(() => {
      deleteItem();
      AppNotifications.success(t('message.success.deleteItem'));
    }).catch((_err: Error) => {
      AppNotifications.error(t('message.error.deleteItem'));
    }).finally(() => {
      stopLoading();
    });
  };

  return (
    <WarningConfirmModal
      description={t('message.questions.deleteItem')}
      closeOnConfirm={false}
      onConfirm={onConfirm}
      onClose={() => changeDeleteItemModalDisplay(false)}
      {...restProps}
    />
  );
}
