import {ReactNode} from 'react';

type TDetailsPageFooter = Readonly<{
	children?: ReactNode;
	actionButtons?: ReactNode;
}>;

export default function DetailsPageFooter({children, actionButtons}: TDetailsPageFooter) {
  return (
    <div className='flex w-full justify-between items-center h-16 border-t border-t-gray-200 py-4 px-3'>
      <div className='flex items-center relative h-full gap-2 text-gray-600 font-normal'>
        {children}
      </div>
      {actionButtons && <div className='actions-button flex flex-row gap-2 items-center'>
        {actionButtons}
      </div>}
    </div>
  );
}
