import {Damage, DamageView} from '@app/graphql/__types__/graphql';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';

type State = {
  damages?: Partial<Damage>[];
  damageViews?: Partial<DamageView>[];
  fetchDamages?: () => void;
}

const initialState: State = {
  damages: undefined,
};

type Actions = {
  setDamages: (damages?: Partial<Damage>[]) => void;
  setDamageViews: (damageViews?: Partial<DamageView>[]) => void;
  setFetchDamages: (fetchDamages?: () => void) => void;
}

type DamagesState = State & Actions;

export const useDamagesStore = create<DamagesState>()(
  immer((set, _get) => ({
    ...initialState,
    setDamages(damages) {
      set({damages});
    },
    setDamageViews(damageViews) {
      set({damageViews});
    },
    setFetchDamages(fetchDamages) {
      set({fetchDamages});
    },
  })),
);
