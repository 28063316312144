import {ReactNode, useEffect, useRef, useState} from 'react';
import {OBJ_NEW_ID} from '@app/utils/constants';
import ExitWithoutSavingModal from '../Confirm/ExitWithoutSaving';
import {useTranslation} from 'react-i18next';
import MultipleResizablePanels from '@app/components/Common/Panel/MultipleResizablePanels';
import SingleContentModal, {TSingleContentModal} from '../SingleFormModal';
import {twMerge} from 'tailwind-merge';

export type TMultiplePanelsModal<TItem extends { id?: unknown }> = TSingleContentModal & Readonly<{
  panelHeader?: ReactNode
  panelDirection?: 'vertical' | 'horizontal'
  panelsOnResize?: (((size: number, prevSize: number | undefined) => void) | undefined)[]
  panelsDefaultSize?: number[]
  panelsMinSize?: number[]
  panelsMaxSize?: number[]
  resizerClassName?: string
  className?: string
  autoSaveId?: string
  confirmOnExitNoSave?: boolean
  onConfirmExitWithoutSaving?: () => void
  onRender?: () => void;
  item: TItem
  isSaved?: () => boolean
  hasFixedPositionOnTreeList?: boolean;
  onClose?: () => void;
}>

export default function MultiplePanelsModal<TItem extends { id?: unknown }>({isSaved, item, panelHeader, panelsOnResize: _panelsOnResize, onRender, panelsDefaultSize, header, children, panelDirection, footer, resizerClassName, confirmOnExitNoSave, onConfirmExitWithoutSaving, className, isOpen, onOpenChange, title, onClose, autoSaveId, panelsMinSize, panelsMaxSize, panelsOnResize, ...restProps}: TMultiplePanelsModal<TItem>) {
  const [exitNoSaveModalShown, setExitNoSaveModalShown] = useState<boolean>(false);
  const wasOpened = useRef<boolean>(isOpen ?? false);
  const {t} = useTranslation();
  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onCloseClick();
    } else {
      onOpenChange?.(true);
    }
  };

  const onCloseClick = (e?: BeforeUnloadEvent) => {
    if (confirmOnExitNoSave !== false && isSaved && !isSaved() && item.id && item.id !== OBJ_NEW_ID) {
      if (e) {
        e.preventDefault();
        e.returnValue = t('message.warning.changesNotSavedUnloadWindow');
        return t('message.warning.changesNotSavedUnloadWindow');
      }

      setExitNoSaveModalShown?.(true);
      return false;
    }

    if (onClose) {
      onClose();
    } else {
      onOpenChange?.(false);
    }

    return true;
  };

  const handleConfirmExitNoSave = () => {
    setExitNoSaveModalShown(false);
    if (onConfirmExitWithoutSaving) {
      onConfirmExitWithoutSaving();
    } else if (onClose) {
      onClose();
    } else {
      onOpenChange?.(false);
    }
  };

  useEffect(() => {
    if (confirmOnExitNoSave !== false) {
      window.addEventListener('beforeunload', onCloseClick);
    }

    onRender?.();

    return () => {
      if (confirmOnExitNoSave !== false) {
        window.removeEventListener('beforeunload', onCloseClick);
      }
    };
  }, []);

  useEffect(() => {
    // Handle the case when the modal was opened and then closed by an external 'isOpen' change
    if (wasOpened.current && !isOpen) {
      if (!onCloseClick()) {
        onOpenChange?.(true);
      }
    }

    wasOpened.current = isOpen === true;
  }, [isOpen]);

  return (
    <>
      <SingleContentModal
        containerClassName={twMerge('p-0 w-[90%] max-w-[1920px] min-w-0 h-full gap-0', className)}
        title={title}
        header={header !== null ? <div className='p-4'>{header}</div> : null}
        footer={footer !== null ? <div className='flex font-normal text-xs w-full border-t rounded px-3 py-2'>
          {footer ?? ''}
        </div> : null}
        onOpenChange={handleOpenChange}
        {...restProps}
        isOpen={isOpen ?? false}
      >
        <div className='px-3 h-full'>
          <MultipleResizablePanels autoSaveId={autoSaveId} panelsMinSize={panelsMinSize} panelsMaxSize={panelsMaxSize} panelsOnResize={panelsOnResize} panelDirection={panelDirection} panelsDefaultSize={panelsDefaultSize} resizerClassName={resizerClassName} header={panelHeader}>{children}</MultipleResizablePanels>
        </div>
      </SingleContentModal>
      {confirmOnExitNoSave !== false && (
        <ExitWithoutSavingModal open={exitNoSaveModalShown} onClose={() => setExitNoSaveModalShown(false)} onConfirm={handleConfirmExitNoSave}/>
      )}
    </>
  );
}
